import commonSenseApi from '@/state/apis/commonSense'

import { objectToQueryString } from '@/utils/queryString'

import { IMPROVE_LEGAL_PRACTICE } from './applicableLaw/privacyIssue/types'

import {
  PostRequest,
  PostResponseBody,
  PatchRequest,
  PatchResponseBody,
  GetProductPrivacyIssueRequest,
  GetProductPrivacyIssueResponseBody,
  GetProductPrivacyIssueListRequest,
  GetProductPrivacyIssueListResponseBody,
  PutProductPrivacyIssueRequest,
  PutProductPrivacyIssueResponseBody,
  HIGH_PRIORITY_FIX,
  EASY_FIX,
  TOP_PRIORITY,
} from './types'

const productService = commonSenseApi.injectEndpoints({
  endpoints: build => ({
    postCreateProduct: build.mutation<PostResponseBody, PostRequest>({
      query: ({
        body, params: { id },
      }) => ({
        url: `v1/organizations/${id}/products`,
        method: 'POST',
        body,
      }),
    }),
    patchProduct: build.mutation<PatchResponseBody, PatchRequest>({
      query: ({
        body, params: {
          id, productId,
        },
      }) => ({
        url: `v1/organizations/${id}/products/${productId}`,
        method: 'PATCH',
        body,
      }),
    }),
    getProductPrivacyIssue: build.query<GetProductPrivacyIssueResponseBody, GetProductPrivacyIssueRequest>({
      query: ({
        params: {
          id,
          productId,
          privacyIssueId,
        },
      }) => ({
        url: `v1/organizations/${id}/products/${productId}/privacy-issues/${privacyIssueId}`,
        method: 'GET',
      }),
      providesTags: [ 'ProductPrivacyIssue' ],
    }),
    getProductPrivacyIssueList: build.query<GetProductPrivacyIssueListResponseBody, GetProductPrivacyIssueListRequest>({
      query: ({
        params: {
          id,
          productId,
        },
        query,
      }) => ({
        url: `v1/organizations/${id}/products/${productId}/privacy-issues?${objectToQueryString(query)}`,
        method: 'GET',
      }),
      serializeQueryArgs: ({ queryArgs }) => `PrivacyIssues::${queryArgs?.query?.category}`,
      // eslint-disable-next-line
      merge: (currentCache: any, responseData: any) => {
        const {
          total,
          offset,
          limit,
          items,
        } = responseData.data

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let newItems: any[]
        if (offset < currentCache.data?.items.length) {
          newItems = items
        }
        else {
          newItems = (currentCache.data?.items || []).concat(items)
        }

        currentCache.data = {
          total,
          offset,
          limit,
          items: newItems,
        }
      },
      // eslint-disable-next-line
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      },
    }),
    putProductPrivacyIssue: build.mutation<PutProductPrivacyIssueResponseBody, PutProductPrivacyIssueRequest>({
      query: ({
        params: {
          id, productId, privacyIssueId,
        },
        body,
      }) => ({
        url: `v1/organizations/${id}/products/${productId}/privacy-issues/${privacyIssueId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [
        'PrivacyTopics',
        'ProductScore',
        'PrivacyIssues',
        'PrivacyIssueScore',
        'ProductPrivacyIssue',
        'RecommendedAction',
        `PrivacyIssues::${HIGH_PRIORITY_FIX}`,
        `PrivacyIssues::${IMPROVE_LEGAL_PRACTICE}`,
        `PrivacyIssues::${EASY_FIX}`,
        `PrivacyIssues::${TOP_PRIORITY}`,
      ],
    }),

  }),
})

export default productService

export const {
  usePostCreateProductMutation,
  usePatchProductMutation,
  useGetProductPrivacyIssueQuery,
  useGetProductPrivacyIssueListQuery,
  usePutProductPrivacyIssueMutation,
} = productService

export const {
  endpoints: {
    postCreateProduct,
    patchProduct,
    getProductPrivacyIssue,
    getProductPrivacyIssueList,
    putProductPrivacyIssue,
  },
} = productService
