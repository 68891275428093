import commonSenseApi from '@/state/apis/commonSense'

import {
  GetProductApplicableLawRequest,
  GetProductApplicableLawResponseBody,
  GetProductApplicableLawsRequest,
  GetProductApplicableLawsResponseBody,
} from './types'

const applicableLawService = commonSenseApi.injectEndpoints({
  endpoints: build => ({
    getApplicableLaws: build.query<GetProductApplicableLawsResponseBody, GetProductApplicableLawsRequest>({
      query: ({
        params: {
          id,
          productId,
        },
      }) => ({
        url: `v1/organizations/${id}/products/${productId}/applicable-laws`,
        method: 'GET',
      }),
      providesTags: [ 'ProductApplicableLaw' ],
    }),
    getApplicableLaw: build.query<GetProductApplicableLawResponseBody, GetProductApplicableLawRequest>({
      query: ({
        params: {
          id,
          productId,
          lawId,
        },
      }) => ({
        url: `v1/organizations/${id}/products/${productId}/applicable-laws/${lawId}`,
        method: 'GET',
      }),
      providesTags: [ 'ProductApplicableLaw' ],
    }),
  }),
})

export const { useGetApplicableLawQuery } = applicableLawService

export default applicableLawService
