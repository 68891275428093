import React from 'react'

import {
  Box,
  Stack, Typography,
} from '@mui/material'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'

import { Props } from './types'

function LevelBar({
  level = 'High', title,
}: Props): React.ReactElement {
  return (
    <Stack>
      <Typography>{title}</Typography>
      <Stack direction='row' width='100%'>
        <Stack justifyContent='flex-end' textAlign='center' alignItems='center' flex={1} data-testid='LevelBar-Low'>
          {level === 'Low' && (
            <PlayArrowIcon sx={{
              fontSize: 24,
              transform: 'rotate(90deg)',
            }}
            />
          )}
          <Typography variant='subtitle1'>Low</Typography>
          <Box bgcolor='secondary.light' width='100%' height='20px' />
        </Stack>
        <Stack justifyContent='flex-end' textAlign='center' alignItems='center' flex={1} data-testid='LevelBar-Medium'>
          {level === 'Medium' && (
            <PlayArrowIcon sx={{
              fontSize: 24,
              transform: 'rotate(90deg)',
            }}
            />
          )}
          <Typography variant='subtitle1'>Medium</Typography>
          <Box bgcolor='secondary.main' width='100%' height='20px' />
        </Stack>
        <Stack justifyContent='flex-end' textAlign='center' alignItems='center' flex={1} data-testid='LevelBar-High'>
          {level === 'High' && (
            <PlayArrowIcon sx={{
              fontSize: 24,
              transform: 'rotate(90deg)',
            }}
            />
          )}
          <Typography variant='subtitle1'>High</Typography>
          <Box bgcolor='secondary.dark' width='100%' height='20px' />
        </Stack>

      </Stack>
    </Stack>
  )
}

export default React.memo(LevelBar)
