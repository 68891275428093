"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function get(key, type = 'string', dflt = '') {
    const value = process.env[key] || process.env[`REACT_APP_${key}`];
    if (type === 'bool') {
        if (value === 'true') {
            return true;
        }
        if (value === 'false') {
            return false;
        }
        return !!dflt;
    }
    if (type === 'number') {
        return Number(value);
    }
    if (type === 'string') {
        return (value || dflt);
    }
    return value;
}
exports.default = { get };
