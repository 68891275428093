import { api } from '@common-sense-privacy/common'

import commonSenseApi from '../../apis/commonSense'

const userSecurityService = commonSenseApi.injectEndpoints({
  endpoints: build => ({
    patchEditPassword: build.mutation<
      api.endpoints.user.security.types.PatchResponseBody,
      api.endpoints.user.security.types.PatchRequest>({
        query: ({
          body,
          params: { id: userId },
        }) => ({
          url: `v1/users/${userId}/security`,
          method: 'PATCH',
          body,
        }),
      }),
  }),
})

export default userSecurityService

export const { usePatchEditPasswordMutation } = userSecurityService

export const { endpoints: { patchEditPassword } } = userSecurityService
