import {
  Box,
  Button,
  CircularProgress,
  Container,
  Link,
  MenuItem,
  Typography,
} from '@mui/material'
import React, {
  useCallback,
  useState,
  useEffect,
} from 'react'
import {
  Link as RouterLink,
  useNavigate,
} from 'react-router-dom'
import { useDispatch } from 'react-redux'

import {
  api,
  validation,
} from '@common-sense-privacy/common'

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

import Form from '@/components/Form'
import FormField from '@/components/FormField'
import AutocompleteField from '@/components/FormField/Autocomplete'

import { useGetPrivacyPolicyNamesQuery } from '@/services/privacyPolicy'
import { useGetProductTypesQuery } from '@/services/productTypes'
import { useGetCategoriesQuery } from '@/services/categories'
import { usePostCreateProductMutation } from '@/services/organization/product'
import { useGetDistributionMethodsQuery } from '@/services/distributionMethods'

import { useCurrentOrganization } from '@/hooks/useSession'
import {
  ProductName,
  setProductInfo,
  useProductInfo,
  usePolicyUrl,
  useHasExistingPolicy,
} from '@/hooks/useProductInformation'
import useDebouncedValue from '@/hooks/useDebouncedValue'

import DocumentWrapper from '../../../../components/DocumentWrapper'
import ScreenWrapper from '../../../../components/ScreenWrapper'

import Loading from '../../components/Loading'

import type {
  FormValues,
  OnSubmit,
} from './types'

function ProductInformation(): React.ReactElement {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id: organizationId } = useCurrentOrganization()
  const hasExistingPolicy = useHasExistingPolicy()
  const policyUrl = usePolicyUrl()

  const [
    inputValue,
    setInputValue,
  ] = useState('')

  const debouncedInput = useDebouncedValue(inputValue, 200)

  const {
    data: productNames,
    isLoading: isProductNamesLoading,
  } = useGetPrivacyPolicyNamesQuery({ search: debouncedInput }, { refetchOnMountOrArgChange: true })

  const {
    data: productTypes,
    isLoading: isProductTypesLoading,
  } = useGetProductTypesQuery(undefined, { refetchOnMountOrArgChange: true })

  const {
    data: categories,
    isLoading: isCategoriesLoading,
  } = useGetCategoriesQuery(undefined, { refetchOnMountOrArgChange: true })

  const [
    postCreateProduct,
    { isLoading: isCreatingProduct },
  ] = usePostCreateProductMutation({ fixedCacheKey: 'shared-creating-product' })

  const {
    data: distributionMethods,
    isLoading: isDistributionMethodsLoading,
  } = useGetDistributionMethodsQuery(undefined, { refetchOnMountOrArgChange: true })

  const initialValues = useProductInfo()

  const [
    selectedProductType,
    setSelectedProductType,
  ] = useState('')

  useEffect(() => {
    if (productTypes && !selectedProductType && initialValues.type) {
      setSelectedProductType(productTypes.data
        ?.find(productType => productType.id === initialValues.type)?.name || '')
    }
  }, [
    productTypes,
    selectedProductType,
    setSelectedProductType,
    initialValues.type,
  ])

  const { rules } = api.endpoints.organization.product.validation.post.body
  const formRules = {
    ...rules,
    name: validation.object({
      name: rules.name,
      annotatorRefId: rules.annotatorRefId,
    }).required().label('Product Name'),
  }

  const handleSubmit = useCallback<OnSubmit>(async (values: FormValues) => {
    postCreateProduct({
      body: {
        name: values.name.name,
        annotatorRefId: values.name.annotatorRefId,
        type: productTypes?.data?.find(type => type.name === values.type)?.id || '',
        categories: values.categories,
        policyUrl: hasExistingPolicy ? policyUrl : undefined,
        distributionMethods: values.type === 'Mobile App' ? values.distributionMethods : undefined,
        privacyPolicyIntroduction: values.privacyPolicyIntroduction,
      },
      params: { id: organizationId },
    }).unwrap().then(() => {
      dispatch(setProductInfo(values))
      navigate('/wizard/start')
    })
  }, [
    dispatch,
    hasExistingPolicy,
    navigate,
    organizationId,
    policyUrl,
    postCreateProduct,
    productTypes,
  ])

  return (
    <DocumentWrapper title='Common Sense Privacy | Product Information'>
      <ScreenWrapper>
        {isCreatingProduct ? <Loading />
          : (
            <>
              <Container>
                <Box>
                  <Link
                    component={RouterLink}
                    variant='body2'
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      color: 'text.primary',
                      textDecoration: 'none',
                      fontWeight: 'bold',
                    }}
                    to={hasExistingPolicy ? '/wizard/product/privacy-policy/url' : '/wizard/product/privacy-policy'}
                  >
                    <KeyboardArrowLeftIcon /> Back
                  </Link>
                </Box>
              </Container>
              <Container maxWidth='sm'>
                <Typography variant='h5' textAlign='center' mb={4}>Tell us about your product...</Typography>
                <Typography variant='body1' mb={5} textAlign='center'>If Common Sense has rated your privacy practices before, answers will be populated into the Wizard using the product name.To create a new product, simply enter the name.
                </Typography>
                <Form<FormValues> initialValues={initialValues} onSubmit={handleSubmit} rules={formRules} marginBottom={6} spacing={4} alignItems='center'>
                  <AutocompleteField<ProductName>
                    name='name'
                    label='Product Name'
                    onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                    getOptionLabel={option => typeof option === 'string' ? option : option?.name || ''}
                    options={productNames?.data || []}
                    formControlProps={{ fullWidth: true }}
                    loading={isProductNamesLoading}
                    helperText='Search by product, company or create a new product.'
                    loadingText={(
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          width: '100%',
                          justifyContent: 'center',
                        }}
                        py={3}
                      >
                        <CircularProgress size='1.5rem' />
                      </Box>
                    )}
                    data-testid='product-name-wrapper'
                  />
                  <FormField
                    name='type'
                    type='select'
                    labelId='product-type-select-label'
                    id='product-type-select'
                    label='Product Type'
                    formControlProps={{ fullWidth: true }}
                    data-testid='product-type-wrapper'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedProductType(e.target.value)}
                  >
                    {(productTypes?.data || []).map(productType => (
                      <MenuItem
                        key={productType.id}
                        value={productType.name}
                      >
                        {productType.name}
                      </MenuItem>
                    ))}
                  </FormField>
                  {selectedProductType === 'Mobile App' && (
                    <FormField
                      name='distributionMethods'
                      type='select'
                      multiple={true}
                      label='Product Distribution Methods'
                      labelId='product-distribution-methods-select-label'
                      formControlProps={{ fullWidth: true }}
                      data-testid='product-distribution-methods-wrapper'
                    >
                      {(distributionMethods?.data || []).map(distMethod => (
                        <MenuItem key={distMethod.id} value={distMethod.id}>
                          {distMethod.name}
                        </MenuItem>
                      ))}
                    </FormField>
                  )}
                  <FormField
                    name='categories'
                    type='select'
                    multiple={true}
                    label='Product Categories'
                    labelId='product-industry-select-label'
                    formControlProps={{ fullWidth: true }}
                    data-testid='product-categories-wrapper'
                  >
                    {(categories?.data || []).map(category => (
                      <MenuItem
                        key={category.id}
                        value={category.id}
                      >
                        {category.name}
                      </MenuItem>
                    ))}
                  </FormField>
                  <FormField
                    name='privacyPolicyIntroduction'
                    type='text'
                    multiline={true}
                    maxRows={Infinity}
                    minRows={4}
                    label='Privacy Policy Introduction (Optional)'
                    infoTip='If you wish to add a custom introduction to your privacy policy, enter it here.'
                    formControlProps={{
                      fullWidth: true,
                      sx: { mt: 0 },
                    }}
                  />
                  <Button
                    disabled={
                      isProductTypesLoading || isCategoriesLoading || isProductNamesLoading
                      || isCreatingProduct || isDistributionMethodsLoading
                    }
                    role='button'
                    type='submit'
                  >
                    Continue
                  </Button>
                </Form>
              </Container>
            </>
          )}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(ProductInformation)
