/* eslint-disable react/jsx-key */
/* eslint-disable react/no-array-index-key */
import {
  Box, Typography,
} from '@mui/material'
import React from 'react'
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Cell,
} from 'recharts'

const RADIAN = Math.PI / 180
const data = [
  {
    name: '0',
    value: 17,
  },
  {
    name: '25',
    value: 17,
  },
  {
    name: '50',
    value: 33,
  },
  {
    name: '75',
    value: 17,
  },
  {
    name: '100',
    value: 17,
  },
]
const COLORS = [
  '#FAA41A',
  '#FAA41A',
  '#23B5E8',
  '#33A544',
  '#33A544',
]
const cx = 125
const cy = 175
const iR = 50
const oR = 100
// eslint-disable-next-line default-param-last
const needle = (value = 55, data: { value: number }[], cx = 165, cy = 175, iR = 50, oR = 100) => {
  let total = 0
  data.forEach((v: { value: number }) => {
    total += v.value
  })
  const ang = 180.0 * (1 - value / total)
  const length = (iR + 2 * oR) / 3
  const sin = Math.sin(-RADIAN * ang)
  const cos = Math.cos(-RADIAN * ang)
  const r = 5
  const x0 = cx + 5
  const y0 = cy + 5
  const xba = x0 + r * sin
  const yba = y0 - r * cos
  const xbb = x0 - r * sin
  const ybb = y0 + r * cos
  const xp = x0 + length * cos
  const yp = y0 + length * sin

  return [
    <circle key='needle-circle' cx={x0} cy={y0} r={r} fill='#fff' stroke='none' />,
    <path key='needle-path' d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke='#none' fill='#000' />,
  ]
}

const renderLabel = (entry: { name: string }) => entry.name

function ChartsScore({ value } : { value: number }) {
  return (
    <Box width={265} position='relative'>
      <ResponsiveContainer width={265} height={300}>
        <PieChart>
          <Pie
            dataKey='value'
            startAngle={180}
            endAngle={0}
            data={data}
            cx='50%'
            cy='60%'
            outerRadius={100}
            innerRadius={50}
            label={renderLabel}
            labelLine={false}
            style={{
              fontSize: '0.75rem',
              fontFamily: 'Oswald',
            }}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${entry.name}`} stroke={COLORS[index % COLORS.length]} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          {needle(value, data, cx, cy, iR, oR)}
        </PieChart>
      </ResponsiveContainer>
      <Box position='absolute' bottom={0} width='100%' textAlign='center'>
        <Typography fontSize={65} fontWeight='bold'>{value}%</Typography>
      </Box>
      <Box minHeight={20} />
    </Box>
  )
}

export default React.memo(ChartsScore)
