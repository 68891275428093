import React, {
  useState,
  useEffect,
} from 'react'

import {
  Box,
  Stack,
  Typography,
  Button,
  Divider,
  IconButton,
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import {
  useCurrentOrganization,
  useCurrentProduct,
} from '@/hooks/useSession'

import { usePutProductRecommendedActionMutation } from '@/services/organization/product/recommendedAction'

import Drawer from '@/components/Drawer'

import RecommendedActionQuestion from './RecommendedActionQuestion'

import { Props } from './types'

function RecommendedActionDrawer({
  productRecommendedAction,
  onSubmit,
  onClose,
  ...props
}: Props): React.ReactElement {
  const { id: recommendedActionId } = productRecommendedAction
  const { id: organizationId } = useCurrentOrganization()
  const { id: productId } = useCurrentProduct()

  const [
    answerIds,
    setAnswerIds,
  ] = useState<string[]>([])

  const {
    recommendedAction: {
      answerInputFields,
      question,
      title,
    },
    answers,
  } = productRecommendedAction

  useEffect(() => {
    setAnswerIds(answers?.map(answer => answer.answer.id) || [])
  }, [ answers ])

  const [ answerInput ] = answerInputFields

  const { id: inputId } = answerInput

  const handleOnAnswer = (newAnswerIds: string[]) => {
    setAnswerIds(newAnswerIds)
  }

  const handleClearForm = () => {
    setAnswerIds([])
  }

  const [ putProductPrivacyIssueMutation ] = usePutProductRecommendedActionMutation()

  const handleSave = () => {
    putProductPrivacyIssueMutation({
      body: { answerIds },
      params: {
        id: organizationId,
        productId: `${productId}`,
        recommendedActionId: recommendedActionId || '',
      },
    }).then(() => {
      onSubmit()
    })
  }

  return (
    <Drawer
      anchor='right'
      {...props}
      data-testid='RecommendedActionDrawer'
      onClose={onClose}
    >
      <Box top={32} right={32} position='absolute'>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box px={6.5} pt={7.5}>
        <Stack spacing={2} mb={2}>
          <Typography variant='h3'>{title}</Typography>
          <Typography variant='h6'>{question}</Typography>
          <RecommendedActionQuestion
            answerInputFields={answerInputFields}
            answerIds={answerIds}
            inputId={inputId}
            onAnswer={handleOnAnswer}
          />
          <Divider />
          <Stack direction='row' spacing={2}>
            <Button sx={{ width: 'auto' }} onClick={handleSave}>Save</Button>
            <Button
              variant='outlined'
              sx={{ width: 'auto' }}
              disabled={!answerIds.length}
              onClick={handleClearForm}
            >
              Clear Form
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Drawer>
  )
}

export default React.memo(RecommendedActionDrawer)
