import {
  Card,
  CardContent,
  Stack,
  Typography,
  Divider,
  Grid,
  Button,
} from '@mui/material'
import React from 'react'

import { Link as RouterLink } from 'react-router-dom'

import { useCurrentProduct } from '@/hooks/useSession'

import { Props } from './types'

const primaryStyles = {
  breakpoints: {
    firstChild: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    secondChild: {
      xs: 12,
      sm: 6,
      md: 8,
    },
  },
  alignment: {
    firstChild: 'center',
    secondChild: 'flex-start',
  },
}

const secondaryStyles = {
  breakpoints: {
    firstChild: { xs: 12 },
    secondChild: { xs: 12 },
  },
  alignment: {
    firstChild: 'flex-start',
    secondChild: 'flex-start',
  },
}

function BenchmarkScoreCard({
  type,
  children,
  title,
  message,
  actionText,
  actionLink,
}: Props): React.ReactElement {
  const { name: productName } = useCurrentProduct()
  const styles = type === 'primary' ? primaryStyles : secondaryStyles

  return (
    <Card variant='outlined'>
      <CardContent>
        <Stack spacing={2} alignItems='center' mb={2}>
          <Typography variant='h2' textAlign='center'>{title}</Typography>
          <Divider flexItem={true} />
        </Stack>
        <Grid container={true} spacing={2} justifyContent='center' mb={4} mt={2}>
          <Grid item={true} {...styles.breakpoints.firstChild}>
            <Stack alignItems={styles.alignment.firstChild}>
              <Typography variant='h6'>{productName}</Typography>
              {message}
              {children[0]}
            </Stack>
          </Grid>
          <Grid item={true} {...styles.breakpoints.secondChild} justifyContent='center' display='flex' flexDirection='column' mt={2}>
            <Stack alignItems={styles.alignment.secondChild}>
              <Typography variant='h6'>Others</Typography>
              <Typography mb={2}>Others in your product category rank as follows:</Typography>
              {children[1]}
            </Stack>
          </Grid>
        </Grid>
        <Stack spacing={2} alignItems='center'>
          <Button variant='outlined' component={RouterLink} to={actionLink}>{actionText}</Button>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default React.memo(BenchmarkScoreCard)
