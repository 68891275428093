"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function url() {
    /* eslint-disable */
    return this.test('urlValidation', 'Please enter a complete URL.', (value) => {
        if (value && !value.match(/^((ht|f)tp(s)?:\/\/|mailto:|sms:|tel:)/)) {
            return false;
        }
        return true;
    });
    /* eslint-enable */
}
exports.default = url;
