import React, {
  useCallback,
  useState,
  useEffect,
} from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material'

import { useDispatch } from 'react-redux'

import { api } from '@common-sense-privacy/common'

import Form from '@/components/Form'
import FormField from '@/components/FormField'

import useHandleFormApiErrors from '@/hooks/useHandleFormApiErrors'

import { useDeleteUserMutation } from '@/services/user'
import useSession, { setSession } from '@/hooks/useSession'
import useNavigate from '@/hooks/useNavigate'

import type {
  FormValues,
  OnSubmit,
  Props,
} from './types'

function CancelAccountDialog({
  open,
  onClose,
}: Props) {
  const navigate = useNavigate()
  const handleFormApiErrors = useHandleFormApiErrors()
  const sessionState = useSession()
  const dispatch = useDispatch()
  const [
    deleteUser,
    { isLoading },
  ] = useDeleteUserMutation()

  const initialValues = {
    password: '',
    confirmPassword: '',
  }
  const handleSubmit = useCallback<OnSubmit>(async (values: FormValues, { setErrors }) => {
    if (!sessionState.user) {
      return
    }
    deleteUser({
      body: values,
      params: { id: sessionState.user?.id },
    })
      .unwrap().then(() => {
        dispatch(setSession(undefined))
        navigate('/signin')
      }).catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
          showFieldErrorsAsToast: true,
        })
      })
  }, [
    deleteUser,
    sessionState.user,
    dispatch,
    navigate,
    handleFormApiErrors,
  ])
  const { rules } = api.endpoints.user.validation.deleteUser.body

  const [
    showPasswordModal,
    setShowPasswordModal,
  ] = useState(false)

  useEffect(() => {
    if (open) setShowPasswordModal(false)
  }, [
    open,
    setShowPasswordModal,
  ])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent dividers={true}>
        {!showPasswordModal
          && (
            <Box textAlign='center'>
              <Typography variant='h5'>Are you sure you want to cancel your account?</Typography>
              <Typography mt={2} mb={4}>If you proceed, all information associated with your account will be deleted.</Typography>
              <Stack spacing={2} direction='row' justifyContent='center'>
                <Button variant='cancel' onClick={onClose} sx={{ width: 'auto' }}>Go Back</Button>
                <Button variant='contained' onClick={() => setShowPasswordModal(prev => !prev)}>Yes, Cancel My Account</Button>
              </Stack>
            </Box>
          ) }
        {showPasswordModal
          && (
            <Box textAlign='center'>
              <Typography variant='h5'>To proceed with canceling your account, please confirm your password.</Typography>
              <Form<FormValues> initialValues={initialValues} onSubmit={handleSubmit} rules={rules}>
                <Stack spacing={2} my={3} maxWidth={240} mx='auto'>
                  <FormField
                    name='password'
                    type='password'
                    label='Password'
                    variant='outlined'
                    inputProps={{ 'data-testid': 'cancel-password' }}
                  />
                  <FormField
                    name='confirmPassword'
                    type='password'
                    label='Confirm Password'
                    variant='outlined'
                    inputProps={{ 'data-testid': 'cancel-confirmPassword' }}
                  />
                </Stack>
                <Stack spacing={2} direction='row' justifyContent='center'>
                  <Button variant='cancel' onClick={onClose} sx={{ width: 'auto' }}>Go Back</Button>
                  <Button
                    variant='contained'
                    disabled={isLoading}
                    type='submit'
                    role='button'
                  >
                    Yes, Cancel My Account
                  </Button>
                </Stack>
              </Form>
            </Box>
          ) }
      </DialogContent>
    </Dialog>
  )
}

export default React.memo(CancelAccountDialog)
