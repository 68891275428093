import { api } from '@common-sense-privacy/common'

export type PostRequest = api.endpoints.organization.product.types.PostRequest

export type PostResponseBody = api.endpoints.organization.product.types.PostResponseBody

export type PatchRequest = api.endpoints.organization.product.types.PatchRequest

export type PatchResponseBody = api.endpoints.organization.product.types.PatchResponseBody

export type GetProductPrivacyIssueRequest = api.endpoints.organization.product.privacyIssue.types.GetRequest

export type GetProductPrivacyIssueResponseBody = api.endpoints.organization.product.privacyIssue.types.GetResponseBody

export type GetProductPrivacyIssueListRequest = api.endpoints.organization.product.privacyIssue.types.GetListRequest

export type GetProductPrivacyIssueListResponseBody = api.endpoints.organization.product.privacyIssue.types.GetListResponseBody

export type PutProductPrivacyIssueRequest = api.endpoints.organization.product.privacyIssue.types.PutRequest

export type PutProductPrivacyIssueResponseBody = api.endpoints.organization.product.privacyIssue.types.PutResponseBody

export type ProductPrivacyIssue = api.endpoints.organization.product.privacyIssue.types.ProductPrivacyIssue

export type ProductPrivacyIssueAnswer = api.endpoints.organization.product.privacyIssue.types.ProductPrivacyIssueAnswer

export const {
  HIGH_PRIORITY_FIX,
  TOP_PRIORITY,
  EASY_FIX,
  IMPROVE_PRACTICE,
} = api.endpoints.organization.product.privacyIssue.types.Category
