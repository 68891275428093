import commonSenseApi from '@/state/apis/commonSense'

import { GetRolesResponseBody } from './types'

const rolesService = commonSenseApi.injectEndpoints({
  endpoints: build => ({
    getRoles: build.query<GetRolesResponseBody, void>({
      query: () => ({
        url: 'v1/roles',
        method: 'GET',
      }),
    }),
  }),
})

export default rolesService

export const { useGetRolesQuery } = rolesService

export const { endpoints: { getRoles } } = rolesService
