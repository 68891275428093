import commonSenseApi from '@/state/apis/commonSense'

import {
  UserDeleteRequestPayload,
  UserDeleteResponseBody,
} from './types'

const userService = commonSenseApi.injectEndpoints({
  endpoints: build => ({
    deleteUser: build.mutation<UserDeleteResponseBody, UserDeleteRequestPayload>({
      query: ({
        body,
        params,
      }) => ({
        url: `v1/user/${params.id}`,
        method: 'DELETE',
        body,
      }),
    }),
  }),
})

export default userService

export const { useDeleteUserMutation } = userService

export const { endpoints: { deleteUser } } = userService
