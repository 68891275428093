import React, {
  useCallback,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'

import {
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/material'

import useNavigate from '@/hooks/useNavigate'
import useNewRelic from '@/hooks/useNewRelic'

import { setSession } from '@/state/slices/session'

function DevMenu(): React.ReactElement {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const newRelic = useNewRelic()

  const [
    isExpanded,
    setIsExpanded,
  ] = useState(false)

  const toggle = useCallback(() => setIsExpanded(prev => !prev), [])

  const confirm = useCallback((callback: () => void, content?: string) => () => {
    // eslint-disable-next-line no-alert
    if (!window.confirm(content)) {
      return
    }

    callback()
  }, [])

  const onPress = useCallback((callback: () => void) => () => {
    setIsExpanded(false)

    callback()
  }, [])

  return (
    <Box position='fixed' zIndex='4' top={5} left={5}>
      <Button
        onClick={toggle}
        variant='outlined'
        sx={{
          padding: 0,
          width: 'auto',
        }}
      >
        <Typography variant='inherit'>
          {isExpanded ? 'x' : '+'}
        </Typography>
      </Button>

      {isExpanded && (
        <Stack spacing={1} mt={1}>
          <Button
            size='large'
            variant='outlined'
            onClick={onPress(() => navigate('/dev/scratch'))}
            sx={{ width: 'auto' }}
          >
            Dev Scratch
          </Button>
          <Button
            onClick={confirm(onPress(() => {
              throw Error('Test error (intentionally thrown)')
            }), 'This will crash the app.')}
            size='large'
            variant='outlined'
            sx={{ width: 'auto' }}
          >
            ⚠️ Throw error
          </Button>
          <Button
            onClick={confirm(onPress(() => newRelic?.noticeError(new Error('test manual throw'))), 'This will NOT crash the app.')}
            size='large'
            variant='outlined'
            sx={{ width: 'auto' }}
          >
            Throw manual error to New Relic
          </Button>
          <Button
            size='large'
            variant='outlined'
            sx={{ width: 'auto' }}
            onClick={onPress(() => dispatch(setSession(undefined)))}
          >
            Sign Out
          </Button>
        </Stack>
      )}
    </Box>
  )
}

export default React.memo(DevMenu)
