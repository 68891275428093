import React from 'react'

import {
  Box,
  Stack,
  Typography,
  IconButton,
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import {
  useCurrentOrganization,
  useCurrentProduct,
} from '@/hooks/useSession'

import { usePutProductPrivacyIssueMutation } from '@/services/organization/product'

import Drawer from '@/components/Drawer'
import PrivacyIssueQuestion from '@/screens/Wizard/components/PrivacyIssueQuestion'

import { Props } from './types'

function PrivacyIssuePracticesDrawer({
  productPrivacyIssue,
  onSubmit,
  onClose,
  ...props
}: Props): React.ReactElement {
  const { id: privacyIssueId } = productPrivacyIssue
  const { id: organizationId } = useCurrentOrganization()
  const { id: productId } = useCurrentProduct()

  const {
    privacyIssue: {
      answerInputFields,
      question,
      privacyTopic: { title },
    },
  } = productPrivacyIssue

  const [ answerInput ] = answerInputFields

  const { id: inputId } = answerInput

  const [ putProductPrivacyIssueMutation ] = usePutProductPrivacyIssueMutation()

  const handleSave = (answers: string[]) => {
    putProductPrivacyIssueMutation({
      body: { answers },
      params: {
        id: organizationId,
        productId: `${productId}`,
        privacyIssueId: privacyIssueId || '',
      },
    }).then(() => {
      onSubmit()
    })
  }

  return (
    <Drawer
      anchor='right'
      {...props}
      data-testid='PrivacyIssuePracticesDrawer'
      onClose={onClose}
    >
      <Box top={32} right={32} position='absolute'>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box px={6.5} pt={7.5}>
        <Stack spacing={2} mb={2}>
          <Typography variant='h3'>{title}</Typography>
          <Typography variant='h6'>{question}</Typography>
          <PrivacyIssueQuestion
            inputId={inputId}
            onSave={handleSave}
            productPrivacyIssue={productPrivacyIssue}
            showDividerAboveButtons={true}
          />
        </Stack>
      </Box>
    </Drawer>
  )
}

export default React.memo(PrivacyIssuePracticesDrawer)
