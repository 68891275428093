import {
  useState,
  useEffect,
} from 'react'
import { useDispatch } from 'react-redux'

import { useRefreshAccessTokenMutation } from '@/services/auth'
import { setSession } from '@/hooks/useSession'

function useRefreshAccessToken(canRefresh: boolean) {
  const [
    accessTokenRefreshed,
    setAccessTokenRefreshed,
  ] = useState<boolean>(false)
  const dispatch = useDispatch()
  const [ refreshAccessToken ] = useRefreshAccessTokenMutation()

  useEffect(() => {
    if (!canRefresh && !accessTokenRefreshed) return

    refreshAccessToken(undefined).unwrap().then(response => {
      dispatch(setSession(response?.data))
      setAccessTokenRefreshed(true)
    })
  }, [
    canRefresh,
    refreshAccessToken,
    dispatch,
    setAccessTokenRefreshed,
    accessTokenRefreshed,
  ])

  return accessTokenRefreshed
}

export default useRefreshAccessToken
