import React from 'react'

import {
  Box,
  Chip,
  Link,
  Stack, Typography,
} from '@mui/material'

import { Props } from './types'

function ResourceCard({
  categories = [],
  date,
  description,
  title,
  link,
}: Props): React.ReactElement {
  return (

    <Stack
      height='100%'
    >
      <Stack>
        <Typography variant='body3' color='text.secondary'>{date}</Typography>
        <Link
          href={link}
          sx={{
            color: 'text.primary',
            textDecoration: 'none',
          }}
        ><Typography variant='h6'>{title}</Typography>
        </Link>
      </Stack>
      <Typography my={2}>{description}</Typography>
      <Box marginTop='auto'>
        {categories.map(category => (
          <Chip
            sx={{
              marginBottom: '6px',
              marginRight: '6px',
            }}
            key={category}
            label={category}
            variant='results'
          />
        ))}
      </Box>
    </Stack>
  )
}

export default React.memo(ResourceCard)
