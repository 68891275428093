import {
  Container,
  Divider,
  Link,
  Typography,
  Stack,
  Button,
} from '@mui/material'
import React, {
  useEffect, useState,
} from 'react'

import { lang } from '@common-sense-privacy/common'

import { useNavigate } from 'react-router-dom'

import DocumentWrapper from '@/components/DocumentWrapper'
import ScreenWrapper from '@/components/ScreenWrapper'
import { useOrganizationSubscription } from '@/hooks/useSession'
import { useAlert } from '@/context/AlertContext'
import { useCreateSetupIntentMutation } from '@/services/stripe'
import Loading from '@/screens/Wizard/components/Loading'
import StripeFormWrapper from '@/components/StripeFormWrapper'
import Form from '@/components/Form'

function AccountEditPayment(): React.ReactElement {
  const alert = useAlert()
  const navigate = useNavigate()
  const [
    clientSecret,
    setClientSecret,
  ] = useState<string | undefined>()

  const [
    isLoading,
    setIsLoading,
  ] = useState(true)

  const subscription = useOrganizationSubscription()

  const [ createSetupIntent ] = useCreateSetupIntentMutation()

  useEffect(() => {
    if (!subscription?.customer.id) {
      return
    }

    setIsLoading(true)

    createSetupIntent({ body: { stripeCustomerId: subscription?.customer.id as string } })
      .unwrap()
      .then(response => {
        setClientSecret(response.data?.clientSecret)
      })
      .catch(() => {
        alert.setAlert({
          description: lang().messages.unknownError(),
          type: 'error',
        })
      })
      .finally(() => setIsLoading(false))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DocumentWrapper title='Common Sense Privacy | Dev Scratch'>
      <ScreenWrapper>
        <Container>
          <Stack mb={4} spacing={0.5} direction='row'>
            <Link
              href='/account'
              variant='body2'
              sx={{ color: 'text.primary' }}
            >My Account
            </Link>
            <Typography variant='body2'>/ Edit Payment Method</Typography>
          </Stack>
          <Stack mb={4}>
            <Typography variant='h1'>Payment Method</Typography>
            <Typography variant='intro' mb={4}>Only one payment method can be on file at a time. Update your payment method using the form below.</Typography>
            <Divider />
          </Stack>
        </Container>
        <Container>
          <Stack mt={4} maxWidth={640}>
            {(isLoading && <Loading />) || (
              <StripeFormWrapper
                clientSecret={clientSecret}
                onSuccessRedirectTo='/account'
                onSuccessMessage={() => lang().messages.editPaymentSuccess()}
                type='updatePaymentMethod'
              >
                {({
                  isSubmitting,
                  onSubmit,
                  PaymentForm,
                }) => (
                  <Form onSubmit={onSubmit}>
                    {PaymentForm}
                    <Stack direction='column' mt={2}>
                      <Typography variant='caption'>
                        By providing your card information, you allow Common Sense Privacy to charge your card for future payments in accordance with their <Link href='/terms' variant='caption' color='inherit'>terms</Link>.
                      </Typography>
                      <Stack direction='row' spacing={2} mt={4}>
                        <Button type='submit' disabled={isSubmitting}>Save</Button>
                        <Button type='button' variant='outlined' onClick={() => navigate('/account')} disabled={isSubmitting}>Cancel</Button>
                      </Stack>
                    </Stack>
                  </Form>
                )}
              </StripeFormWrapper>
            )}
          </Stack>
          <Stack mt={8}>
            <Divider />
          </Stack>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(AccountEditPayment)
