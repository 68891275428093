import {
  Box,
  Container,
  Tooltip,
  Typography,
} from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import DocumentWrapper from '../../components/DocumentWrapper'
import ScreenWrapper from '../../components/ScreenWrapper'

function DevScratch(): React.ReactElement {
  return (
    <DocumentWrapper title='Common Sense Privacy | Dev Scratch'>
      <ScreenWrapper>
        <Container>
          <Typography variant='h1'>Heading One</Typography>
          <Typography variant='h2'>Heading Two</Typography>
        </Container>
        <Container>
          <Box mb='6'>
            <Typography>Dev Scratch With some text and a tooltip
              <Tooltip
                placement='top'
                enterTouchDelay={0}
                PopperProps={{
                  disablePortal: true,
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      height: '100%',
                      maxHeight: '150px',
                      overflow: 'auto',
                    },
                  },
                }}
                title='Testing info tip'
              ><Typography><InfoOutlinedIcon color='inherit' /></Typography>
              </Tooltip>
            </Typography>
            <Link to='/'>Home</Link>
          </Box>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(DevScratch)
