import {
  Box, Stack, Typography,
} from '@mui/material'
import React from 'react'

import { Props } from './types'

function ChartLabel({
  labelText,
  bgcolor,
  ...props
}: Props): React.ReactElement {
  return (
    <Stack direction='row' alignItems='center'>
      <Box
        {...props}
        bgcolor={bgcolor}
        height={15}
        width={15}
        borderRadius={15}
        mr={1}
      />
      <Typography fontSize={11} color='text.secondary'>{labelText}</Typography>
    </Stack>
  )
}

export default React.memo(ChartLabel)
