import {
  dateTime,
  DateTime,
} from '@common-sense-privacy/common'

// import { Native as Sentry } from './sentry'

const format: DateTime.Format = (date, format) => {
  let output = ''

  try {
    output = dateTime.format(date, format)
  }
  catch (error) {
    // Sentry.captureException(error)
  }

  return output
}

export default {
  ...dateTime,
  format,
}
