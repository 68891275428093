import React, { useContext } from 'react'

import { Link as RouterLink } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'

import {
  Box,
  Divider,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material'

import useSession, { usePlanType } from '@/hooks/useSession'
import {
  isAtLeastWizardPlan,
  isAtLeastDashboardPlan,
} from '@/utils/stripe'

import Drawer from '@/components/Drawer'
import Logo from '@/components/Logo'

import { MenuContext } from './context'

function Menu(): React.ReactElement {
  const {
    isMenuOpen,
    closeMenu,
  } = useContext(MenuContext)
  const session = useSession()
  const isNotLoggedIn = !session?.user

  const planType = usePlanType()
  const canViewWizard = planType && isAtLeastWizardPlan(planType)
  const canViewDashboard = planType && isAtLeastDashboardPlan(planType)

  return (
    <Drawer
      anchor='left'
      onClose={closeMenu}
      open={isMenuOpen}
    >
      <Stack alignItems='flex-start' pt={2} pl={2}>
        <IconButton onClick={closeMenu}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Box pl={4} pr={4} mt={2}>
        <Box mb={4}>
          <Logo />
        </Box>
        {/* Logged In */}
        {!isNotLoggedIn && (
          <>
            <Stack spacing={2} mb={2} data-testid='member-menu-1'>
              <Typography variant='h2' fontSize={22}>Privacy Wizard</Typography>
              <Typography fontSize={18}>
                <Link component={RouterLink} to='/wizard' color='inherit' underline='hover'>Privacy Questions</Link>
              </Typography>
              {canViewWizard && (
                <Typography fontSize={18}>
                  <Link component={RouterLink} to='/privacy-policies' color='inherit' underline='hover'>Privacy Policies</Link>
                </Typography>
              )}
              <Divider />
            </Stack>
            {canViewDashboard && (
              <Stack spacing={2} mb={2} data-testid='member-menu-2'>
                <Typography variant='h2' fontSize={22}>Privacy Dashboard</Typography>
                <Typography fontSize={18}>
                  <Link component={RouterLink} to='/dashboard' color='inherit' underline='hover'>Current Privacy Evaluation</Link>
                </Typography>
                {/* Hidden for v1 launch */}
                {/* <Typography fontSize={18}>
                <Link component={RouterLink} to='/' color='inherit' underline='hover'>Past Privacy Evaluations</Link>
              </Typography>
              <Typography fontSize={18}>
                <Link component={RouterLink} to='/' color='inherit' underline='hover'>Update My Privacy Score</Link>
              </Typography>*/}
                <Divider />
              </Stack>
            )}
            {/* Hidden for v1 launch */}
            {/* <Stack spacing={2} mb={2}>
              <Typography variant='h2' fontSize={22}>Workflow</Typography>
              <Typography fontSize={18}>
                <Link component={RouterLink} to='/' color='inherit' underline='hover'>Tasks</Link>
              </Typography>
              <Typography fontSize={18}>
                <Link component={RouterLink} to='/' color='inherit' underline='hover'>Contributors</Link>
              </Typography>
              <Divider />
            </Stack> */}
            <Stack spacing={2} mb={2}>
              <Typography fontSize={18}>
                <Link component={RouterLink} to='/account' color='inherit' underline='hover'>Account</Link>
              </Typography>
              <Typography fontSize={18}>
                <Link component={RouterLink} to='/contact-support' color='inherit' underline='hover'>Support</Link>
              </Typography>
              {/* <Typography fontSize={18}>
                <Link component={RouterLink} to='/' color='inherit' underline='hover'>Feedback</Link>
              </Typography> */}
            </Stack>
          </>
        )}
        {/* Logged Out */}
        {isNotLoggedIn && (
          <Stack spacing={2} mb={2} data-testid='no-member-menu'>
            <Typography variant='h2' fontSize={22}>
              <Link component={RouterLink} to='/' color='inherit' underline='hover'>Home</Link>
            </Typography>
            <Typography variant='h2' fontSize={22}>
              <Link component={RouterLink} to='/' color='inherit' underline='hover'>Privacy Wizard</Link>
            </Typography>
            {canViewDashboard && (
              <Typography variant='h2' fontSize={22}>
                <Link component={RouterLink} to='/' color='inherit' underline='hover'>Privacy Dashboard</Link>
              </Typography>
            )}
          </Stack>
        )}
      </Box>
    </Drawer>
  )
}

export default React.memo(Menu)

export { MenuContext } from './context'

export * from './types'
