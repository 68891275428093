import React from 'react'

import { Typography } from '@mui/material'

import config, { version } from '../../config'

function Version() {
  const appEnv = (config.appEnv === 'production') ? undefined : (config.appEnv || '??').toUpperCase()

  return (
    <Typography
      variant='body3'
    >
      v{version}{appEnv ? ` (${appEnv})` : ''}
    </Typography>
  )
}

export default React.memo(Version)
