import React, { useCallback } from 'react'
import {
  Button,
  Breadcrumbs,
  Container,
  Divider,
  Link,
  Stack,
  Typography,
} from '@mui/material'

import { api } from '@common-sense-privacy/common'

import Form from '@/components/Form'
import FormField from '@/components/FormField'
import { useAlert } from '@/context/AlertContext'
import useSession from '@/hooks/useSession'
import useNavigate from '@/hooks/useNavigate'
import useHandleFormApiErrors from '@/hooks/useHandleFormApiErrors'
import { usePatchEditPasswordMutation } from '@/state/services/account/securitiy'

import DocumentWrapper from '../../../components/DocumentWrapper'
import ScreenWrapper from '../../../components/ScreenWrapper'

import type {
  OnSubmit, FormValues,
} from './types'

function EditPassword(): React.ReactElement {
  const navigate = useNavigate()
  const session = useSession()
  const handleFormApiErrors = useHandleFormApiErrors()
  const { setAlert } = useAlert()

  const { id: userId } = session.user || { id: '' }

  const [
    patchEditPassword,
    { isLoading },
  ] = usePatchEditPasswordMutation()

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  }
  const { rules } = api.endpoints.user.security.validation.patch.body

  const handleSubmit = useCallback<OnSubmit>(async (values: FormValues, { setErrors }) => {
    patchEditPassword({
      body: values,
      params: { id: userId ?? '' },
    })
      .unwrap()
      .then(() => {
        navigate('/account')
        setAlert({
          description: 'Your password has been updated!',
          type: 'success',
        })
      }).catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
          showFieldErrorsAsToast: error.status !== 403,
        })
      })
  }, [
    navigate,
    handleFormApiErrors,
    patchEditPassword,
    setAlert,
    userId,
  ])

  return (
    <DocumentWrapper title='Common Sense Privacy | My Account Edit Password'>
      <ScreenWrapper>
        <Container>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/account' variant='body2'>
              Account
            </Link>
            <Typography color='text.primary' variant='body2'>Edit Password</Typography>
          </Breadcrumbs>
          <Typography variant='h1'>Password</Typography>
          <Typography variant='intro' mb={4}>Edit your password</Typography>
          <Divider />
          <Form<FormValues> initialValues={initialValues} onSubmit={handleSubmit} rules={rules}>
            <Stack maxWidth='sm' marginTop={4} spacing={4}>
              <FormField
                name='currentPassword'
                type='password'
                label='Current Password'
                variant='outlined'
                inputProps={{ 'data-testid': 'current-password' }}
              />

              <FormField
                name='newPassword'
                type='password'
                label='New Password'
                variant='outlined'
                inputProps={{ 'data-testid': 'new-password' }}
                fieldHelperText='Include a minimum of 8 characters made up of numbers, letters and a special character.'
              />

              <FormField
                name='confirmPassword'
                type='password'
                label='Confirm New Password'
                variant='outlined'
                inputProps={{ 'data-testid': 'confirm-password' }}
              />

              <Stack direction='row' spacing={2}>
                <Button
                  variant='contained'
                  disabled={isLoading}
                  type='submit'
                  role='button'
                >
                  Save
                </Button>
                <Button variant='outlined' onClick={() => navigate(-1)}>Cancel</Button>
              </Stack>
            </Stack>
          </Form>
          <Stack mt={8}>
            <Divider />
          </Stack>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(EditPassword)
