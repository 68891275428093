import {
  Box,
  Chip,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'

import ResourceCard from '@/components/ResourceCard'

import DocumentWrapper from '../../../../components/DocumentWrapper'
import ScreenWrapper from '../../../../components/ScreenWrapper'

function DashboardResourcesDetail(): React.ReactElement {
  return (
    <DocumentWrapper title='Common Sense Privacy | Dashboard Resources Detail'>
      <ScreenWrapper bgcolor='grey.50'>
        <Container>
          <Stack mb={8} maxWidth='750px' mx='auto'>
            <Box
              component='img'
              sx={{
                height: 'auto',
                width: '100%',
                maxWidth: 650,
                margin: '0 auto 40px auto',
              }}
              alt='Featured Image'
              src='https://placehold.co/650x425'
            />
            <Typography variant='body3' color='text.secondary' mb={1}>09.07.2022 - Common Sense Media</Typography>
            <Typography variant='h1' mb={2}>Update Notices For Changes to a Privacy Policy</Typography>
            <Typography>
              When sending an update notice it’s good practice to include a short summary of the changes you’ve made and advise users of the date the new.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Etiam venenatis, enim id interdum commodo, ante justo ornare risus, et mattis tellus tellus id dui.
              Sed a blandit magna, eu posuere nisl.
              Phasellus posuere luctus eros, at porttitor neque vulputate nec.
              Sed quis laoreet ligula. Quisque bibendum non ipsum vitae semper.
              In nec arcu viverra, vestibulum libero eget, mattis arcu.
              Nulla sed ipsum in orci pellentesque fringilla.
              Pellentesque convallis, massa non malesuada finibus, neque risus cursus nisl, eget lobortis
              leo orci ac ipsum. Mauris pellentesque ex vel erat sollicitudin, eget aliquet lorem vulputate.
              Praesent faucibus metus non odio placerat, a rutrum ligula suscipit.
              In neque sem, ultrices et velit nec, bibendum sodales libero. Ut vitae justo leo.
              Ut volutpat porta urna sit amet egestas.
            </Typography>
            <Box mt={4}>
              <Chip
                sx={{
                  marginBottom: '6px',
                  marginRight: '6px',
                }}
                key='1.0.0'
                label='1.0.0'
                variant='results'
              />
              <Chip
                sx={{
                  marginBottom: '6px',
                  marginRight: '6px',
                }}
                key='Data Sharing'
                label='Data Sharing'
                variant='results'
              />
              <Chip
                sx={{
                  marginBottom: '6px',
                  marginRight: '6px',
                }}
                key='Transparency'
                label='Transparency'
                variant='results'
              />
            </Box>
          </Stack>
        </Container>
        <Container>
          <Stack spacing={2} mb={3}>
            <Typography variant='h4'>Related Resources & Guides</Typography>
            <Divider />
          </Stack>
          <Grid container={true} spacing={8} mb={6}>
            <Grid item={true} xs={12} sm={6} md={4}>
              <ResourceCard
                date='09.07.2022 - Common Sense Media'
                title='Best Practices for Material Updates to Your Privacy Policy'
                description='When sending an update notice it is good practice to include a short summary of the changes you have made and advise users of the date the new...'
                link='/dashboard/resources/detail'
                categories={[
                  '1.1.2',
                  'Data Sharing',
                ]}
              />
            </Grid>
            <Grid item={true} xs={12} sm={6} md={4}>
              <ResourceCard
                date='09.07.2022 - Common Sense Media'
                title='9 simple ways to update customers on policy changes'
                description='When sending an update notice it is good practice to include a short summary of the changes you have made and advise users of the date the new...'
                link='/dashboard/resources/detail'
                categories={[
                  'CalOPPA',
                  '1.1.2',
                  'Data Sharing',
                ]}
              />
            </Grid>
            <Grid item={true} xs={12} sm={6} md={4}>
              <ResourceCard
                date='09.07.2022 - Common Sense Media'
                title='Best Practices for Material Updates to Your Privacy Policy'
                description='When sending an update notice it is good practice to include.'
                link='/dashboard/resources/detail'
                categories={[
                  'CalOPPA',
                  '1.1.2',
                  'Data Sharing',
                ]}
              />
            </Grid>
          </Grid>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(DashboardResourcesDetail)
