import React from 'react'
import {
  Box,
  Divider,
  Link,
  Stack,
} from '@mui/material'

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

import QuestionsContactSupport from '@/components/QuestionsContactSupport'

import Sidebar from '../Sidebar'

import { Props } from './types'

function WiardQuestionsLayout({
  children,
  backLink,
  hasQuestionsFooter = true,
}: Props): React.ReactElement {
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'flex-start',
      flex: 1,
      height: '100%',
      maxWidth: 'xl',
    }}
    >
      <Sidebar />
      <Stack sx={{
        pt: 8,
        pb: 6,
        flex: 1,
        alignItems: 'flex-start',
      }}
      >
        {backLink
        && (
          <Stack>
            <Link
              href={backLink}
              variant='body2'
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: 'text.primary',
                textDecoration: 'none',
                fontWeight: 'bold',
              }}
            ><KeyboardArrowLeftIcon /> Back
            </Link>
          </Stack>
        ) }
        {children}
        {hasQuestionsFooter
        && (
          <Stack>
            <Stack mt={4} spacing={4}>
              <Divider />
              <QuestionsContactSupport />
            </Stack>
          </Stack>
        ) }
      </Stack>
    </Box>
  )
}

export default React.memo(WiardQuestionsLayout)
