import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'

import { RootState } from '../../types'

import {
  ProductInformationState,
  ProductInfo,
} from './types'

export const initialState: ProductInformationState = {
  hasExistingPolicy: true,
  policyUrl: '',
}

export const productInformationSlice = createSlice({
  name: 'productInformation',
  initialState,
  reducers: {
    setHasExistingPolicy: (state, action: PayloadAction<boolean>) => {
      state.hasExistingPolicy = action.payload

      return state
    },
    setPolicyUrl: (state, action: PayloadAction<string>) => {
      state.policyUrl = action.payload

      return state
    },
    setProductInfo: (state, action: PayloadAction<ProductInfo>) => {
      state.productInfo = action.payload

      return state
    },
  },
})

export const {
  setHasExistingPolicy,
  setPolicyUrl,
  setProductInfo,
} = productInformationSlice.actions

export const productInformationSelectors = { productInformation: (state: RootState) => state.productInformation }

export default productInformationSlice.reducer

export * from './types'
