import React from 'react'

import { url } from '@common-sense-privacy/common'

import {
  Box,
  Button,
  Container,
  Divider,
  Typography,
} from '@mui/material'

import { Link as RouterLink } from 'react-router-dom'

import DocumentWrapper from '../../components/DocumentWrapper'
import ScreenWrapper from '../../components/ScreenWrapper'

import serverError from './assets/500.svg'

function Error500(): React.ReactElement {
  return (
    <DocumentWrapper title='Common Sense Privacy | Server Error'>
      <ScreenWrapper>
        <Container>
          <Box component='center'>

            <Box
              component='img'
              src={serverError}
              width={218}
              height={156}
              alt='Not Found'
              mb={6}
            />

            <Typography variant='h1' mb={4}>Oops! 500 - server error.</Typography>
            <Typography variant='h5' mb={6} maxWidth='sm'>Something went wrong. Try to refresh this page or feel free  to contact us if the problem persists.</Typography>
            <Divider />
            <Box mt={6}>
              <Button to={url.marketingSite('/contact-us')} component={RouterLink}>Contact Us</Button>
            </Box>
          </Box>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(Error500)
