import { api } from '@common-sense-privacy/common'

import commonSenseApi from '../../state/apis/commonSense'

const categoryService = commonSenseApi.injectEndpoints({
  endpoints: build => ({
    getCategories: build.query<
      api.endpoints.category.types.GetResponseBody,
      void
    >({
      query: () => ({
        url: 'v1/categories',
        method: 'GET',
      }),
    }),
  }),
})

export default categoryService

export const { useGetCategoriesQuery } = categoryService

export const { endpoints: { getCategories } } = categoryService
