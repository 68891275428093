import React from 'react'
import {
  Box,
  CircularProgress,
  Typography,
} from '@mui/material'

function Loading() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
      }}
      my={6}
    >
      <Typography mb={6} fontSize={24} fontWeight='bold'>Please stand by...</Typography>
      <CircularProgress size='9rem' />
    </Box>
  )
}

export default React.memo(Loading)
