import React from 'react'

import {
  Box,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import AssetsIcon from './assets/icon-questions.svg'

function Questions() {
  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      <Box
        component='img'
        sx={{
          height: 35,
          width: 35,
        }}
        alt='Answer All'
        src={AssetsIcon}
      />
      <Typography fontWeight='bold'>Questions? </Typography>
      <Typography>
        <Link
          component={RouterLink}
          to='/contact-support'
          color='text.secondary'
          sx={{ fontWeight: 'bold' }}
        >Contact Support
        </Link>
      </Typography>
    </Stack>
  )
}

export default React.memo(Questions)
