import {
  Button,
  Container,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import {
  setHasExistingPolicy,
  useHasExistingPolicy,
} from '@/hooks/useProductInformation'

import DocumentWrapper from '../../../../components/DocumentWrapper'
import ScreenWrapper from '../../../../components/ScreenWrapper'

function PrivacyPolicy(): React.ReactElement {
  const dispatch = useDispatch()
  const hasPrivacyPolicy = useHasExistingPolicy()

  return (
    <DocumentWrapper title='Common Sense Privacy | Privacy Policy'>
      <ScreenWrapper>
        <Container>
          <Typography variant='h5' textAlign='center' mb={4}>Do you have an existing privacy policy?</Typography>
          <Stack spacing={2} alignItems='center'>
            <Button
              component={RouterLink}
              to='/wizard/product/privacy-policy/url'
              variant={hasPrivacyPolicy ? 'contained' : 'outlined'}
              onClick={() => dispatch(setHasExistingPolicy(true))}
            >
              Yes
            </Button>
            <Button
              component={RouterLink}
              variant={!hasPrivacyPolicy ? 'contained' : 'outlined'}
              to='/wizard/product/information'
              onClick={() => dispatch(setHasExistingPolicy(false))}
            >
              No
            </Button>
          </Stack>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(PrivacyPolicy)
