import {
  array,
  lang,
} from '@common-sense-privacy/common'
import log from '@common-sense-privacy/common/dist/src/log/browser'

import {
  FormErrors,
  HandleFormApiErrors,
} from './types'

const useHandleFormApiErrors = (): HandleFormApiErrors => ({
  defaultMessage = 'Something went wrong.',
  error,
  setErrors,
  showFieldErrorsAsToast = false,
}) => {
  const errors = error.data?.errors
  const message = error.data?.message
  if (setErrors) {
    const formErrors = Object.keys(errors || {}).reduce((acc: FormErrors, key) => {
      const errorMessage = errors?.[key as keyof typeof errors].message

      if (errorMessage) {
        acc[key] = errorMessage
      }

      return acc
    }, {})

    setErrors(formErrors)
  }

  const showToast = showFieldErrorsAsToast === true
      || !Object.keys(errors || {}).length
      || (Array.isArray(showFieldErrorsAsToast) && (showFieldErrorsAsToast || []).find(field => !!errors?.[field]))

  if (showToast) {
    const fieldErrorMessages = (Array.isArray(showFieldErrorsAsToast) && (showFieldErrorsAsToast || []).reduce((acc: string[], key) => {
      if (errors && errors[key] && errors[key].message) {
        acc.push(errors[key].message)
      }

      return acc
    }, [])) || []

    const description = (fieldErrorMessages.length && array.toListString(fieldErrorMessages)) || message || defaultMessage

    /**
     * @todo instead of toast use material alert and remove log
     */
    // toast.show({
    //   description,
    //   title: lang().messages.genericErrorHeading(),
    //   type: 'error',
    // })
    log('info', description)
    log('info', lang().messages.genericErrorHeading())
  }
}

export default useHandleFormApiErrors

export * from './types'
