import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { createRoot } from 'react-dom/client'

import AlertProvider from '@/context/AlertContext'
import StripeProvider from '@/context/StripeContext'

import store from '@/state'

import App from '@/App'

function Root() {
  return (
    <ReduxProvider store={store}>
      <AlertProvider>
        <StripeProvider>
          <App />
        </StripeProvider>
      </AlertProvider>
    </ReduxProvider>
  )
}

// Start the mocking conditionally.
if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_API === 'msw') {
  // eslint-disable-next-line
  const { worker } = require('./mock-api/worker')
  worker.start()
}

const rootTag = createRoot(document.getElementById('root') as HTMLElement)
rootTag.render(<Root />)
