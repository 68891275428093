import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'

import ResourceCard from '@/components/ResourceCard'

import DocumentWrapper from '../../../components/DocumentWrapper'
import ScreenWrapper from '../../../components/ScreenWrapper'

function DashboardResources(): React.ReactElement {
  return (
    <DocumentWrapper title='Common Sense Privacy | Dashboard Resources'>
      <ScreenWrapper bgcolor='grey.50'>
        <Container>
          <Stack direction='row' justifyContent='space-between' mb={6}>
            <Typography variant='h1'>Resources</Typography>
          </Stack>
        </Container>
        <Container>
          <Grid container={true} spacing={8} mb={6}>
            <Grid item={true} xs={12} sm={6} md={4}>
              <ResourceCard
                date='09.07.2022 - Common Sense Media'
                title='Update Notices For Changes to a Privacy Policy'
                description='Update Users Through Email. Another effective method for sharing notifications about updates to your Privacy Policy is through the use of email.'
                link='/dashboard/resources/detail'
                categories={[
                  'CalOPPA',
                  '1.1.2',
                  'Data Sharing',
                ]}
              />
            </Grid>
            <Grid item={true} xs={12} sm={6} md={4}>
              <ResourceCard
                date='09.07.2022 - Common Sense Media'
                title='Best Practices for Material Updates to Your Privacy Policy'
                description='When sending an update notice it is good practice to include a short summary of the changes you have made and advise users of the date the new...'
                link='/dashboard/resources/detail'
                categories={[
                  '1.1.2',
                  'Data Sharing',
                ]}
              />
            </Grid>
            <Grid item={true} xs={12} sm={6} md={4}>
              <ResourceCard
                date='09.07.2022 - Common Sense Media'
                title='9 simple ways to update customers on policy changes'
                description='When sending an update notice it is good practice to include a short summary of the changes you have made and advise users of the date the new...'
                link='/dashboard/resources/detail'
                categories={[
                  'CalOPPA',
                  '1.1.2',
                  'Data Sharing',
                ]}
              />
            </Grid>
            <Grid item={true} xs={12} sm={6} md={4}>
              <ResourceCard
                date='09.07.2022 - Common Sense Media'
                title='Best Practices for Material Updates to Your Privacy Policy'
                description='When sending an update notice it is good practice to include.'
                link='/dashboard/resources/detail'
                categories={[
                  'CalOPPA',
                  '1.1.2',
                  'Data Sharing',
                ]}
              />
            </Grid>
            <Grid item={true} xs={12} sm={6} md={4}>
              <ResourceCard
                date='09.07.2022 - Common Sense Media'
                title='Update Notices For Changes to a Privacy Policy'
                description='When sending an update notice it is good practice to include a short summary of the changes you have made and advise users of the date the new...'
                link='/dashboard/resources/detail'
                categories={[
                  'CalOPPA',
                  '1.1.2',
                  'Data Sharing',
                ]}
              />
            </Grid>
            <Grid item={true} xs={12} sm={6} md={4}>
              <ResourceCard
                date='09.07.2022 - Common Sense Media'
                title='9 simple ways to update customers on policy changes'
                description='When sending an update notice it is good practice to include a short summary of the changes you have made and advise users of the date the new...'
                link='/dashboard/resources/detail'
                categories={[
                  'CalOPPA',
                  '1.1.2',
                  'Transparency',
                ]}
              />
            </Grid>
            <Grid item={true} xs={12} sm={6} md={4}>
              <ResourceCard
                date='09.07.2022 - Common Sense Media'
                title='Update Notices For Changes to a Privacy Policy'
                description='When sending an update notice it is good practice to include a short summary of the changes you have made and advise users of the date the new...'
                link='/dashboard/resources/detail'
                categories={[ 'CalOPPA' ]}
              />
            </Grid>
            <Grid item={true} xs={12} sm={6} md={4}>
              <ResourceCard
                date='09.07.2022 - Common Sense Media'
                title='Update Notices For Changes to a Privacy Policy'
                description='When sending an update notice it is good practice to include a short summary of the changes you have made and advise users of the date the new...'
                link='/dashboard/resources/detail'
                categories={[
                  'CalOPPA',
                  '1.1.2',
                  'Data Sharing',
                ]}
              />
            </Grid>
            <Grid item={true} xs={12} sm={6} md={4}>
              <ResourceCard
                date='09.07.2022 - Common Sense Media'
                title='Best Practices for Material Updates to Your Privacy Policy'
                description='Update Users Through Email. Another effective method for sharing notifications about updates to your Privacy Policy is through the use of email..'
                link='/dashboard/resources/detail'
                categories={[
                  'CalOPPA',
                  'Data Sharing',
                  'Transparency',
                ]}
              />
            </Grid>
            <Grid item={true} xs={12} sm={6} md={4}>
              <ResourceCard
                date='09.07.2022 - Common Sense Media'
                title='9 simple ways to update customers on policy changes'
                description='When sending an update notice it is good practice to include a short summary of the changes you have made and advise.'
                link='/dashboard/resources/detail'
                categories={[
                  'CalOPPA',
                  '1.1.2',
                  'Data Sharing',
                ]}
              />
            </Grid>
            <Grid item={true} xs={12} sm={6} md={4}>
              <ResourceCard
                date='09.07.2022 - Common Sense Media'
                title='Update Notices For Changes to a Privacy Policy'
                description='When sending an update notice it is good practice to include a short summary of the changes you have made and advise users of the date the new...'
                link='/dashboard/resources/detail'
                categories={[
                  'CalOPPA',
                  '1.1.2',
                  'Data Sharing',
                ]}
              />
            </Grid>
            <Grid item={true} xs={12} sm={6} md={4}>
              <ResourceCard
                date='09.07.2022 - Common Sense Media'
                title='Update Notices For Changes to a Privacy Policy'
                description='When sending an update notice it is good practice to include a short summary of the changes you have made and advise users of the date the new...'
                link='/dashboard/resources/detail'
                categories={[
                  'CalOPPA',
                  '1.1.2',
                  'Data Sharing',
                ]}
              />
            </Grid>
          </Grid>
          <Box mb={6} textAlign='center' position='relative'>
            <Box position='absolute' width='100%' top='50%'>
              <Divider />
            </Box>
            <Button
              variant='outlined'
            >More Resources
            </Button>
          </Box>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(DashboardResources)
