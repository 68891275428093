import React from 'react'
import {
  Box,
  Typography,
} from '@mui/material'

import { useNavigate } from 'react-router-dom'

import { Props } from './types'

function TabItem({
  question,
  topic,
  title,
  to,
  ...props
}: Props): React.ReactElement {
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        borderBottom: '1px solid #CCC',
        marginBottom: '10px',
        paddingBottom: '10px',
        cursor: 'pointer',
      }}
      {...props}
      onClick={() => navigate(to)}
    >
      <Typography mb={2}>Question {question}</Typography>
      <Typography>Topic: {topic}</Typography>
      <Typography>Title: {title}</Typography>
    </Box>
  )
}

export default React.memo(TabItem)
