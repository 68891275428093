import React from 'react'

import { api } from '@common-sense-privacy/common'

import type { SessionState } from '@/state/slices/session/types'

export type Props = {
  children?: React.ReactElement,
  otherwise?: React.ReactElement | (React.ReactElement | undefined)[],
  test?: Test | Test[],
  /**
   * @todo fix toast types
   */
  toast?: (undefined)[],
}

export type Test = (session: SessionState) => boolean

export type Plan = api.endpoints.stripe.plans.types.Product

const { PlanType } = api.endpoints.stripe.plans.types

export { PlanType }
