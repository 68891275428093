import {
  useState,
  useEffect,
  useRef,
} from 'react'

import { useGetSubscriptionQuery } from '@/services/stripe'

import {
  Props,
  SubscriptionWithExpansions,
} from './types'

function useWaitForSubscriptionChange({
  canWait,
  subscription,
}: Props) {
  const [
    subscriptionChanged,
    setSubscriptionChanged,
  ] = useState<boolean>(false)
  const savedSubscription = useRef<SubscriptionWithExpansions | undefined>(undefined)
  const {
    data: response,
    isFetching,
    refetch,
  } = useGetSubscriptionQuery(undefined)
  const changedSubscription = response?.data

  useEffect(() => {
    if (!savedSubscription.current && subscription) {
      savedSubscription.current = subscription
    }
    if (!canWait && !subscriptionChanged) return

    const currentPlan = savedSubscription.current?.plan?.product?.metadata?.type
    const newPlan = changedSubscription?.plan?.product?.metadata?.type
    const currentPaymentMethod = savedSubscription.current?.customer?.invoice_settings?.default_payment_method
    const newPaymentMethod = changedSubscription?.customer?.invoice_settings?.default_payment_method
    const currentPhase = savedSubscription.current?.upcomingPlanChangePhase
    const newPhase = changedSubscription?.upcomingPlanChangePhase
    if (currentPhase === newPhase && currentPlan === newPlan && currentPaymentMethod === newPaymentMethod) {
      if (!isFetching) refetch()
    }
    else {
      setSubscriptionChanged(true)
    }
  }, [
    canWait,
    subscription,
    changedSubscription,
    isFetching,
    refetch,
    setSubscriptionChanged,
    subscriptionChanged,
  ])

  return subscriptionChanged
}

export default useWaitForSubscriptionChange
