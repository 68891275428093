import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { FormikProps } from 'formik'

import {
  Box,
  Button,
  Container,
  Divider,
  Link,
  MenuItem,
  Stack,
  Typography,
  Grid,
  CircularProgress,
} from '@mui/material'

import {
  api,
  url,
} from '@common-sense-privacy/common'

import Form from '@/components/Form'
import FormField from '@/components/FormField'
import AutocompleteField from '@/components/FormField/Autocomplete'

import useHandleFormApiErrors from '@/hooks/useHandleFormApiErrors'
import useNavigate from '@/hooks/useNavigate'
import useDebouncedValue from '@/hooks/useDebouncedValue'

import { usePostSignUpMutation } from '@/services/signup'
import { setSession } from '@/state/slices/session'
import { useGetRolesQuery } from '@/services/roles'
import {
  useGetDetailedGoogleAddressQuery,
  useGetSuggestedGoogleAddressesQuery,
} from '@/services/googlePlaces'

import { useSignup } from '@/context/SignupContext'

import DocumentWrapper from '../../components/DocumentWrapper'
import ScreenWrapper from '../../components/ScreenWrapper'

import type {
  FormValues,
  OnSubmit,
  Role,
  Address,
} from './types'

function SignUp(): React.ReactElement {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleFormApiErrors = useHandleFormApiErrors()
  const formikRef = useRef<FormikProps<FormValues> | undefined>(undefined)
  const {
    signupValues,
    setSignupValues,
  } = useSignup()

  const {
    data: roles,
    isLoading: isRolesLoading,
  } = useGetRolesQuery()

  const [
    streetAddress,
    setStreetAddress,
  ] = useState('')

  const [
    countryISOCode,
    setCountryISOCode,
  ] = useState('us')

  const [
    placeId,
    setPlaceId,
  ] = useState('')

  const debouncedStreetAddress = useDebouncedValue(streetAddress, 100)

  const {
    data: suggestedAddresses,
    isLoading: isAddressesLoading,
  } = useGetSuggestedGoogleAddressesQuery({
    search: debouncedStreetAddress,
    countryISOCode,
  }, { refetchOnMountOrArgChange: true })

  const { data: detailedGoogleAddress } = useGetDetailedGoogleAddressQuery({ search: placeId }, { refetchOnMountOrArgChange: true })

  const [
    postSignUp,
    { isLoading },
  ] = usePostSignUpMutation()

  useEffect(() => {
    if (!detailedGoogleAddress || !detailedGoogleAddress.data || !formikRef.current) return

    const {
      city,
      state,
      postalCode,
    } = detailedGoogleAddress.data

    formikRef.current.setFieldValue('city', city)
    formikRef.current.setFieldValue('state', state)
    formikRef.current.setFieldValue('zipCode', postalCode)
  }, [
    detailedGoogleAddress,
    setSignupValues,
  ])

  const handleSubmit = useCallback<OnSubmit>(async (values: FormValues, { setErrors }) => {
    setSignupValues(values)
    postSignUp(values)
      .unwrap().then(response => {
        dispatch(setSession(response.data))
        navigate('/signup/verify')
      }).catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
          showFieldErrorsAsToast: true,
        })
      })
  }, [
    setSignupValues,
    postSignUp,
    navigate,
    dispatch,
    handleFormApiErrors,
  ])

  const { rules } = api.endpoints.signup.validation.post.body
  const organizationSizes = api.endpoints.signup.validation.ORGANIZATION_SIZES
  const countryList: { name: string, code: string }[] = api.endpoints.organization.validation.COUNTRY_LIST

  return (
    <DocumentWrapper title='Common Sense Privacy | Sign Up'>
      <ScreenWrapper>
        <Container maxWidth='md'>
          <Typography variant='h5' textAlign='center'>To get started with the Privacy Wizard, create an account for your organization.</Typography>
          <Divider />
        </Container>
        <Container maxWidth='md'>
          <Form<FormValues> initialValues={signupValues} onSubmit={handleSubmit} rules={rules}>
            {formik => (
              <>
                <Grid container={true} spacing={4} justifyContent='space-between' mb={3}>
                  <Grid item={true}>
                    <Typography variant='body2' textAlign='left'>Account Information</Typography>
                  </Grid>
                  <Grid item={true} md={8}>
                    <Stack maxWidth='sm' marginTop={3} spacing={4}>
                      {/* Commented out for v1.0 */}
                      {/* <Typography variant='intro' textAlign='center'>Complete questions for free. Pay to download your policy.</Typography> */}
                      <FormField
                        name='firstName'
                        label='First Name'
                        variant='outlined'
                        type='text'
                        inputProps={{ 'data-testid': 'signup-first-name' }}
                      />
                      <FormField
                        name='lastName'
                        label='Last Name'
                        variant='outlined'
                        type='text'
                        inputProps={{ 'data-testid': 'signup-last-name' }}
                      />
                      <FormField
                        name='jobTitle'
                        label='Job Title'
                        variant='outlined'
                        type='text'
                        inputProps={{ 'data-testid': 'signup-job-title' }}
                      />
                      <FormField
                        type='select'
                        name='roles'
                        labelId='demo-simple-select-label'
                        id='demo-multiple-name'
                        label='Functional Roles'
                        data-testid='signup-functional-roles-wrapper'
                        inputProps={{ 'data-testid': 'signup-functional-roles' }}
                        multiple={true}
                      >
                        {(roles?.data || []).map((role: Role) => (
                          <MenuItem
                            key={role.id}
                            value={role.id}
                          >
                            {role.name}
                          </MenuItem>
                        ))}
                      </FormField>

                      <FormField
                        name='email'
                        label='Email'
                        variant='outlined'
                        inputProps={{ 'data-testid': 'signup-email' }}
                      />
                      <FormField
                        name='password'
                        type='password'
                        label='Password *'
                        variant='outlined'
                        inputProps={{ 'data-testid': 'signup-password' }}
                        fieldHelperText='Include a minimum of 8 characters made up of numbers, letters, and a special character.'
                      />

                      <FormField
                        name='confirmPassword'
                        type='password'
                        label='Confirm Password *'
                        variant='outlined'
                        inputProps={{ 'data-testid': 'signup-confirm-password' }}
                      />
                    </Stack>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container={true} spacing={4} justifyContent='space-between' mb={3}>
                  <Grid item={true}>
                    <Typography variant='body2' textAlign='left'>Organization Information</Typography>
                  </Grid>
                  <Grid item={true} md={8}>
                    <Stack maxWidth='sm' marginTop={3} spacing={4}>
                      <FormField
                        name='organizationName'
                        label='Organization Name'
                        variant='outlined'
                        type='text'
                        inputProps={{ 'data-testid': 'signup-organization-name' }}
                      />
                      <FormField
                        type='select'
                        name='organizationSize'
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        label='Organization Size'
                        inputProps={{ 'data-testid': 'signup-organization-size' }}
                      >
                        {organizationSizes.map((size: string) => (
                          <MenuItem key={size} value={size}>{size}</MenuItem>
                        ))}
                      </FormField>
                      <FormField
                        name='organizationEmail'
                        label='Organization Email'
                        variant='outlined'
                        inputProps={{ 'data-testid': 'signup-organization-email' }}
                        fieldHelperText='Organization email will appear in the privacy policy.'
                      />
                      <FormField
                        type='select'
                        name='organizationCountry'
                        labelId='orgnization-country-select-label'
                        id='organization-country-select'
                        label='Country/State of Organziation'
                        inputProps={{ 'data-testid': 'signup-organization-country' }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const country = countryList.find(countryItem => countryItem.name === e.target.value)
                          setCountryISOCode(country?.code ?? '')
                        }}
                      >
                        {countryList.map((item: { name: string, code: string }) => (
                          <MenuItem key={item.name} value={item.name}>{item.name}</MenuItem>
                        ))}
                      </FormField>
                      <AutocompleteField<Address>
                        name='streetAddress'
                        label='Street Address *'
                        onInputChange={(event, newInputValue) => setStreetAddress(newInputValue)}
                        onChange={(event, address: string | Address | null) => {
                          const placeId = typeof address === 'string' ? '' : address?.placeId || ''
                          setPlaceId(placeId)
                          formikRef.current = formik
                        }}
                        getOptionLabel={option => typeof option === 'string' ? option : option?.description || ''}
                        options={suggestedAddresses?.data || []}
                        formControlProps={{ fullWidth: true }}
                        loading={isAddressesLoading}
                        loadingText={(
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              width: '100%',
                              justifyContent: 'center',
                            }}
                            py={3}
                          >
                            <CircularProgress size='1.5rem' />
                          </Box>
                        )}
                        data-testid='signup-organization-address'
                      />
                      <FormField
                        name='city'
                        label='City'
                        variant='outlined'
                        type='text'
                        inputProps={{ 'data-testid': 'signup-city' }}
                      />
                      <Grid container={true} justifyContent='space-between'>
                        <Grid item={true}>
                          <FormField
                            name='state'
                            label='State'
                            variant='outlined'
                            type='text'
                            inputProps={{ 'data-testid': 'signup-state' }}
                            style={{ width: '100%' }}
                          />
                        </Grid>
                        <Grid item={true}>
                          <FormField
                            name='zipCode'
                            label='Zip Code'
                            variant='outlined'
                            type='text'
                            inputProps={{ 'data-testid': 'signup-zip-code' }}
                            style={{ width: '100%' }}
                          />
                        </Grid>
                      </Grid>
                      <FormField
                        name='poBox'
                        label='PO Box'
                        variant='outlined'
                        type='text'
                        inputProps={{ 'data-testid': 'signup-po-box' }}
                      />
                    </Stack>
                  </Grid>
                </Grid>
                <Divider />
                <FormField
                  name='agreeToTerms'
                  type='checkbox'
                  label={(
                    <Typography variant='body2'>
                      I have read and agree to the{' '}
                      <Link href={url.marketingSite('/terms-of-service')} target='_blank' color='inherit' underline='hover'>
                        Terms{' '}
                      </Link>
                      &{' '}
                      <Link href={url.marketingSite('/privacy-policy')} target='_blank' color='inherit' underline='hover'>
                        Privacy Policy
                      </Link>
                    </Typography>
                  )}
                />
                <Box textAlign='center' mt={5}>
                  <Button
                    variant='contained'
                    role='button'
                    type='submit'
                    disabled={isLoading || isRolesLoading}
                  >
                    Create Account
                  </Button>
                </Box>
              </>
            )}
          </Form>
        </Container>
        <Container>
          <Stack spacing={4} mt={4} textAlign='center'>
            <Divider />
            <Typography variant='h6'>Already have an account?</Typography>
            <Box>
              <Button component={RouterLink} to='/signin' variant='contained'>Sign In</Button>
            </Box>
          </Stack>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(SignUp)
