import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Link } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

import { Props } from './types'

function BackLink({
  to,
  onClick,
}: Props): React.ReactElement {
  return (
    <Link
      component={RouterLink}
      to={to}
      variant='body2'
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: 'text.primary',
        textDecoration: 'none',
        fontWeight: 'bold',
      }}
      onClick={onClick}
    ><KeyboardArrowLeftIcon /> Back
    </Link>
  )
}

export default React.memo(BackLink)
