import { useContext } from 'react'

import config from '@/config'

import { NewRelicContext } from '@/context/NewRelicContext'

export default () => {
  const context = useContext(NewRelicContext)

  if (config.newRelic.isEnabled && !context) {
    throw new Error('🐞 useNewRelic must be used within a NewRelicContextProvider')
  }

  return context
}
