import {
  config,
  env,
} from '@common-sense-privacy/common'

import packageJson from '../package.json'

export const { version } = packageJson

export default {
  ...config,
  newRelic: {
    ...config.newRelic,
    accountId: env.get<string>('NEW_RELIC_ACCOUNT_ID'),
    applicationId: env.get<string>('NEW_RELIC_APPLICATION_ID'),
    trustKey: env.get<string>('NEW_RELIC_TRUST_KEY'),
  },
  stripe: { publishableKey: env.get<string>('STRIPE_PUBLISHABLE_KEY') },

}
