import React, {
  useCallback,
  useMemo,
} from 'react'
import {
  Formik, Form as FormikForm,
} from 'formik'

import { validation } from '@common-sense-privacy/common'

import { Stack } from '@mui/material'

import {
  DefaultFormValues,
  Helpers,
  Props,
} from './types'

function Form<T extends DefaultFormValues = DefaultFormValues>({
  children,
  initialErrors = {},
  initialValues = {} as T,
  onSubmit: onSubmitProp,
  rules = {},
  validateOnBlur = false,
  validateOnChange = false,
  ...props
}: Props<T>) {
  const onSubmit = useCallback((values: T, helpers: Helpers<T>) => {
    onSubmitProp && onSubmitProp(values, helpers)
  }, [ onSubmitProp ])

  const formikProps = useMemo(() => ({
    ...props,
    initialErrors,
    initialValues,
    onSubmit,
    validationSchema: validation.schema(rules),
    validateOnBlur,
    validateOnChange,
  }), [
    props,
    initialErrors,
    initialValues,
    onSubmit,
    rules,
    validateOnBlur,
    validateOnChange,
  ])

  const stackProps = useMemo(() => ({ ...props }), [ props ])

  return (
    <Formik<T> {...formikProps}>
      {formik => (
        <FormikForm>
          <Stack {...stackProps}>
            {typeof children === 'function' ? children(formik) : children}
          </Stack>
        </FormikForm>
      )}
    </Formik>
  )
}

export default Form

export * from './types'
