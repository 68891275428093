import React, {
  useEffect, useState,
} from 'react'

import { Box } from '@mui/material'

import { useLocation } from 'react-router-dom'

import Header from './Header'
import Footer from './Footer'

import {
  Props,
  Headers,
} from './types'

function ScreenWrapper({
  children,
  verticalPadding = true,
  ...props
}: Props): React.ReactElement {
  const { pathname } = useLocation()
  const [
    header,
    setHeader,
  ] = useState<Headers>('default')

  useEffect(() => {
    if (pathname.match('wizard') || pathname.match('privacy-policies')) {
      setHeader('wizard')
    }
    else if (pathname.match('dashboard')) {
      setHeader('dashboard')
    }
    else {
      setHeader('default')
    }
  }, [ pathname ])

  return (
    <Box height='100%' display='flex' flexDirection='column' {...props}>
      <Header header={header} />
      <Box
        mb={verticalPadding ? '64px' : '0'} // Need this conditoin for Wizard Drawer sidebar
        mt={verticalPadding ? '192px' : '147px'} // Need this conditoin for Wizard Drawer sidebar
        width='100%'
        flex='1'
      >
        {children}
      </Box>
      <Footer />
    </Box>
  )
}

export default React.memo(ScreenWrapper)

export * from './types'
