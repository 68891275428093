import React from 'react'
import {
  FormControl as MuiFormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps,
  TextField,
  TextFieldProps,
  Select,
  SelectProps,
  FormControlLabel,
  Checkbox,
  FormControlLabelProps,
  Tooltip,
  Typography,
  Stack,
  SxProps,
} from '@mui/material'
import {
  ErrorMessage,
  Field, FieldProps,
} from 'formik'
import {
  Visibility, VisibilityOff,
} from '@mui/icons-material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

type TextProps = TextFieldProps & OutlinedInputProps
type ComponentProps = TextProps | SelectProps

type FormFieldProps = ComponentProps & {
    name: string,
    type?: 'password' | 'select' | 'checkbox' | 'text',
    fieldHelperText?: string,
    formControlProps?: {
      fullWidth?: boolean,
      sx?: SxProps,
    },
    infoTip?: string,
  }

function FormField({
  name,
  type = 'text',
  fieldHelperText,
  formControlProps,
  infoTip,
  ...props
}: FormFieldProps) {
  const [
    showPassword,
    setShowPassword,
  ] = React.useState(false)
  const handleClickShowPassword = () => setShowPassword(show => !show)

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }
  const renderPassword = ({
    label, ...componentProps
  }: TextProps, {
    field,
    meta,
  }: FieldProps) => (
    <>
      <InputLabel htmlFor='outlined-adornment-password'>{`${label || 'Password'}`}</InputLabel>
      <OutlinedInput
        type={showPassword ? 'text' : 'password'}
        error={!!meta.error}
        label={label}
        endAdornment={(
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge='end'
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )}
        {...componentProps}
        {...field}
      />
    </>
  )

  const renderText = (componentProps: TextFieldProps, {
    field,
    meta,
  }: FieldProps) => (
    <TextField
      {...componentProps}
      {...field}
      name={name}
      type='text'
      error={!!meta.error}
    />
  )

  const renderSelect = ({
    labelId,
    label,
    ...otherProps
  }: SelectProps, {
    field,
    meta,
  }: FieldProps) => (
    <>
      {labelId && <InputLabel id={labelId}>{label}</InputLabel> }
      <Select
        {...otherProps}
        {...field}
        labelId={labelId}
        name={name}
        label={label}
        error={!!meta.error}
        onChange={(event, child) => {
          field.onChange && field.onChange(event)
          otherProps.onChange && otherProps.onChange(event, child)
        }}
      />
    </>
  )

  const renderCheckbox = ({ label }: FormControlLabelProps, { field }: FieldProps) => (
    <FormControlLabel
      control={<Checkbox />}
      label={label}
      {...field}
    />
  )

  const renderComponents = (fieldProps: FieldProps) => {
    switch (type) {
      case 'select':
        return renderSelect(props as SelectProps, fieldProps)
      case 'password':
        return renderPassword(props as TextProps, fieldProps)
      case 'checkbox':
        return renderCheckbox(props as FormControlLabelProps, fieldProps)
      default:
        return renderText(props as TextFieldProps, fieldProps)
    }
  }

  return (
    <Field name={name}>
      {({
        field,
        meta,
        ...restFieldProps
      }: FieldProps) => (
        <MuiFormControl variant={type === 'password' ? 'outlined' : undefined} {...formControlProps}>
          {infoTip && (
            <Stack alignItems='flex-end'>
              <Tooltip
                placement='top'
                enterTouchDelay={0}
                PopperProps={{
                  disablePortal: true,
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      height: '100%',
                      maxHeight: '150px',
                      overflow: 'auto',
                    },
                  },
                }}
                title={infoTip}
              ><Typography><InfoOutlinedIcon htmlColor='grey' /></Typography>
              </Tooltip>
            </Stack>
          )}
          {renderComponents({
            field,
            meta,
            ...restFieldProps,
          })}
          {meta.touched && meta.error && (
            <ErrorMessage
              name={name}
              render={(errorMessage: string) => <FormHelperText error={true}>{errorMessage}</FormHelperText>}
            />
          )}
          {!!fieldHelperText && (<FormHelperText>{fieldHelperText}</FormHelperText>)}
        </MuiFormControl>
      ) }
    </Field>
  )
}

export default FormField
