import {
  Box,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'

import { Link as RouterLink } from 'react-router-dom'

import { Props } from './types'

function TimelineDivider() {
  return (
    <Box sx={{
      borderLeft: '1px solid #BDBDBD',
      height: '35px',
      width: '1px',
      marginLeft: '5px',
      display: {
        xs: 'none',
        md: 'block',
      },
    }}
    />
  )
}

function TimelineItem({
  title,
  to,
  status = 'untouched',
  hasDivider = true,
}: Props): React.ReactElement {
  return (
    <Box sx={{
      marginLeft: {
        xs: 0,
        md: 4,
      },
    }}
    >
      {hasDivider
      && <TimelineDivider /> }
      <Stack direction='row' alignItems='center'>
        <Box
          sx={{
            backgroundColor: 'transparent',
            ...(status === 'active' && { backgroundColor: '#23B5E8' }),
            ...(status === 'complete' && { backgroundColor: '#33A544' }),
            ...(status === 'inactive' && { backgroundColor: '#CCC' }),
            border: '1px solid #BDBDBD',
            ...(status === 'active' && { border: '1px solid #23B5E8' }),
            ...(status === 'complete' && { border: '1px solid #33A544' }),
            ...(status === 'incomplete' && { border: '1px solid #33A544' }),
            ...(status === 'inactive' && { backgroundColor: '#CCC' }),
            height: '10px',
            width: '10px',
            borderRadius: '50%',
            marginRight: '10px',
            boxShadow: 'none',
            ...(status === 'incomplete' && { boxShadow: '0 0 2px 2px #33A544' }),
          }}
        />
        {status === 'inactive' ? (<Typography fontWeight='normal' lineHeight={2} color='text.primary'>{title}</Typography>) : (
          <Link
            component={RouterLink}
            to={to}
            sx={{
              color: '#000',
              textDecoration: 'none',
            }}
          ><Typography fontWeight={(status === 'active' ? 'bold' : 'normal')} lineHeight={2} color='text.primary'>{title}</Typography>
          </Link>
        )}

      </Stack>
    </Box>
  )
}

export default React.memo(TimelineItem)
