import React, { useCallback } from 'react'
import {
  Container,
  Button,
  Stack,
  Typography,
} from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { api } from '@common-sense-privacy/common'

import Form from '@/components/Form'
import FormField from '@/components/FormField'

import useHandleFormApiErrors from '@/hooks/useHandleFormApiErrors'
import useNavigate from '@/hooks/useNavigate'
import { useAuthInfo } from '@/hooks/useSession'
import { usePostContactSupportMutation } from '@/services/supportContact'

import { useAlert } from '@/context/AlertContext'

import DocumentWrapper from '../../../components/DocumentWrapper'
import ScreenWrapper from '../../../components/ScreenWrapper'

import type {
  FormValues,
  OnSubmit,
} from './types'

function ContactSupport(): React.ReactElement {
  const navigate = useNavigate()
  const handleFormApiErrors = useHandleFormApiErrors()
  const { setAlert } = useAlert()
  const { isLoggedInAndVerified } = useAuthInfo()

  const [
    postContactSupport,
    { isLoading },
  ] = usePostContactSupportMutation()

  const initialValues = isLoggedInAndVerified ? {
    isLoggedInAndVerified,
    topic: '',
    inquiry: '',
  } : {
    isLoggedInAndVerified,
    firstName: '',
    lastName: '',
    email: '',
    topic: '',
    inquiry: '',
  }

  const handleSubmit = useCallback<OnSubmit>(async (values: FormValues, { setErrors }) => {
    postContactSupport(values)
      .unwrap().then(response => {
        if (response.message) {
          setAlert({
            description: response.message,
            type: 'success',
          })
        }
      }).catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
          showFieldErrorsAsToast: true,
        })
      })
  }, [
    handleFormApiErrors,
    postContactSupport,
    setAlert,
  ])
  const { rules } = api.endpoints.supportContact.validation.post.body

  return (
    <DocumentWrapper title='Common Sense Privacy | Contact Support'>
      <ScreenWrapper>
        <Container maxWidth='sm'>
          <Button
            onClick={() => navigate(-1)}
            variant='link'
            data-testid='privacy-issue-back'
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              color: 'text.primary',
              textDecoration: 'none',
              fontWeight: 'bold',
            }}
          ><KeyboardArrowLeftIcon /> Back
          </Button>

          <Typography variant='h5' textAlign='center' mb={4}>Questions? Contact Support</Typography>
          <Form<FormValues> initialValues={initialValues} onSubmit={handleSubmit} rules={rules}>
            <Stack marginBottom={6} spacing={4} alignItems='center'>
              {!isLoggedInAndVerified && (
                <>
                  <FormField
                    type='text'
                    id='firstName'
                    name='firstName'
                    label='First Name'
                    variant='outlined'
                    minRows={3}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{ 'data-testid': 'contact-support-firstName' }}
                  />
                  <FormField
                    type='text'
                    id='lastName'
                    name='lastName'
                    label='Last Name'
                    variant='outlined'
                    minRows={3}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{ 'data-testid': 'contact-support-lastName' }}
                  />
                  <FormField
                    type='text'
                    id='email'
                    name='email'
                    label='Email'
                    variant='outlined'
                    minRows={3}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{ 'data-testid': 'contact-support-email' }}
                  />
                </>
              )}
              <FormField
                type='text'
                id='topic'
                name='topic'
                label='Topic'
                variant='outlined'
                minRows={3}
                formControlProps={{ fullWidth: true }}
                inputProps={{ 'data-testid': 'contact-support-topic' }}
              />
              <FormField
                type='text'
                id='inquiry'
                name='inquiry'
                label='Inquiry'
                variant='outlined'
                minRows={3}
                formControlProps={{ fullWidth: true }}
                inputProps={{ 'data-testid': 'contact-support-inquiry' }}
              />
              <Button type='submit' role='button' disabled={isLoading}>Submit Request</Button>
            </Stack>
          </Form>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(ContactSupport)
