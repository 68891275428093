import React from 'react'

import {
  Box, LinearProgress, Typography,
} from '@mui/material'

import { Props } from './types'

function ResultsProgressBar({
  color, value = 0, hasLabel = false,
}: Props): React.ReactElement {
  let barColor

  switch (color) {
    case 'green': {
      barColor = '#33A544'
      break
    }
    case 'blue': {
      barColor = '#23B5E8'
      break
    }
    case 'orange': {
      barColor = '#FAA41A'
      break
    }
    default: {
      barColor = '#626262'
      break
    }
  }

  return (
    <Box
      width='100%'
      color={barColor}
      display='flex'
      alignItems='center'
    >
      <Box flex={1}>
        <LinearProgress
          color='inherit'
          variant='determinate'
          value={value}
          sx={{
            padding: '16px',
            borderRadius: 0,
          }}
        />
      </Box>
      {hasLabel
      && <Typography ml={1} variant='subtitle1' color='text.secondary'>{value}%</Typography>}
    </Box>
  )
}

export default React.memo(ResultsProgressBar)
