import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from 'react'

import {
  loadStripe as loadStripeAPI,
  Stripe,
} from '@stripe/stripe-js'

import config from '@/config'

interface StripeContextProps {
  stripe: Stripe | undefined,
  loadStripe: () => Promise<void>,
}

const initialContext: StripeContextProps = {
  stripe: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  loadStripe: () => Promise.resolve(),
}

const StripeContext = createContext<StripeContextProps>(initialContext)

export const useStripe = () => {
  const context = useContext(StripeContext)
  if (!context) {
    throw new Error('useStripe must be used within a StripeProvider')
  }

  const {
    stripe,
    loadStripe,
  } = context

  // In order to avoid ugly calls to loadStripe inside the components, we can just call it here
  if (!stripe) {
    loadStripe()
  }

  return context
}

interface StripeProviderProps {
  children: React.ReactNode,
}

// eslint-disable-next-line react/function-component-definition
const StripeProvider: React.FC<StripeProviderProps> = ({ children }) => {
  const [
    stripe,
    setStripe,
  ] = useState<Stripe | undefined>(undefined)

  // Avoid loading stripe whenever you render the provider by providing a load function
  const loadStripe = useCallback(async () => {
    if (stripe) return

    if (!config.stripe.publishableKey) {
      throw new Error('REACT_APP_STRIPE_PUBLISHABLE_KEY missing from env')
    }

    const stripeInstance = await loadStripeAPI(config.stripe.publishableKey)
    if (!stripeInstance) {
      throw new Error('Failed to load stripe.')
    }

    setStripe(stripeInstance)
  }, [
    stripe,
    setStripe,
  ])

  const contextValue = useMemo((): StripeContextProps => ({
    stripe,
    loadStripe,
  }), [
    stripe,
    loadStripe,
  ])

  return (
    <StripeContext.Provider value={contextValue}>
      {children}
    </StripeContext.Provider>
  )
}

export default StripeProvider
