import { api } from '@common-sense-privacy/common'

export const {
  EXCELLENT,
  RISKY,
  MEETING_REQUIREMENTS,
} = api.endpoints.organization.product.metrics.types.ApplicableLawState

export const HUMANIZED_STATE = {
  [MEETING_REQUIREMENTS]: 'Meeting Requirements',
  [RISKY]: 'Risky',
  [EXCELLENT]: 'Excellent',
}
