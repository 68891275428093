import React from 'react'
import {
  Box,
  CircularProgress,
  Typography,
} from '@mui/material'

import { LoadingProps } from './types'

function CircularLoading({
  title,
  ...props
}: LoadingProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
      }}
      {...props}
    >
      {!!title && <Typography mb={6} fontSize={24} fontWeight='bold'>{title}</Typography>}
      <CircularProgress size='9rem' />
    </Box>
  )
}

export default React.memo(CircularLoading)
