import { api } from '@common-sense-privacy/common'

import commonSenseApi from '../../apis/commonSense'

const userNotificationService = commonSenseApi.injectEndpoints({
  endpoints: build => ({
    patchUserNotification: build.mutation<
      api.endpoints.user.notification.types.PatchResponseBody,
      api.endpoints.user.notification.types.PatchRequest
      >({
        query: ({
          body, params: { id: userId },
        }) => ({
          url: `v1/users/${userId}/notification/`,
          method: 'PATCH',
          body,
        }),
      }),
  }),
})

export default userNotificationService

export const { usePatchUserNotificationMutation } = userNotificationService

export const { endpoints: { patchUserNotification } } = userNotificationService
