import React, { useEffect } from 'react'

import { useCurrentProduct } from '@/hooks/useSession'
import useNavigate from '@/hooks/useNavigate'

import CircularLoading from '@/components/CircularLoading'

import DocumentWrapper from '../../components/DocumentWrapper'
import ScreenWrapper from '../../components/ScreenWrapper'

function Wizard(): React.ReactElement {
  const navigate = useNavigate()
  const product = useCurrentProduct()
  const { id: productId } = product || { id: '' }

  useEffect(() => {
    if (productId) {
      navigate(`/wizard/products/${productId}`)
    }
  }, [
    navigate,
    productId,
  ])

  return (
    <DocumentWrapper title='Common Sense Privacy | Privacy Wizard'>
      <ScreenWrapper>
        <CircularLoading />
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(Wizard)
