import commonSenseApi from '@/state/apis/commonSense'

import {
  GetTopicsRequest,
  GetTopicsResponseBody,
  GetPrivacyTopicRequest,
  GetPrivacyTopicsResponseBody,
} from './types'

const topicsService = commonSenseApi.injectEndpoints({
  endpoints: build => ({
    getProductTopics: build.query<GetTopicsResponseBody, GetTopicsRequest>({
      query: ({
        params: {
          id, productId,
        },
      }) => ({
        url: `v1/organizations/${id}/products/${productId}/topics`,
        method: 'GET',
      }),
      providesTags: [ 'PrivacyTopics' ],
    }),
    getPrivacyTopic: build.query<GetPrivacyTopicsResponseBody, GetPrivacyTopicRequest>({
      query: ({
        params: {
          id, productId, topicId,
        },
      }) => ({
        url: `v1/organizations/${id}/products/${productId}/topics/${topicId}`,
        method: 'GET',
      }),
      providesTags: [ 'PrivacyTopics' ],
    }),
  }),
})

export default topicsService

export const {
  useGetProductTopicsQuery,
  useGetPrivacyTopicQuery,
} = topicsService

export const {
  endpoints: {
    getProductTopics,
    getPrivacyTopic,
  },
} = topicsService
