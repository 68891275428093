import commonSenseApi from '../../../state/apis/commonSense'

import {
  PatchRequest,
  PatchResponseBody,
} from './types'

const contactService = commonSenseApi.injectEndpoints({
  endpoints: build => ({
    updateContactDetails: build.mutation<PatchResponseBody, PatchRequest>({
      query: ({
        params: { id },
        body,
      }) => ({
        url: `v1/users/${id}/contact`,
        method: 'PATCH',
        body,
      }),
    }),
  }),
})

export default contactService

export const { useUpdateContactDetailsMutation } = contactService

export const { endpoints: { updateContactDetails } } = contactService
