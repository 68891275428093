import React from 'react'

import {
  Container,
  Typography,
} from '@mui/material'

import DocumentWrapper from '../../../components/DocumentWrapper'
import ScreenWrapper from '../../../components/ScreenWrapper'

import ScoreIndicator from './components/ScoreIndicator'
import LevelBar from './components/LevelBar'
import RaiseYourScoreSection from './components/RaiseYourScoreSection'

function DashboardRaiseYourScore(): React.ReactElement {
  return (
    <DocumentWrapper title='Common Sense Privacy | Dashboard Raise Your Score'>
      <ScreenWrapper bgcolor='grey.50'>
        <Container>
          <Typography variant='h1' mb={4}>Raise Your Score</Typography>
        </Container>
        <Container data-testid='easy-fix-item-container'>
          <RaiseYourScoreSection
            title='Easy Fixes'
            description='The easiest way to raise your privacy score is to improve transparency and disclose practices that are omitted, unclear or unknown. Address the following questions first...'
            itemsPerPage={4}
            loadMoreText='More Easy Fixes'
            category='easy-fix'
            diagram={(
              <ScoreIndicator scoreLevel='unclear' />
            )}
          />
        </Container>

        <Container data-testid='high-priority-fix-item-container'>
          <RaiseYourScoreSection
            title='High Priority Fixes'
            description='Some fixes require changing how you address privacy, but, prioritizing them can reduce risk and improve public perception. Address these points next...'
            itemsPerPage={4}
            loadMoreText='More High Priority Fixes'
            category='high-priority-fix'
            diagram={(
              <LevelBar title='How much do people care?' />
            )}
          />
        </Container>

        <Container data-testid='improve-practice-item-container'>
          <RaiseYourScoreSection
            title='Improve Your Practices'
            description='Improve your business practices to increase your score on each question. This may include delegating questions to stakeholders...'
            itemsPerPage={4}
            loadMoreText='More Improvements'
            category='improve-practice'
            diagram={(
              <LevelBar title='How easy is this fix?' level='Medium' />
            )}
          />
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(DashboardRaiseYourScore)
