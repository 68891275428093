import { api } from '@common-sense-privacy/common'

import commonSenseApi from '../../state/apis/commonSense'

const productTypeService = commonSenseApi.injectEndpoints({
  endpoints: build => ({
    getProductTypes: build.query<
      api.endpoints.productType.types.GetResponseBody,
      void
    >({
      query: () => ({
        url: 'v1/product-types',
        method: 'GET',
      }),
    }),
  }),
})

export default productTypeService

export const { useGetProductTypesQuery } = productTypeService

export const { endpoints: { getProductTypes } } = productTypeService
