import React from 'react'

import {
  Stack, Typography,
} from '@mui/material'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'

import { Props } from './types'

function ScoreIndicator({ scoreLevel }: Props): React.ReactElement {
  return (
    <Stack spacing={1} pl={2}>
      <Stack
        direction='row'
        bgcolor={scoreLevel === 'good' ? '#33A544' : '#E4E4E4'}
        color={scoreLevel === 'good' ? '#FFF' : '#000'}
        sx={{ position: 'relative' }}
        p={1}
        justifyContent='space-between'
        data-testid='ScoreIndicator-good'
      >
        {scoreLevel === 'good'
        && (
          <PlayArrowIcon
            sx={{
              color: '#000',
              position: 'absolute',
              left: '-25px',
              top: '9px',
              fontSize: 24,
            }}
          />
        ) }
        <Typography variant='subtitle1'>Clear & Good</Typography>
        <Typography variant='subtitle1'>1.0</Typography>
      </Stack>
      <Stack
        direction='row'
        bgcolor={scoreLevel === 'bad' ? '#23B5E8' : '#E4E4E4'}
        color={scoreLevel === 'bad' ? '#FFF' : '#000'}
        sx={{ position: 'relative' }}
        p={1}
        justifyContent='space-between'
        data-testid='ScoreIndicator-bad'
      >
        {scoreLevel === 'bad'
        && (
          <PlayArrowIcon
            sx={{
              color: '#000',
              position: 'absolute',
              left: '-25px',
              top: '9px',
              fontSize: 24,
            }}
          />
        ) }
        <Typography variant='subtitle1'>Clear & Bad</Typography>
        <Typography variant='subtitle1'>0.5 - 0.75</Typography>
      </Stack>
      <Stack
        direction='row'
        bgcolor={scoreLevel === 'unclear' ? '#FAA41A' : '#E4E4E4'}
        color={scoreLevel === 'unclear' ? '#FFF' : '#000'}
        sx={{ position: 'relative' }}
        p={1}
        justifyContent='space-between'
        data-testid='ScoreIndicator-unclear'
      >
        {scoreLevel === 'unclear'
        && (
          <PlayArrowIcon
            sx={{
              color: '#000',
              position: 'absolute',
              left: '-25px',
              top: '9px',
              fontSize: 24,
            }}
          />
        ) }
        <Typography variant='subtitle1'>Unclear</Typography>
        <Typography variant='subtitle1'>0.0</Typography>
      </Stack>
    </Stack>
  )
}

export default React.memo(ScoreIndicator)
