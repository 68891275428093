import commonSenseApi from '@/state/apis/commonSense'

import {
  SendEmailPostRequestPayload,
  SendEmailPostResponsePostResponseBody,
  ConfirmEmailPostRequestPayload,
  ConfirmEmailPostResponsePostResponseBody,
} from './types'

const authService = commonSenseApi.injectEndpoints({
  endpoints: build => ({
    postSendEmail: build.mutation<SendEmailPostResponsePostResponseBody, SendEmailPostRequestPayload>({
      query: () => ({
        url: 'v1/verify/send-email',
        method: 'POST',
      }),
    }),
    postConfirmEmail: build.mutation<ConfirmEmailPostResponsePostResponseBody, ConfirmEmailPostRequestPayload>({
      query: body => ({
        url: 'v1/verify/confirm',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export default authService

export const {
  usePostSendEmailMutation,
  usePostConfirmEmailMutation,
} = authService

export const {
  endpoints: {
    postSendEmail,
    postConfirmEmail,
  },
} = authService
