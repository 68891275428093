import commonSenseApi from '@/state/apis/commonSense'

import {
  GetProductMetricRequest,
  GetProductMetricResponseBody,
  GetPrivacyIssueMetricRequest,
  GetPrivacyIssueMetricResponseBody,
} from './types'

const metricService = commonSenseApi.injectEndpoints({
  endpoints: build => ({
    getProductMetrics: build.query<GetProductMetricResponseBody, GetProductMetricRequest>({
      query: ({
        params: {
          id, productId,
        },
        query: { metric },
      }) => ({
        url: `v1/organizations/${id}/products/${productId}/metrics?${metric.map(value => `metric=${value}`).join('&')}`,
        method: 'GET',
      }),
      providesTags: [ 'ProductScore' ],
    }),
    getPrivacyIssueMetrics: build.query<GetPrivacyIssueMetricResponseBody, GetPrivacyIssueMetricRequest>({
      query: ({
        params: {
          id, productId, privacyIssueId,
        },
        query: { metric },
      }) => ({
        url: `v1/organizations/${id}/products/${productId}/privacy-issues/${privacyIssueId}/metrics?${metric.map(value => `metric=${value}`).join('&')}`,
        method: 'GET',
      }),
      providesTags: [ 'PrivacyIssueScore' ],
    }),
  }),
})

export default metricService

export const {
  useGetProductMetricsQuery,
  useGetPrivacyIssueMetricsQuery,
} = metricService

export const {
  endpoints: {
    getProductMetrics,
    getPrivacyIssueMetrics,
  },
} = metricService
