import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import { Link as RouterLink } from 'react-router-dom'

import { dateTime } from '@common-sense-privacy/common'

import { useGetProductMetricsQuery } from '@/services/organization/product/metric'
import { useGetArticlesQuery } from '@/services/article'
import { Article } from '@/services/article/types'
import {
  useCurrentOrganization,
  useCurrentProduct,
} from '@/hooks/useSession'

import ChartsBenchMark from '@/components/Charts/BenchMark'
import ChartsPrivacy from '@/components/Charts/Privacy'
import ChartsLegal from '@/components/Charts/Legal'
import ChartsScore from '@/components/Charts/Score'
import { TopPrivacyIssueStat } from '@/components/Charts/Privacy/types'

import QuestionsContactSupport from '@/components/QuestionsContactSupport'

import DocumentWrapper from '../../components/DocumentWrapper'
import ScreenWrapper from '../../components/ScreenWrapper'

function Dashboard(): React.ReactElement {
  const {
    id: organizationId, name: organizationName,
  } = useCurrentOrganization()
  const product = useCurrentProduct()
  const { id: productId } = product || { id: '' }

  const { data: metrics } = useGetProductMetricsQuery({
    params: {
      id: organizationId,
      productId,
    },
    query: {
      metric: [
        'score',
        'topPrivacyIssues',
        'benchmarkScore',
        'legallySpeaking',
      ],
    },
  }, { refetchOnMountOrArgChange: true })

  const { data: articlesResponse } = useGetArticlesQuery(undefined, { refetchOnMountOrArgChange: true })

  const score = metrics?.data?.score || 0
  const scoreToImprove = 100 - score

  const topPrivacyIssues:TopPrivacyIssueStat[] = metrics?.data?.topPrivacyIssues || []
  const numOfPrivacyIssuesToImprove = topPrivacyIssues.filter(item => item.score < 1).length
  const stats = metrics?.data?.legallySpeaking || {
    excellent: 0,
    meetingRequirements: 0,
    risky: 0,
    applicableLawScores: [],
  }
  const numOfLawsToImprove = stats.risky + stats.meetingRequirements

  const benchmarkScore = metrics?.data?.benchmarkScore || {
    myScore: 0,
    othersScore: 0,
  }
  const benchmarkScoreToImprove = 100 - benchmarkScore.myScore

  const articles:Article[] = articlesResponse?.data || []
  const [
    articlePage,
    setArticlePage,
  ] = useState(1)
  const paginatedArticles = articles.slice(0, articlePage * 5)

  return (
    <DocumentWrapper title='Common Sense Privacy | Dashboard'>
      <ScreenWrapper bgcolor='grey.50'>
        <Container>
          <Stack
            direction={{
              xs: 'column',
              sm: 'row',
            }}
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
            mb={4}
          >
            <Typography variant='h1'>Welcome, {organizationName}!</Typography>
          </Stack>
        </Container>
        <Container>
          <Grid container={true} spacing={2} justifyContent='center'>
            <Grid item={true} xs={12} sm={8} md={4}>
              <Card variant='outlined'>
                <CardContent>
                  <Stack spacing={2} alignItems='center'>
                    <Typography variant='h2' textAlign='center'>Raise Your Score</Typography>
                    <Divider sx={{ width: '100%' }} />
                    <ChartsScore value={score} />
                    <Divider sx={{ width: '100%' }} />
                    {scoreToImprove > 0 ? (
                      <Typography variant='body2' textAlign='center'>You can improve your privacy score by <strong>{scoreToImprove}%</strong>.</Typography>
                    ) : (
                      <Typography variant='body2' textAlign='center'>You have <strong>perfect</strong> privacy score.</Typography>
                    )}
                    <Button to='/dashboard/raise-your-score' component={RouterLink} variant='outlined'>Take Action</Button>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid item={true} xs={12} sm={8} md={4}>
              <Card variant='outlined'>
                <CardContent>
                  <Stack spacing={2} alignItems='center'>
                    <Typography variant='h2' textAlign='center'>Top Privacy Issues</Typography>
                    <Divider sx={{ width: '100%' }} />
                    <ChartsPrivacy data={topPrivacyIssues} />
                    <Divider sx={{ width: '100%' }} />
                    { numOfPrivacyIssuesToImprove > 0 ? (
                      <Typography variant='body2' textAlign='center'>
                        You can improve ratings on <strong>{numOfPrivacyIssuesToImprove}</strong> top privacy issues.
                      </Typography>
                    ) : (
                      <Typography variant='body2' textAlign='center'>
                        You have a perfect score on the top privacy issues.
                      </Typography>
                    )}
                    <Button to='/dashboard/top-privacy-issues' component={RouterLink} variant='outlined'>Improve Ratings</Button>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid item={true} xs={12} sm={8} md={4}>
              <Card variant='outlined'>
                <CardContent>
                  <Stack spacing={2} alignItems='center'>
                    <Typography variant='h2' textAlign='center'>Legally Speaking</Typography>
                    <Divider sx={{ width: '100%' }} />
                    <ChartsLegal stats={stats} />
                    <Divider sx={{ width: '100%' }} />
                    <Typography variant='body2' textAlign='center'>You can improve ratings on <strong>{numOfLawsToImprove}</strong> applicable laws.</Typography>
                    <Button to='/dashboard/legally-speaking' component={RouterLink} variant='outlined'>Review Laws</Button>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item={true}
              xs={12}
              sm={8}
              md={8}
              order={{
                xs: 5,
                md: 4,
              }}
            >
              <Card variant='outlined'>
                <CardContent>
                  <Typography variant='h2' textAlign='center' mb={2}>The Latest</Typography>
                  <Divider />
                  <Stack spacing={2} mt={2}>
                    {paginatedArticles.map(({
                      id,
                      title,
                      publishedAt,
                      url,
                    }) => (
                      <Stack key={id}>
                        <Typography variant='body3' color='text.secondary'>{dateTime.format(publishedAt, 'MM.dd.yyyy')}</Typography>
                        <Typography fontWeight='bold' mb={2}>
                          <Link href={url} underline='none' color='inherit' target='new'>{title}</Link>
                        </Typography>
                        <Divider />
                      </Stack>
                    ))}
                    {articles.length > paginatedArticles.length && <Button variant='link' onClick={() => setArticlePage(articlePage + 1)}>Show More <KeyboardArrowDownOutlinedIcon /></Button>}
                    {articlePage > 1 && <Button variant='link' onClick={() => setArticlePage(articlePage - 1)}>Show Less <KeyboardArrowUpOutlinedIcon /></Button>}
                    <Stack mt={2} alignItems='center'>
                      <Button to='/dashboard/resources' component={RouterLink} variant='outlined'>Resources Archive</Button>
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item={true}
              xs={12}
              sm={8}
              md={4}
              order={{
                xs: 4,
                md: 5,
              }}
            >
              <Card variant='outlined'>
                <CardContent>
                  <Stack spacing={2} alignItems='center'>
                    <Typography variant='h2' textAlign='center'>Benchmarking</Typography>
                    <Divider sx={{ width: '100%' }} />
                    <ChartsBenchMark score={benchmarkScore} />
                    <Divider sx={{ width: '100%' }} />
                    <Typography variant='body2' textAlign='center'>You can improve your privacy score <strong>{benchmarkScoreToImprove}%</strong> in your product category.</Typography>
                    <Button to='/dashboard/benchmarking' component={RouterLink} variant='outlined'>Compare Performance</Button>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
        <Container>
          <Stack spacing={2} mt={6}>
            <Divider />
            <QuestionsContactSupport />
          </Stack>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(Dashboard)
