import {
  Box,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import React from 'react'

import Timeline from '../Timeline'

function SideBar(): React.ReactElement {
  const [
    clicked,
    setClicked,
  ] = React.useState(false)

  const handleSidebarToggle = () => {
    setClicked(!clicked)
  }

  return (
    <Box sx={{
      position: 'relative',
      height: '100%',
      marginRight: {
        xs: '20px',
        md: '40px',
      },
      paddingLeft: {
        xs: '40px',
        md: '0',
      },
    }}
    >
      <Box sx={{
        backgroundColor: '#FAFAFA',
        height: '100%',
        position: {
          xs: 'absolute',
          md: 'relative',
        },
        width: {
          xs: clicked ? '360px' : '40px',
          md: '360px',
        },
        zIndex: 2,
        left: 0,
        top: 0,
      }}
      >
        <IconButton
          color='inherit'
          aria-label='open Sidebar'
          edge='start'
          onClick={handleSidebarToggle}
          disableRipple={true}
          sx={{
            mr: 2,
            display: { md: 'none' },
            position: 'absolute',
            right: '-15px',
            top: '10px',
          }}
        >
          {clicked ? <CloseIcon color='primary' fontSize='medium' /> : <ArrowForwardIosIcon fontSize='medium' color='secondary' />}
        </IconButton>
        <Box sx={{
          padding: 4,
          display: {
            xs: clicked ? 'block' : 'none',
            md: 'block',
          },
        }}
        >
          <Timeline />
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(SideBar)
