import {
  useCallback,
  useEffect,
  useState,
} from 'react'

import log from '@common-sense-privacy/common/dist/src/log/browser'

import {
  LoadData,
  Props,
} from './types'

function useLoadData<T = unknown>(props: Props): LoadData {
  const shouldLoad = !!props

  const {
    simulateError = false,
    simulateLoadTime = 500,
  } = props || {}

  const [
    isError,
    setIsError,
  ] = useState(false)

  const [
    isLoading,
    setIsLoading,
  ] = useState(shouldLoad)

  const [
    isRefreshing,
    setIsRefreshing,
  ] = useState(false)

  const load = useCallback(({
    shouldSetIsLoading = true,
    shouldSetIsRefreshing = false,
  } = {}) => {
    if (!shouldLoad) {
      return
    }

    const callbacks = {
      onBefore: () => {
        setIsError(false)
        shouldSetIsLoading && setIsLoading(true)
        shouldSetIsRefreshing && setIsRefreshing(true)
      },
      onSuccess: () => {
        setIsError(false)
      },
      onFailure: () => {
        setIsError(true)
      },
      onAfter: () => {
        setIsLoading(false)
        setIsRefreshing(false)
      },
      onRefresh: () => undefined,
    }

    simulate<T>({
      callbacks,
      simulateError,
      simulateLoadTime,
    })
  }, [
    shouldLoad,
    simulateError,
    simulateLoadTime,
  ])

  useEffect(() => {
    load()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onRefresh = useCallback(() => {
    load({
      shouldSetIsLoading: false,
      shouldSetIsRefreshing: true,
    })
  }, [ load ])

  return {
    isError,
    isLoading,
    isRefreshing,
    onRefresh,
  }
}

const simulate = <T = unknown>({
  callbacks,
  simulateError,
  simulateLoadTime,
}: {
  callbacks: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  simulateError: boolean,
  simulateLoadTime: number,
}) => {
  log('info', '🥸  Simulating api call... 🥸')

  callbacks.onBefore()

  setTimeout(() => {
    if (simulateError) {
      callbacks.onFailure({} as T)
    }
    else {
      callbacks.onSuccess({} as T)
    }

    callbacks.onAfter({} as T)
  }, simulateLoadTime)
}

export default useLoadData

export * from './types'
