import {
  Box,
  Button,
  Container,
  Divider,
  Typography,
} from '@mui/material'
import React from 'react'

import useNavigate from '../../hooks/useNavigate'

import DocumentWrapper from '../../components/DocumentWrapper'
import ScreenWrapper from '../../components/ScreenWrapper'

import paymentRequired from './assets/402.svg'

function Error402(): React.ReactElement {
  const navigate = useNavigate()

  return (
    <DocumentWrapper title='Common Sense Privacy | Payment Required'>
      <ScreenWrapper>
        <Container>
          <Box component='center'>

            <Box
              component='img'
              src={paymentRequired}
              width={218}
              height={156}
              alt='Not Found'
              mb={6}
            />

            <Typography variant='h1' mb={4}>Oops! 402 - payment required.</Typography>
            <Typography variant='h5' mb={6} maxWidth='sm'>Sorry, the request could not be processed due to account payment issues.</Typography>
            <Divider />
            <Box mt={6}>
              <Button onClick={() => navigate(-1)}>Go Back</Button>
            </Box>
          </Box>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(Error402)
