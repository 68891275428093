import React from 'react'

import { Outlet } from 'react-router-dom'

import { useCurrentOrganization } from '@/hooks/useSession'

import useRouteParams from '@/hooks/useRouteParams'

import { useGetProductTopicsQuery } from '@/services/organization/product/topics'
import { usePostProductSnapshotMutation } from '@/services/organization/product/snapshot'

import CircularLoading from '@/components/CircularLoading'

import DocumentWrapper from '../../../components/DocumentWrapper'
import ScreenWrapper from '../../../components/ScreenWrapper'

import WizardQuestionsLayout from '../components/WizardQuestionsLayout'

function WizardProducts(): React.ReactElement {
  const { id: organizationId } = useCurrentOrganization()
  const { productId } = useRouteParams()
  const [
    ,
    { isLoading: isFinalizing },
  ] = usePostProductSnapshotMutation()

  const {
    data: topics,
    isLoading,
  } = useGetProductTopicsQuery(
    {
      params: {
        id: organizationId,
        productId: `${productId}`,
      },
    },
    { refetchOnMountOrArgChange: true },
  )

  const hasTopic = !!topics?.data

  if (isLoading && hasTopic) {
    return (
      <DocumentWrapper title='Common Sense Privacy | Privacy Wizard'>
        <ScreenWrapper verticalPadding={false}>
          <CircularLoading />
        </ScreenWrapper>
      </DocumentWrapper>
    )
  }

  return (
    <DocumentWrapper title='Common Sense Privacy | Privacy Wizard'>
      <ScreenWrapper verticalPadding={false}>
        {isFinalizing ? <Outlet /> : (
          <WizardQuestionsLayout hasQuestionsFooter={false}>
            <Outlet />
          </WizardQuestionsLayout>
        )}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(WizardProducts)
