import React from 'react'

import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Stack,
  IconButton,
  Typography,
} from '@mui/material'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { Props } from './types'

function ActionCard({
  tags = [],
  description,
  title,
  score,
  status,
  onPracticesClick,
}: Props): React.ReactElement {
  const [
    selected,
    setSelected,
  ] = React.useState(false)
  const handleChange = () => {
    setSelected(prev => !prev)
  }

  return (
    <Card
      variant='outlined'
      sx={{ borderWidth: '2px' }}
    >
      {status === 'actionable'
        && (
          <Box bgcolor='#FFF4E5' px={2} py={1}>
            <IconButton
              onClick={handleChange}
              disableRipple={true}
              sx={{
                border: 'none',
                padding: 0,
                width: '100%',
                position: 'relative',
                justifyContent: 'flex-start',
              }}
            >
              <Stack direction='row'>
                <WarningAmberIcon sx={{
                  color: 'warning.main',
                  marginRight: '3px',
                }}
                /><Typography fontWeight='bold' color='#000'>There is still more you can do</Typography>
              </Stack>
              <Box sx={{
                position: 'absolute',
                right: '5px',
              }}
              >
                {selected ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
              </Box>
            </IconButton>
            {selected
            && (
              <Box sx={{ display: 'flex' }} mt={1}>
                <Typography>Your practices will be reflected in your Privacy Policy download.<br />
                  Consider doing more in order to improve your privacy score.
                </Typography>
              </Box>
            )}

          </Box>
        )}
      {status === 'completed'
        && (
          <Box bgcolor='#EDF7ED' px={2} py={1}>
            <Stack direction='row'>
              <CheckCircleOutlineIcon sx={{
                color: 'success.main',
                marginRight: '3px',
              }}
              /><Typography fontWeight='bold'>No further actions are required at this point</Typography>
            </Stack>
          </Box>
        )}
      <CardContent>

        <Grid container={true} item={true} spacing={4} mb={2}>
          <Grid
            item={true}
            xs={12}
            sm={9}
            md={9}
          >
            <Stack alignItems='flex-start' spacing={1.5}>
              {title && <Typography fontWeight='bold'>{title}</Typography>}
              <Typography>{description}</Typography>
              <Box>
                {tags.map(tag => (
                  <Chip
                    sx={{
                      marginBottom: '6px',
                      marginRight: '6px',
                    }}
                    key={tag}
                    label={tag}
                    variant='results'
                  />
                ))}
              </Box>
            </Stack>
          </Grid>
          <Grid
            item={true}
            xs={12}
            sm={3}
            md={3}
          >
            <Stack
              spacing={1}
              alignItems='center'
              border={1}
              borderColor='secondary.main'
              borderRadius={2}
              p={1}
              maxWidth={80}
              ml={{
                xs: 0,
                sm: 'auto',
              }}
            >
              <Typography variant='subtitle1'>Total Impact</Typography>
              <Stack direction='row' position='relative'>
                <ArrowUpwardIcon
                  fontSize='small'
                  sx={{
                    color: 'secondary.main',
                    position: 'absolute',
                    left: '-6px',
                    top: '5px',
                  }}
                />
                <Typography fontSize={20} pl={2} color='secondary.main'>{score}</Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Divider />
        <Stack mt={2} direction='row' spacing={2}>
          <Button variant='link' onClick={onPracticesClick} disableRipple={true}>{status === 'unActionable' ? 'Disclose Practices' : 'Update Practices'}</Button>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default React.memo(ActionCard)
