import React, { useEffect } from 'react'
import { Container } from '@mui/material'
import { useDispatch } from 'react-redux'

import { lang } from '@common-sense-privacy/common'

import ScreenWrapper from '@/components/ScreenWrapper'
import DocumentWrapper from '@/components/DocumentWrapper'

import { useAlert } from '@/context/AlertContext'
import { usePostConfirmEmailMutation } from '@/services/verify'
import { setSession } from '@/hooks/useSession'
import useRouteQuery from '@/hooks/useRouteQuery'
import useNavigate from '@/hooks/useNavigate'

export default function Verify() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const query = useRouteQuery()
  const token = query.get('token')

  const { setAlert } = useAlert()
  const [ postConfirmEmail ] = usePostConfirmEmailMutation()

  useEffect(() => {
    if (!token) {
      return
    }

    postConfirmEmail({ token })
      .unwrap()
      .then(response => {
        dispatch(setSession(response.data))
        navigate('/subscriptions/plans')
      }).catch(e => {
        setAlert({
          description: e?.data?.message || lang().messages.somethingWentWrong(),
          type: 'error',
        })
      })
  }, [
    setAlert,
    token,
    postConfirmEmail,
    navigate,
    dispatch,
  ])

  return (
    <DocumentWrapper title='Common Sense Privacy | Verify Account'>
      <ScreenWrapper>
        <Container maxWidth='sm' />
      </ScreenWrapper>
    </DocumentWrapper>
  )
}
