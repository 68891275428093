import {
  Box, Stack,
} from '@mui/material'
import React from 'react'
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'

import ChartLabel from '../components/Label'

import {
  Cell,
  TopPrivacyIssueStat,
} from './types'

function ChartsPrivacy({ data }: { data: TopPrivacyIssueStat[]}) {
  function buildData() {
    const questionsWithGoodPractice:Cell[] = []
    const questionsWithBadPractice:Cell[] = []
    const questionsWithUnclearPractice:Cell[] = []

    function getTarget(score: number) {
      if (score >= 1) {
        return questionsWithGoodPractice
      }
      if (score >= 0.5) {
        return questionsWithBadPractice
      }

      return questionsWithUnclearPractice
    }

    data.forEach(({ score }, y) => {
      const target = getTarget(score)
      target.push({
        x: score,
        y,
      })
    })

    return {
      questionsWithGoodPractice,
      questionsWithBadPractice,
      questionsWithUnclearPractice,
    }
  }

  const {
    questionsWithGoodPractice,
    questionsWithBadPractice,
    questionsWithUnclearPractice,
  } = buildData()

  return (
    <Box width='100%'>
      <ResponsiveContainer width='100%' height={300}>
        <ScatterChart
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid />
          <XAxis
            dataKey='x'
            type='number'
            tick={{ fill: '#999999' }}
            style={{
              fontSize: '0.75rem',
              fontFamily: 'Lato',
            }}
            domain={[
              0,
              1,
            ]}
          />
          <YAxis
            yAxisId='left'
            tickFormatter={value => data[value]?.title || ''}
            dataKey='y'
            tickCount={7}
            tick={{ fill: '#999999' }}
            style={{
              fontSize: '0.75rem',
              fontFamily: 'Lato',
            }}
          />
          <Tooltip cursor={{ strokeDasharray: '3 3' }} />
          <Scatter yAxisId='left' name='Clear & Good' data={questionsWithGoodPractice} fill='#33A544' />
          <Scatter yAxisId='left' name='Clear & Bad' data={questionsWithBadPractice} fill='#23B5E8' />
          <Scatter yAxisId='left' name='Unclear' data={questionsWithUnclearPractice} fill='#FAA41A' />
        </ScatterChart>
      </ResponsiveContainer>
      <Stack direction='row' justifyContent='center' spacing={1} minHeight={20}>
        <ChartLabel labelText='Clear & Good' bgcolor='#33A544' />
        <ChartLabel labelText='Clear & Bad' bgcolor='#23B5E8' />
        <ChartLabel labelText='Unclear' bgcolor='#FAA41A' />
      </Stack>
    </Box>
  )
}

export default React.memo(ChartsPrivacy)
