import React from 'react'
import { Box } from '@mui/material'

import { Props } from './types'

function TabPanel({
  value,
  index,
  children,
}: Props): React.ReactElement {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box pt={3}>
          {children}
        </Box>
      )}
    </div>
  )
}

export default React.memo(TabPanel)
