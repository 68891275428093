import commonSenseApi from '@/state/apis/commonSense'

import {
  PostRequest,
  PostResponseBody,
} from './types'

const supportContactService = commonSenseApi.injectEndpoints({
  endpoints: build => ({
    postContactSupport: build.mutation<PostResponseBody, PostRequest>({
      query: body => ({
        url: 'v1/support-contact',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export default supportContactService

export const { usePostContactSupportMutation } = supportContactService

export const { endpoints: { postContactSupport } } = supportContactService
