import { api } from '@common-sense-privacy/common'

export type Plan = api.endpoints.stripe.plans.types.ProductWithPrices

export type Subscription = api.endpoints.stripe.subscriptions.types.SubscriptionWithExpansions

export type TransformPlansProps = {
  plans: Plan[],
  subscription?: Subscription,
}

export type TransformPlanProps = {
  plan: Plan,
  subscription?: Subscription,
}

const { PlanType } = api.endpoints.stripe.plans.types

export { PlanType }
