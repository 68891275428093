import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'

import { RootState } from '../../types'

import { WizardState } from './types'

export const initialState: WizardState = {}

export const wizardSlice = createSlice({
  name: 'wizard',
  initialState,
  reducers: {
    setSkippedQuestion: (state, action: PayloadAction<boolean>) => {
      state.skippedQuestion = action.payload

      return state
    },
  },
})

export const { setSkippedQuestion } = wizardSlice.actions

export const wizardSelectors = { wizard: (state: RootState) => state.wizard }

export default wizardSlice.reducer

export * from './types'
