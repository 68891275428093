import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'

import { FormikProps } from 'formik'

import {
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Link,
  MenuItem,
  Stack,
  Grid,
  CircularProgress,
  Box,
  Typography,
} from '@mui/material'

import { api } from '@common-sense-privacy/common'

import Form from '@/components/Form'
import FormField from '@/components/FormField'
import { useAlert } from '@/context/AlertContext'
import { usePatchOrganizationMutation } from '@/services/organization'
import {
  useGetDetailedGoogleAddressQuery,
  useGetSuggestedGoogleAddressesQuery,
} from '@/services/googlePlaces'

import useHandleFormApiErrors from '@/hooks/useHandleFormApiErrors'
import useNavigate from '@/hooks/useNavigate'
import { useCurrentOrganization } from '@/hooks/useSession'
import { setUserOrganization } from '@/state/slices/session'

import AutocompleteField from '@/components/FormField/Autocomplete'
import { Address } from '@/screens/SignUp/types'

import useDebouncedValue from '@/hooks/useDebouncedValue'

import DocumentWrapper from '../../../components/DocumentWrapper'
import ScreenWrapper from '../../../components/ScreenWrapper'

import type {
  FormValues,
  OnSubmit,
} from './types'

function EditOrganization(): React.ReactElement {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleFormApiErrors = useHandleFormApiErrors()
  const formikRef = useRef<FormikProps<FormValues> | undefined>(undefined)
  const { setAlert } = useAlert()
  const [
    address,
    setAddress,
  ] = useState('')
  const [
    placeId,
    setPlaceId,
  ] = useState('')
  const [
    countryISOCode,
    setCountryISOCode,
  ] = useState('')

  const debouncedStreetAddress = useDebouncedValue(address, 100)

  const countryList: { name: string, code: string }[] = api.endpoints.organization.validation.COUNTRY_LIST

  const {
    data: suggestedGoogleAddresses,
    isLoading: isAddressesLoading,
  } = useGetSuggestedGoogleAddressesQuery({
    search: debouncedStreetAddress,
    countryISOCode,
  }, { refetchOnMountOrArgChange: true })

  const { data: detailedGoogleAddress } = useGetDetailedGoogleAddressQuery({ search: placeId }, { refetchOnMountOrArgChange: true })

  useEffect(() => {
    if (!detailedGoogleAddress || !detailedGoogleAddress.data || !formikRef.current) return

    const {
      city,
      state,
      postalCode,
    } = detailedGoogleAddress.data

    formikRef.current.setFieldValue('city', city)
    formikRef.current.setFieldValue('state', state)
    formikRef.current.setFieldValue('zipCode', postalCode)
  }, [ detailedGoogleAddress ])

  const {
    id: organizationId,
    name,
    size,
    email,
    city,
    state,
    zipCode,
    poBox,
    streetAddress,
    country,
  } = useCurrentOrganization()

  useEffect(() => {
    const countryItem = countryList.find(item => item.name === country)
    setCountryISOCode(countryItem?.code ?? 'us')
  }, [
    country,
    countryList,
  ])

  const [
    patchOrganization,
    { isLoading },
  ] = usePatchOrganizationMutation()

  const initialValues = useMemo(() => ({
    name,
    size,
    email,
    city,
    state,
    zipCode,
    poBox,
    streetAddress,
    organizationCountry: country,
  }), [
    city,
    country,
    email,
    name,
    poBox,
    size,
    state,
    streetAddress,
    zipCode,
  ])

  const handleSubmit = useCallback<OnSubmit>(async (values: FormValues, { setErrors }) => {
    patchOrganization({
      body: values,
      params: { id: organizationId },
    })
      .unwrap()
      .then(response => {
        navigate('/account')
        if (response.data) {
          dispatch(setUserOrganization(response.data))
        }
        setAlert({
          description: 'Success! Your changes have been saved.',
          type: 'success',
        })
      }).catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
          showFieldErrorsAsToast: true,
        })
      })
  }, [
    navigate,
    organizationId,
    dispatch,
    handleFormApiErrors,
    setAlert,
    patchOrganization,
  ])
  const { rules } = api.endpoints.organization.validation.patch.body
  const organizationSizes = api.endpoints.signup.validation.ORGANIZATION_SIZES

  return (
    <DocumentWrapper title='Common Sense Privacy | My Account Organization Details'>
      <ScreenWrapper>
        <Container>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/account' variant='body2'>
              Account
            </Link>
            <Typography color='text.primary' variant='body2'>Edit Organization Details</Typography>
          </Breadcrumbs>
          <Typography variant='h1'>Organization details</Typography>
          <Typography variant='intro' mb={4}>Edit your organization information</Typography>
          <Divider />
        </Container>
        <Container>
          <Form<FormValues> initialValues={initialValues} onSubmit={handleSubmit} rules={rules}>
            { formik => (
              <Stack maxWidth='sm' spacing={4} mt={6}>
                <FormField
                  name='name'
                  id='organization'
                  label='Organization'
                  variant='outlined'
                  inputProps={{ 'data-testid': 'organization-name' }}
                />
                <FormField
                  type='select'
                  name='size'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Organization Size'
                  inputProps={{ 'data-testid': 'organization-size' }}
                >
                  {organizationSizes.map((size: string) => (
                    <MenuItem key={size} value={size}>{size}</MenuItem>
                  ))}
                </FormField>
                <FormField
                  name='email'
                  label='Organization Email'
                  variant='outlined'
                  inputProps={{ 'data-testid': 'signup-organization-email' }}
                  helperText='Organization email will appear in the privacy policy.'
                />
                <FormField
                  type='select'
                  name='organizationCountry'
                  labelId='orgnization-country-select-label'
                  id='organization-country-select'
                  label='Country/State of Organziation'
                  data-testid='signup-organization-country'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const country = countryList.find(countryItem => countryItem.name === e.target.value)
                    setCountryISOCode(country?.code ?? '')
                  }}
                >
                  {countryList.map((item: { name: string, code: string }) => (
                    <MenuItem key={item.name} value={item.name}>{item.name}</MenuItem>
                  ))}
                </FormField>
                <AutocompleteField<Address>
                  name='streetAddress'
                  label='Street Address *'
                  onInputChange={(event, newInputValue) => setAddress(newInputValue)}
                  onChange={(event, address: string | Address | null) => {
                    const placeId = typeof address === 'string' ? '' : address?.placeId || ''
                    setPlaceId(placeId)
                    formikRef.current = formik
                  }}
                  getOptionLabel={option => typeof option === 'string' ? option : option?.description || ''}
                  options={suggestedGoogleAddresses?.data || []}
                  formControlProps={{ fullWidth: true }}
                  loading={isAddressesLoading}
                  loadingText={(
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        justifyContent: 'center',
                      }}
                      py={3}
                    >
                      <CircularProgress size='1.5rem' />
                    </Box>
                  )}
                  data-testid='street-address-wrapper'
                />
                <FormField
                  name='city'
                  label='City'
                  variant='outlined'
                  type='text'
                  inputProps={{ 'data-testid': 'signup-city' }}
                />
                <Grid container={true} spacing={1}>
                  <Grid item={true} xs={6}>
                    <FormField
                      name='state'
                      label='State'
                      variant='outlined'
                      type='text'
                      inputProps={{ 'data-testid': 'signup-state' }}
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <FormField
                      name='zipCode'
                      label='Zip Code'
                      variant='outlined'
                      type='text'
                      inputProps={{ 'data-testid': 'signup-zip-code' }}
                    />
                  </Grid>
                </Grid>
                <FormField
                  name='poBox'
                  label='PO Box'
                  variant='outlined'
                  type='text'
                  inputProps={{ 'data-testid': 'signup-po-box' }}
                />
                <Stack direction='row' spacing={2}>
                  <Button variant='contained' type='submit' disabled={isLoading}>Save</Button>
                  <Button variant='outlined' onClick={() => navigate(-1)}>Cancel</Button>
                </Stack>
              </Stack>
            )}
          </Form>
          <Stack mt={8}>
            <Divider />
          </Stack>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(EditOrganization)
