import { useSelector } from 'react-redux'

import { wizardSelectors } from '../../state/slices/wizard'

function useWizard() {
  const wizard = useSelector(wizardSelectors.wizard)

  return wizard || {}
}

export function useHasSkippedQuestion(): boolean {
  const wizard = useWizard()

  return !!wizard.skippedQuestion
}

export * from '../../state/slices/wizard'
