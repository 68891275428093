import commonSenseApi from '@/state/apis/commonSense'

import { objectToQueryString } from '@/utils/queryString'

import {
  GetRequest,
  GetResponseBody,
  GetListRequest,
  GetListResponseBody,
  PutRequest,
  PutResponseBody,
} from './types'

const recommendedActionService = commonSenseApi.injectEndpoints({
  endpoints: build => ({
    getProductRecommendedAction: build.query<GetResponseBody, GetRequest>({
      query: ({
        params: {
          id,
          productId,
          recommendedActionId,
        },
      }) => ({
        url: `v1/organizations/${id}/products/${productId}/recommended-actions/${recommendedActionId}`,
        method: 'GET',
      }),
      providesTags: [ 'RecommendedAction' ],
    }),
    getProductRecommendedActionList: build.query<GetListResponseBody, GetListRequest>({
      query: ({
        params: {
          id,
          productId,
        },
        query,
      }) => ({
        url: `v1/organizations/${id}/products/${productId}/recommended-actions?${objectToQueryString(query)}`,
        method: 'GET',
      }),
      providesTags: [ 'RecommendedAction' ],
    }),
    putProductRecommendedAction: build.mutation<PutResponseBody, PutRequest>({
      query: ({
        params: {
          id,
          productId,
          recommendedActionId,
        },
        body,
      }) => ({
        url: `v1/organizations/${id}/products/${productId}/recommended-actions/${recommendedActionId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [
        'RecommendedAction',
        'PrivacyIssueScore',
        'ProductPrivacyIssue',
      ],
    }),
  }),
})

export default recommendedActionService

export const {
  useGetProductRecommendedActionQuery,
  useGetProductRecommendedActionListQuery,
  usePutProductRecommendedActionMutation,
} = recommendedActionService

export const {
  endpoints: {
    getProductRecommendedAction,
    getProductRecommendedActionList,
    putProductRecommendedAction,
  },
} = recommendedActionService
