import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
} from 'recharts'

import { BenchmarkScoreStat } from './types'

function ChartsBenchMark({ score }: { score: BenchmarkScoreStat }) {
  const data = [
    {
      name: 'You',
      bm: score.myScore,
      fill: '#23B5E8',
    },
    {
      name: 'Others',
      bm: score.othersScore,
      fill: '#626262',
    },
  ]

  const labelFormatter = (value: number) => `${value}%`

  return (
    <ResponsiveContainer height={300} width='100%'>
      <BarChart
        data={data}
        margin={{
          top: 15,
          right: 20,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis
          dataKey='name'
          style={{
            fontSize: '0.8rem',
            fontFamily: 'Lato',
          }}
          tick={{ fill: '#999' }}
          tickLine={{ stroke: '#999' }}
        />
        <YAxis
          style={{
            fontSize: '0.8rem',
            fontFamily: 'Lato',
          }}
          tick={{ fill: '#999' }}
          tickLine={{ stroke: '#999' }}
          type='number'
          tickCount={6}
          domain={[
            0,
            100,
          ]}
        />
        <Bar dataKey='bm' barSize={60} isAnimationActive={false}>
          <LabelList
            fill='#999'
            formatter={labelFormatter}
            position='top'
            style={{
              fontSize: '0.8rem',
              fontFamily: 'Lato',
            }}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

export default React.memo(ChartsBenchMark)
