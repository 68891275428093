import React from 'react'
import {
  FormControl as MuiFormControl,
  FormHelperText,
  TextField,
  Autocomplete,
  AutocompleteProps,
} from '@mui/material'
import {
  ErrorMessage,
  Field,
  FieldProps,
} from 'formik'

function AutocompleteField<T>({
  name,
  label,
  formControlProps,
  inputProps,
  onInputChange,
  onChange,
  helperText,
  ...props
}: Omit<AutocompleteProps<T, false, false, true>, 'renderInput'> & {
  name: string,
  label: string,
  formControlProps?: { fullWidth?: boolean },
  inputProps?: { 'data-testid'?: string },
  helperText?: string,
}) {
  return (
    <Field name={name}>
      {({
        field,
        meta,
        form: {
          setTouched,
          setFieldValue,

        },
      }: FieldProps) => (
        <MuiFormControl {...formControlProps}>
          <Autocomplete
            {...props}
            {...field}
            onInputChange={(e, value, reason) => {
              setFieldValue(name, value)

              if (onInputChange) {
                onInputChange(e, value, reason)
              }
            }}
            freeSolo={true}
            multiple={false}
            onChange={(e, value, reason) => {
              if (onChange) {
                onChange(e, value, reason)
              }
            }}
            onBlur={() => setTouched({ [name]: true })}
            renderInput={params => (
              <TextField
                {...params}
                {...inputProps}
                label={label}
                error={!!meta.error}
              />
            )}
          />
          {helperText && (<FormHelperText sx={{ color: '#000' }}>{helperText}</FormHelperText>)}
          {meta.touched && meta.error && (
            <ErrorMessage
              name={name}
              render={err => <FormHelperText error={true}>{typeof err === 'string' ? err : err[name]}</FormHelperText>}
            />
          )}

        </MuiFormControl>
      ) }
    </Field>
  )
}

export default AutocompleteField
