import React, { useCallback } from 'react'
import {
  Box,
  Button,
  Container,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import {
  Link as RouterLink,
  useNavigate,
} from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { api } from '@common-sense-privacy/common'

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

import Form from '@/components/Form'
import FormField from '@/components/FormField'

import {
  setPolicyUrl,
  usePolicyUrl,
} from '@/hooks/useProductInformation'

import DocumentWrapper from '../../../../../components/DocumentWrapper'
import ScreenWrapper from '../../../../../components/ScreenWrapper'

import type {
  FormValues,
  OnSubmit,
} from './types'

function PrivacyPolicyUrl(): React.ReactElement {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const policyUrl = usePolicyUrl()

  const initialValues = { policyUrl }
  const rules = { policyUrl: api.endpoints.organization.product.validation.post.body.rules.policyUrl.required() }
  const handleSubmit = useCallback<OnSubmit>(async (values: FormValues) => {
    dispatch(setPolicyUrl(values.policyUrl))
    navigate('/wizard/product/information')
  }, [
    dispatch,
    navigate,
  ])

  return (
    <DocumentWrapper title='Common Sense Privacy | Privacy Policy URL'>
      <ScreenWrapper>
        <Container>
          <Box>
            <Link
              component={RouterLink}
              variant='body2'
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: 'text.primary',
                textDecoration: 'none',
                fontWeight: 'bold',
              }}
              to='/wizard/product/privacy-policy'
            >
              <KeyboardArrowLeftIcon /> Back
            </Link>
          </Box>
        </Container>
        <Container maxWidth='sm'>
          <Typography variant='h5' textAlign='center' mb={4}>Please enter the URL of your privacy policy.</Typography>
          <Form<FormValues> initialValues={initialValues} onSubmit={handleSubmit} rules={rules}>
            <Stack marginBottom={6} spacing={4} alignItems='center'>
              <FormField
                name='policyUrl'
                label='URL'
                variant='outlined'
                inputProps={{ 'data-testid': 'policy-url' }}
                formControlProps={{ fullWidth: true }}
              />
              <Button type='submit'>Continue</Button>
            </Stack>
          </Form>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(PrivacyPolicyUrl)
