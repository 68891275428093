import React, { useState } from 'react'

import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material'

import PrivacyIssueCard from '@/components/PrivacyIssueCard'

import { useGetProductPrivacyIssueListQuery } from '@/services/organization/product'
import { ProductPrivacyIssue } from '@/services/organization/product/types'

import {
  useCurrentOrganization,
  useCurrentProduct,
} from '@/hooks/useSession'

import { Props } from './types'

export default function RaiseYourScoreSection({
  title,
  description,
  loadMoreText,
  itemsPerPage,
  category,
  diagram,
}: Props): React.ReactElement {
  const { id: organizationId } = useCurrentOrganization()
  const product = useCurrentProduct()
  const { id: productId } = product || { id: '' }

  const [
    offset,
    setOffset,
  ] = useState(0)

  const handleShowMore = () => {
    setOffset(offset + itemsPerPage)
  }

  const { data: paginatedResponse } = useGetProductPrivacyIssueListQuery({
    params: {
      id: organizationId,
      productId,
    },
    query: {
      category,
      offset: `${offset}`,
      limit: `${itemsPerPage}`,
    },
  }, { refetchOnMountOrArgChange: true })

  const privacyIssues: ProductPrivacyIssue[] = paginatedResponse?.data?.items || []
  const total = paginatedResponse?.data?.total || 0

  return (
    <>
      <Grid
        container={true}
        spacing={2}
        mb={6}
      >
        <Grid
          container={true}
          xs={12}
          sm={6}
          md={12}
          spacing={2}
          item={true}
        >
          <Grid item={true}>
            <Stack spacing={2} mb={4} maxWidth='md'>
              <Typography variant='h4'>{title}</Typography>
              <Typography variant='intro'>{description}</Typography>
            </Stack>
          </Grid>
        </Grid>
        <Grid
          container={true}
          item={true}
          xs={12}
          sm={6}
          md={4}
          order={{ md: 3 }}
        >
          <Grid
            item={true}
            flex={1}
          >
            {diagram}
          </Grid>
        </Grid>
        <Grid container={true} item={true} xs={12} md={8} spacing={2}>
          {privacyIssues.length > 0 ? privacyIssues.map(issue => (
            <Grid key={issue.id} item={true} xs={12} sm={6}>
              <PrivacyIssueCard data={issue} />
            </Grid>
          )) : (
            <Grid item={true} xs={12} sm={6} mx={2}>
              <Typography>There are no privacy issues to show.</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Box mb={6} textAlign='center' position='relative' minHeight={38}>
        <Box position='absolute' width='100%' top='50%'>
          <Divider />
        </Box>
        {total > offset + itemsPerPage && (
          <Button
            onClick={handleShowMore}
            variant='outlined'
          >{loadMoreText}
          </Button>
        )}
      </Box>
    </>
  )
}
