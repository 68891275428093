import commonSenseApi from '@/state/apis/commonSense'

import {
  GetSuggestedGoogleAddressesResponseBody,
  GetDetailedGoogleAddressResponseBody,
  GetSuggestedGoogleAddressesRequestQuery,
  GetDetailedGoogleAddressRequestQuery,
} from './type'

const googlePlacesService = commonSenseApi.injectEndpoints({
  endpoints: build => ({
    getSuggestedGoogleAddresses: build.query<GetSuggestedGoogleAddressesResponseBody, GetSuggestedGoogleAddressesRequestQuery>({
      query: ({
        search,
        countryISOCode,
      }) => ({
        url: `v1/googleplaces?search=${search}&countryISOCode=${countryISOCode}`,
        method: 'GET',
      }),
    }),
    getDetailedGoogleAddress: build.query<GetDetailedGoogleAddressResponseBody, GetDetailedGoogleAddressRequestQuery>({
      query: ({ search }) => ({
        url: `v1/googleplaces/detail?placeId=${search}`,
        method: 'GET',
      }),
    }),
  }),
})

export default googlePlacesService

export const {
  useGetSuggestedGoogleAddressesQuery,
  useGetDetailedGoogleAddressQuery,
} = googlePlacesService

export const {
  endpoints: {
    getSuggestedGoogleAddresses,
    getDetailedGoogleAddress,
  },
} = googlePlacesService
