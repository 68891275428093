import React, { useEffect } from 'react'
import { Container } from '@mui/material'

import { useNavigate } from 'react-router-dom'

import ScreenWrapper from '@/components/ScreenWrapper'
import DocumentWrapper from '@/components/DocumentWrapper'
import useSession from '@/hooks/useSession'

export default function Home() {
  const navigate = useNavigate()
  const session = useSession()

  useEffect(() => {
    if (session.user) {
      navigate('/wizard')
    }
    else {
      navigate('/signin')
    }
  }, [
    navigate,
    session,
  ])

  return (
    <DocumentWrapper>
      <ScreenWrapper>
        <Container />
      </ScreenWrapper>
    </DocumentWrapper>
  )
}
