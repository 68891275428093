import React, {
  useCallback,
  useState,
} from 'react'

import { MenuContextType } from './types'

export const MenuContext = React.createContext<MenuContextType>({
  isMenuOpen: undefined,
  closeMenu: () => undefined,
  openMenu: () => undefined,
  toggleMenu: () => undefined,
})

function MenuContextProvider({ children }: { children: React.ReactNode }): React.ReactElement {
  const [
    isMenuOpen,
    setIsMenuOpen,
  ] = useState(false)

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false)
  }, [])

  const openMenu = useCallback(() => {
    setIsMenuOpen(true)
  }, [])

  const toggleMenu = useCallback(() => {
    setIsMenuOpen(prev => !prev)
  }, [])

  return React.createElement(
    MenuContext.Provider,
    {
      value: {
        isMenuOpen,
        closeMenu,
        openMenu,
        toggleMenu,
      },
    },
    children,
  )
}

export default MenuContextProvider
