import React from 'react'

import { SvgIcon } from '@mui/material'

import { ReactComponent as DefaultLogo } from './assets/logo.svg'

function Logo() {
  return (
    <SvgIcon
      viewBox='0 0 144 70'
      sx={{
        height: '70px',
        width: '120px',
      }}
      component={DefaultLogo}
    />
  )
}

export default React.memo(Logo)
