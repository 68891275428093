import { api } from '@common-sense-privacy/common'

export type ProductRecommendedAction = api.endpoints.organization.product.recommendedActions.types.ProductRecommendedAction

export type ProductPrivacyIssue = api.endpoints.organization.product.privacyIssue.types.ProductPrivacyIssue

export enum Status {
  UNACTIONABLE = 'unActionable',
  ACTIONABLE = 'actionable',
  COMPLETED = 'completed',
}

export type TransformProps = {
  productRecommendedAction?: ProductRecommendedAction,
  productPrivacyIssue?: ProductPrivacyIssue,
  stats: { score: number, codes: string[] } | undefined,
}

export type TransformData = {
  title: string,
  description: string,
  score: string,
  status: Status,
  tags: string[],
}
