import React from 'react'
import {
  Box,
  Button,
  ButtonBase,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import Collapse from '@mui/material/Collapse'

import { useGetPlansQuery } from '@/services/stripe'
import { useOrganizationSubscription } from '@/hooks/useSession'
import { transformPlans } from '@/utils/stripe'

function ChoosePlan() {
  const [
    visible,
    setVisible,
  ] = React.useState(false)
  const { data: plansResponse } = useGetPlansQuery(undefined, { refetchOnMountOrArgChange: true })
  const handleChange = () => {
    setVisible(prev => !prev)
  }
  const subscription = useOrganizationSubscription()
  const plans = (plansResponse?.data || []).filter(plan => plan.prices.some(price => price.unit_amount !== 0))
  const transformedPlans = transformPlans({
    plans,
    subscription,
  })

  return (
    <Box
      borderTop={1}
      borderColor='#CCC'
      py={4}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        bgcolor: '#f8faf7',
        position: 'relative',
      }}
    >
      <IconButton
        sx={{
          position: 'absolute',
          right: 2,
          top: 20,
        }}
        onClick={handleChange}
      >
        {visible ? <ExpandLessIcon fontSize='large' /> : <ExpandMoreIcon fontSize='large' />}
      </IconButton>
      <Box>
        <ButtonBase onClick={handleChange} disableRipple={true}>
          <Typography variant='h5'>Choose a Plan</Typography>
        </ButtonBase>
      </Box>
      <Collapse in={visible}>
        <Stack direction='row' spacing={4} mt={4}>
          {transformedPlans.map(plan => (
            <Box
              key={plan.id}
              bgcolor='white'
              border={2}
              borderColor='primary.main'
              borderRadius={4}
              padding={2}
              width='100%'
              maxWidth={360}
            >
              <Typography minHeight={50} fontWeight='700'>{plan.tagline}</Typography>
              <Stack mt={2}>
                <Typography variant='h2'>{plan.name}</Typography>
                <Stack direction='row'><Typography variant='h6'>{plan.price}</Typography> <Typography>/{plan.billingInterval}</Typography></Stack>
                <Stack alignItems='center' mt={4}>
                  <Button
                    href='/subscriptions/plans'
                    target='_blank'
                    variant='outlined'
                  >See More
                  </Button>
                </Stack>
              </Stack>
            </Box>
          ))}
        </Stack>
      </Collapse>
    </Box>
  )
}

export default React.memo(ChoosePlan)
