import React, {
  useMemo,
  useRef,
  useState,
} from 'react'

import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Link,
  Stack,
  Typography,
} from '@mui/material'

import { Props } from './types'

import ActionIcon from './components/ActionIcon'

function ResultsCard({
  color,
  issueNum,
  issueDesc,
  categories = [],
  numRecommendedActions,
  importance,
  answer,
  score,
  isList = true,
  url,
  practicesButtonText,
  onPracticesClick,
}: Props): React.ReactElement {
  const chipsContainerRef = useRef<HTMLElement>()

  const [
    numChipsToShow,
    setNumChipsToShow,
  ] = useState(Math.min(2, categories.length))

  const chips = useMemo(() => {
    const numMoreChips = categories.length - numChipsToShow

    if (numMoreChips > 0) {
      return [
        ...categories.slice(0, numChipsToShow).map(label => ({ label })),
        {
          label: `+${numMoreChips} More`,
          onClick: (!isList && (() => setNumChipsToShow(categories.length))) || undefined,
        },
      ]
    }

    return categories.map(label => ({ label }))
  }, [
    categories,
    isList,
    numChipsToShow,
  ])

  const isEasyFix = useMemo(() => {
    if (score === 1) {
      return undefined
    }

    return score === 0
  }, [ score ])

  let borderColor
  let footerColor
  switch (color) {
    case 'green': {
      borderColor = '#33A544'
      footerColor = '#33a54433'
      break
    }
    case 'blue': {
      borderColor = '#23B5E8'
      footerColor = '#23b5e833'
      break
    }
    case 'orange': {
      borderColor = '#FAA41A'
      footerColor = '#faa41a33'
      break
    }
    default: {
      borderColor = '#CCC'
      footerColor = '#CCC'
      break
    }
  }

  return (
    <Link href={url} underline='none'>
      <Card
        variant='outlined'
        sx={{
          borderColor: { borderColor },
          borderWidth: '2px',
          ...(url && {
            '&:hover': {
              boxShadow: 3,
              cursor: 'pointer',
            },
          }),
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          boxSizing: 'border-box',
        }}
      >
        <CardContent sx={{ flex: 1 }}>
          <Stack spacing={2} alignItems='flex-start'>
            <Typography variant={isList ? 'subtitle2' : 'subtitle1'}>{issueNum}</Typography>
            <Typography variant={isList ? 'body2' : 'h6'}>{issueDesc}</Typography>
            <Box position='relative' ref={chipsContainerRef}>
              {chips.map(chip => (
                <Chip
                  key={chip.label}
                  {...chip}
                  sx={{
                    marginBottom: '3px',
                    marginRight: '3px',
                    fontSize: '11px',
                  }}
                  size='small'
                  variant='results'
                />
              ))}
            </Box>
            {!!practicesButtonText && <Button variant='outlined' onClick={onPracticesClick}>{practicesButtonText}</Button> }
          </Stack>
        </CardContent>
        {numRecommendedActions > 0 && (
          <Stack padding={2} direction='row' spacing={0.5}>
            <ActionIcon /><Typography fontWeight='bold'>{numRecommendedActions}</Typography><Typography>Recommended Action{numRecommendedActions !== 1 && 's'}</Typography>
          </Stack>
        )}
        <Stack
          bgcolor={footerColor}
          direction='row'
          spacing={3}
          justifyContent={isList ? 'center' : 'space-around'}
          textAlign='center'
          p={2}
          sx={{
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20,
          }}
        >
          <Stack>
            <Typography variant={isList ? 'subtitle2' : 'subtitle1'}>Answer</Typography>
            <Typography variant={isList ? 'body2' : 'body1'}>{answer}</Typography>
          </Stack>
          <Stack>
            <Typography variant={isList ? 'subtitle2' : 'subtitle1'}>Score</Typography>
            <Typography variant={isList ? 'body2' : 'body1'}>{score.toFixed(2)}</Typography>
          </Stack>
          <Stack>
            <Typography variant={isList ? 'subtitle2' : 'subtitle1'}>Importance</Typography>
            <Typography variant={isList ? 'body2' : 'body1'}>{importance}</Typography>
          </Stack>
          {isEasyFix !== undefined && (
            <Stack>
              <Typography variant={isList ? 'subtitle2' : 'subtitle1'}>Easy Fix?</Typography>
              <Typography variant={isList ? 'body2' : 'body1'}>{isEasyFix ? 'Yes' : 'No'}</Typography>
            </Stack>
          )}
        </Stack>
      </Card>
    </Link>
  )
}

export default React.memo(ResultsCard)
