import React from 'react'

import { SvgIcon } from '@mui/material'

import { ReactComponent as ActionIconImage } from '../assets/icon-action.svg'

function ActionIcon() {
  return (
    <SvgIcon
      viewBox='0 0 25 25'
      sx={{
        height: '20px',
        width: '20px',
      }}
      component={ActionIconImage}
    />
  )
}

export default React.memo(ActionIcon)
