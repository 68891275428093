import React from 'react'

import { SvgIcon } from '@mui/material'

import { ReactComponent as DefaultLogo } from '../assets/CSP_Dashboard_Logo.svg'

function LogoDashboard() {
  return (
    <SvgIcon
      viewBox='0 0 269 70'
      sx={{
        height: '70px',
        width: '269px',
      }}
      component={DefaultLogo}
    />
  )
}

export default React.memo(LogoDashboard)
