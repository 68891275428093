import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'

import {
  useCurrentOrganization,
  useCurrentProduct,
} from '@/hooks/useSession'
import { useGetProductMetricsQuery } from '@/services/organization/product/metric'

import ChartsScore from '@/components/Charts/Score'

import DocumentWrapper from '@/components/DocumentWrapper'
import ScreenWrapper from '@/components/ScreenWrapper'

import ResultsProgressBar from '@/components/ResultsProgressBar'
import { Color } from '@/components/ResultsProgressBar/types'

import { ApplicableLawStat } from '@/components/Charts/Legal/types'

import {
  MEETING_REQUIREMENTS, RISKY,
} from '../LegallySpeaking/types'

import BenchmarkScoreCard from './components/BenchmarkScoreCard'

function Benchmarking(): React.ReactElement {
  const { id: organizationId } = useCurrentOrganization()
  const { id: productId } = useCurrentProduct()

  const { data: metrics } = useGetProductMetricsQuery({
    params: {
      id: organizationId,
      productId,
    },
    query: {
      metric: [
        'score',
        'benchmarkScore',
        'topPrivacyIssuesScore',
        'legallySpeaking',
      ],
    },
  }, { refetchOnMountOrArgChange: true })

  const overallScore = metrics?.data?.score || 0
  const overallScoreOthers = metrics?.data?.benchmarkScore?.othersScore || 0

  const topPrivacyIssuesScore = metrics?.data?.topPrivacyIssuesScore?.myScore || 0
  const topPrivacyIssuesScoreOthers = metrics?.data?.topPrivacyIssuesScore?.othersScore || 0

  const legallySpeakingMyScore = metrics?.data?.legallySpeaking?.score || 0
  const legallySpeakingOthersScore = metrics?.data?.legallySpeaking?.othersScore || 0

  const numberOfApplicableLaws = metrics?.data?.legallySpeaking?.applicableLawScores.length

  const numberOfAlignedApplicableLaws = (metrics?.data?.legallySpeaking?.excellent || 0) + (metrics?.data?.legallySpeaking?.meetingRequirements || 0)

  const getProgressBarColorFromScore = (score: number) => {
    if (score < 50) {
      return Color.ORANGE
    }

    if (score >= 50 && score < 70) {
      return Color.BLUE
    }

    return Color.GREEN
  }

  const getProgressBarFromStates = (legallySpeaking: ApplicableLawStat | undefined) => {
    if (!legallySpeaking) {
      return Color.ORANGE
    }
    if (legallySpeaking[RISKY] > 0) {
      return Color.ORANGE
    }

    if (legallySpeaking[RISKY] === 0 && legallySpeaking[MEETING_REQUIREMENTS] === 0) {
      return Color.GREEN
    }

    return Color.BLUE
  }

  return (
    <DocumentWrapper title='Common Sense Privacy | Benchmarking'>
      <ScreenWrapper bgcolor='grey.50'>
        <Container>
          <Stack mb={4} maxWidth='sm'>
            <Typography variant='h1' mb={4}>Benchmarking</Typography>
            <Typography variant='h4' mb={2}>How do you compare?</Typography>
            <Typography>
              When consumers choose which digital products to use privacy can be a major consideration.
              Users are becoming more savvy about the need to ensure that their data is respected.
              Use the information below to see how your product compares with others in your category in
              terms of overall rating, against applicable laws, and top privacy issues consumers care about most.
            </Typography>
          </Stack>
        </Container>
        <Container>
          <Box mb={2}>
            <BenchmarkScoreCard
              type='primary'
              title='Overall Score'
              actionText='Raise Your Score'
              actionLink='/dashboard/raise-your-score'
            >
              <ChartsScore value={overallScore} />
              <ResultsProgressBar value={overallScoreOthers} hasLabel={true} />
            </BenchmarkScoreCard>
          </Box>
        </Container>
        <Container>
          <Grid container={true} spacing={2} justifyContent='center'>
            <Grid item={true} xs={12} sm={6}>
              <BenchmarkScoreCard
                type='secondary'
                title='Top Privacy Issues'
                message={(
                  <Typography mb={2}>
                    Here is how you score on the top <strong>7</strong> privacy issues.
                  </Typography>
                )}
                actionText='Improve Ratings'
                actionLink='/dashboard/top-privacy-issues'
              >
                <ResultsProgressBar color={getProgressBarColorFromScore(topPrivacyIssuesScore)} value={topPrivacyIssuesScore} hasLabel={true} />
                <ResultsProgressBar value={topPrivacyIssuesScoreOthers} hasLabel={true} />
              </BenchmarkScoreCard>
            </Grid>
            <Grid item={true} xs={12} sm={6}>
              <BenchmarkScoreCard
                type='secondary'
                title='Legally Speaking'
                message={(
                  <Typography mb={2}>
                    You are aligned with <strong>{numberOfAlignedApplicableLaws}</strong> out of {numberOfApplicableLaws} laws that apply to you.
                  </Typography>
                )}
                actionText='Review Laws'
                actionLink='/dashboard/legally-speaking'
              >
                <ResultsProgressBar color={getProgressBarFromStates(metrics?.data?.legallySpeaking)} value={legallySpeakingMyScore} hasLabel={true} />
                <ResultsProgressBar value={legallySpeakingOthersScore} hasLabel={true} />
              </BenchmarkScoreCard>
            </Grid>
          </Grid>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(Benchmarking)
