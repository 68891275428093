import commonSenseApi from '@/state/apis/commonSense'

import {
  AuthResetPasswordPostResponseBody,
  AuthResetPasswordPostRequestPayload,
  AuthResetPasswordPatchResponseBody,
  AuthResetPasswordPatchRequestPayload,
  PostRequest,
  PostResponseBody,
  AuthLogoutRequestPayload,
  AuthLogoutResponsePostResponseBody,
  AuthMeRequestPayload,
  AuthMeGetResponseBody,
  RefreshAccessTokenResponseBody,
} from './types'

const authService = commonSenseApi.injectEndpoints({
  endpoints: build => ({
    getMe: build.query<AuthMeGetResponseBody, AuthMeRequestPayload>({
      query: () => ({
        url: 'v1/auth/me',
        method: 'GET',
      }),
    }),
    postLogin: build.mutation<PostResponseBody, PostRequest>({
      query: ({ body }) => ({
        url: 'v1/auth',
        method: 'POST',
        body,
      }),
    }),
    postResetPassword: build.mutation<AuthResetPasswordPostResponseBody, AuthResetPasswordPostRequestPayload>({
      query: ({ body }) => ({
        url: 'v1/auth/reset-password',
        method: 'POST',
        body,
      }),
    }),
    patchResetPassword: build.mutation<AuthResetPasswordPatchResponseBody, AuthResetPasswordPatchRequestPayload>({
      query: ({
        body, query,
      }) => ({
        url: `v1/auth/reset-password?token=${query.token}`,
        method: 'PATCH',
        body,
      }),
    }),
    postLogout: build.mutation<AuthLogoutResponsePostResponseBody, AuthLogoutRequestPayload>({
      query: () => ({
        url: 'v1/auth/logout',
        method: 'POST',
      }),
    }),
    refreshAccessToken: build.mutation<RefreshAccessTokenResponseBody, undefined>({
      query: () => ({
        url: 'v1/auth/refresh',
        method: 'POST',
      }),
    }),
  }),
})

export default authService

export const {
  usePostLoginMutation,
  usePostResetPasswordMutation,
  usePatchResetPasswordMutation,
  usePostLogoutMutation,
  useGetMeQuery,
  useRefreshAccessTokenMutation,
} = authService

export const {
  endpoints: {
    getMe,
    postLogin,
    postResetPassword,
    patchResetPassword,
    postLogout,
    refreshAccessToken,
  },
} = authService
