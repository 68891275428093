import React from 'react'
import { Drawer as MuiDrawer } from '@mui/material'

import type { Props } from './types'

function Drawer({
  maxWidth,
  ...props
}: Props) {
  return (
    <MuiDrawer
      PaperProps={{
        sx: {
          backgroundColor: 'white',
          width: '100%',
          maxWidth: maxWidth || 360,
        },
      }}
      {...props}
    />
  )
}

export default React.memo(Drawer)
