import {
  Container,
  Typography,
  Stack,
  Button,
} from '@mui/material'
import React, { useState } from 'react'

import DocumentWrapper from '@/components/DocumentWrapper'
import ScreenWrapper from '@/components/ScreenWrapper'
import CancelAccountDialog from '@/components/CancelAccountDialog'
import Plans from '@/components/Plans'

function AccountEditPlan(): React.ReactElement {
  const [
    open,
    setOpen,
  ] = useState(false)
  const handleOpenDialog = () => {
    setOpen(true)
  }
  const handleCloseDialog = () => {
    setOpen(false)
  }

  return (
    <DocumentWrapper title='Common Sense Privacy | Edit Plan'>
      <ScreenWrapper>
        <Plans
          subtitle='To change your plan make a selection below.'
          requiresPaidPlan={false}
          editMode={true}
        />
        <Container>
          <Stack spacing={3} mt={4} alignItems='flex-start'>
            <Typography variant='h6'>No longer need our service?</Typography>
            <Button variant='outlined' onClick={handleOpenDialog}>Cancel Account</Button>
          </Stack>
        </Container>
      </ScreenWrapper>
      <CancelAccountDialog open={open} onClose={handleCloseDialog} />
    </DocumentWrapper>
  )
}

export default React.memo(AccountEditPlan)
