import commonSenseApi from '@/state/apis/commonSense'

import {
  GetProductPrivacyIssuesRequest,
  GetProductPrivacyIssuesResponseBody,
} from './types'

const privacyIssueService = commonSenseApi.injectEndpoints({
  endpoints: build => ({
    getPrivacyIssuesByState: build.query<GetProductPrivacyIssuesResponseBody, GetProductPrivacyIssuesRequest>({
      query: ({
        params: {
          id,
          productId,
          lawId,
          state,
        },
        query: {
          offset,
          limit,
        },
      }) => ({
        url: `v1/organizations/${id}/products/${productId}/applicable-laws/${lawId}/privacy-issues/${state}?limit=${limit}&offset=${offset}`,
        method: 'GET',
      }),
      serializeQueryArgs: ({
        endpointName,
        queryArgs,
      }) => `${endpointName}::${queryArgs.params.state}`,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      merge: (currentCache: any, responseData: any) => {
        const {
          total,
          offset,
          limit,
          items,
        } = responseData.data

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let newItems: any[]
        if (offset < currentCache.data?.items.length) {
          newItems = items
        }
        else {
          newItems = (currentCache.data?.items || []).concat(items)
        }

        currentCache.data = {
          total,
          offset,
          limit,
          items: newItems,
        }
      },
      providesTags: [ 'PrivacyIssuesMeetingRequirements' ],
    }),
  }),
})

export default privacyIssueService

export const { useGetPrivacyIssuesByStateQuery } = privacyIssueService
