import React, {
  createContext,
  useState,
} from 'react'
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'

import config from '@/config'

import type {
  NewRelicConfig,
  NewRelicContextType,
} from './types'

export const NewRelicContext = createContext<NewRelicContextType | undefined>(undefined)

function NewRelicContextProvider({
  children,
  config: newRelicConfig,
}: {
  children: React.ReactElement,
  config?: NewRelicConfig,
}): React.ReactElement {
  const [
    agent,
    setAgent,
  ] = useState<BrowserAgent>()

  if (!config.newRelic.isEnabled) {
    return children
  }

  if (!agent) {
    setAgent(init(newRelicConfig))
  }

  return (
    <NewRelicContext.Provider value={agent}>
      {children}
    </NewRelicContext.Provider>
  )
}

const init = (newRelicConfig: NewRelicConfig = {}) => new BrowserAgent({
  init: {
    ajax: { deny_list: [ 'bam.nr-data.net' ] },
    distributed_tracing: { enabled: true },
    page_view_timing: { enabled: true },
    privacy: { cookies_enabled: true },
    session_trace: { enabled: true },
    ...newRelicConfig.init,
  },
  info: {
    beacon: 'bam.nr-data.net',
    errorBeacon: 'bam.nr-data.net',
    sa: 1,
    ...newRelicConfig.info,
    applicationID: config.newRelic.applicationId,
    licenseKey: config.newRelic.licenseKey,
  },
  loader_config: {
    ...newRelicConfig.loader_config,
    accountID: config.newRelic.accountId,
    agentID: config.newRelic.applicationId, // agentID and applicationID were the same in the newrelic-provided snippet
    applicationID: config.newRelic.applicationId,
    licenseKey: config.newRelic.licenseKey,
    trustKey: config.newRelic.trustKey,
  },
})

export default NewRelicContextProvider
