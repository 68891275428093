import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

import dateTime from '@/utils/dateTime'

import {
  PrivacyIssueAnswerInputField,
  PrivacyIssueAnswerInputFieldOption,
  ProductPrivacyIssue,
} from './types'

function PrivacyIssueQuestion({
  inputId,
  isLoading = false,
  onSave,
  productPrivacyIssue,
  saveButtonText = 'Save',
  showDividerAboveButtons = false,
} : {
  inputId: string,
  isLoading?: boolean,
  onSave: (answerIds: string[]) => void,
  productPrivacyIssue: ProductPrivacyIssue,
  saveButtonText?: string,
  showDividerAboveButtons?: boolean,
}): React.ReactElement {
  const { privacyIssue } = productPrivacyIssue

  const isMultiple = useMemo(() => privacyIssue.type === 'multi-select', [ privacyIssue.type ])

  const [
    answers,
    setAnswers,
  ] = useState<string[]>([])

  useEffect(() => {
    if (isLoading || !productPrivacyIssue?.answers) {
      return
    }

    if (productPrivacyIssue.privacyIssue.type === 'multi-select' && productPrivacyIssue?.answers?.length === 0) {
      const defaultNoAnswerIds = productPrivacyIssue.privacyIssue.answerInputFields
        .flatMap(answerInputField => answerInputField?.privacyIssueAnswerInputFieldOptions?.find(option => option.internalValue === 'No')?.id)
        .filter(answerId => answerId) as string[]

      if (defaultNoAnswerIds && defaultNoAnswerIds.length > 0) {
        setAnswers(defaultNoAnswerIds)

        return
      }
    }

    const answers = productPrivacyIssue.answers
      .map(answer => productPrivacyIssue.privacyIssue.type === 'date' ? answer.userEnteredValue : answer.answer?.id)
      .filter(id => !!id)

    setAnswers(answers)
  }, [
    isLoading,
    productPrivacyIssue,
  ])

  const onClearForm = useCallback(() => {
    setAnswers([])
  }, [])

  // const handleOnSaveForLater = () => {
  //   // TODO: submit
  // }

  const getNewAnswers = (answerId: string, checked: boolean) => {
    if (!isMultiple) {
      return [ answerId ]
    }

    if (checked) {
      return [
        ...answers,
        answerId,
      ]
    }

    return answers.filter(id => id !== answerId)
  }

  const handleOnRadioChange = (answerId: string, checked: boolean) => {
    setAnswers(getNewAnswers(answerId, checked))
  }

  const handleOnCheckboxChange = (inputFieldId: string, checked: boolean) => {
    const currentAnswers = answers
    const inputField = privacyIssue.answerInputFields.find((inputField: PrivacyIssueAnswerInputField) => inputField.id === inputFieldId) as PrivacyIssueAnswerInputField
    const foundOption = inputField.privacyIssueAnswerInputFieldOptions.find((option: PrivacyIssueAnswerInputFieldOption) => {
      if (checked) {
        return option.internalValue === 'Yes'
      }

      return option.internalValue === 'No'
    }) as PrivacyIssueAnswerInputFieldOption
    const removeOption = inputField.privacyIssueAnswerInputFieldOptions.find((option: PrivacyIssueAnswerInputFieldOption) => option.id !== foundOption.id) as PrivacyIssueAnswerInputFieldOption
    const newAnswers = currentAnswers.filter(id => id !== removeOption.id)
    newAnswers.push(foundOption.id)

    setAnswers(newAnswers)
  }

  const isCheckBoxChecked = (inputFieldId: string) => {
    const inputField = privacyIssue?.answerInputFields?.find((inputField: PrivacyIssueAnswerInputField) => inputField.id === inputFieldId) as PrivacyIssueAnswerInputField
    const currentAnswers = answers
    const options = inputField.privacyIssueAnswerInputFieldOptions as PrivacyIssueAnswerInputFieldOption[]
    const answer = options.find((option: PrivacyIssueAnswerInputFieldOption) => currentAnswers.includes(option.id))

    if (answer) {
      return answer.internalValue === 'Yes'
    }

    return false
  }

  const handleOnMultiRadioChange = (inputFieldId: string, optionId: string) => {
    const inputField = privacyIssue.answerInputFields.find(field => field.id === inputFieldId)
    if (!inputField) return
    const selectedOption = inputField.privacyIssueAnswerInputFieldOptions
      .find(option => option.id === optionId)
    const otherOption = inputField.privacyIssueAnswerInputFieldOptions
      .find(option => option.id !== optionId)

    const newAnswers = otherOption ? answers.filter(answer => answer !== otherOption.id) : answers.slice()
    if (selectedOption && !answers.includes(selectedOption.id)) {
      newAnswers.push(selectedOption.id)
    }

    setAnswers(newAnswers)
  }

  const renderRadioGroup = (privacyIssueAnswerInputField: PrivacyIssueAnswerInputField) => (
    <RadioGroup
      name={inputId}
    >
      {privacyIssueAnswerInputField.privacyIssueAnswerInputFieldOptions.map(option => (
        <FormControlLabel
          key={option.id}
          value={option.id}
          control={<Radio data-testid={`radio-${option.id}`} checked={answers.includes(option.id)} />}
          label={option.value}
          onChange={(event: React.SyntheticEvent) => handleOnRadioChange(option.id, (event.target as HTMLInputElement).checked)}
        />
      ))}
    </RadioGroup>
  )

  const renderDateInput = () => {
    const value = answers?.[0] && !Number.isNaN(new Date(answers[0]).getTime())
      ? new Date(answers[0])
      : null // eslint-disable-line no-null/no-null

    return (
      <Box maxWidth='sm'>
        <DatePicker<Date>
          onChange={value => {
            setAnswers(value ? [ dateTime.format(value, 'yyyy/MM/dd') ] : [])
          }}
          value={value}
        />
      </Box>
    )
  }

  const renderCheckboxList = (privacyIssueAnswerInputFields: PrivacyIssueAnswerInputField[]) => (
    <FormGroup>
      {privacyIssueAnswerInputFields.map(inputField => (
        <FormControlLabel
          key={inputField.id}
          value={inputField.id}
          control={<Checkbox data-testid={`check-${inputField.id}`} checked={isCheckBoxChecked(inputField.id)} />}
          label={inputField?.privacyIssueAnswerInputFieldOptions.at(0)?.value}
          onChange={(event: React.SyntheticEvent) => handleOnCheckboxChange(inputField.id, (event.target as HTMLInputElement).checked)}
        />
      ))}
    </FormGroup>
  )

  const renderMultiRadioList = (privacyIssueAnswerInputFields: PrivacyIssueAnswerInputField[]) => (
    privacyIssueAnswerInputFields.map(inputField => (
      <FormControl key={inputField.id}>
        <FormLabel>{inputField.label}</FormLabel>
        <RadioGroup sx={{ paddingLeft: '12px' }}>
          {inputField.privacyIssueAnswerInputFieldOptions.map(option => (
            <FormControlLabel
              key={option.id}
              value={option.id}
              control={<Radio data-testid={`radio-${option.id}`} checked={answers.includes(option.id)} />}
              label={option.internalValue}
              onChange={() => handleOnMultiRadioChange(inputField.id, option.id)}
            />
          ))}
        </RadioGroup>
        <Box maxWidth='250px' mt={1}><Divider /></Box>
      </FormControl>
    ))
  )

  return (
    <Stack spacing={2}>
      {(privacyIssue.type === 'date' && (
        <FormControl sx={{ marginTop: 0 }}>
          {renderDateInput()}
        </FormControl>
      )) || (privacyIssue.type === 'matrix' && (
        <Stack spacing={4}>
          {renderMultiRadioList(privacyIssue.answerInputFields)}
        </Stack>
      )) || (privacyIssue.type === 'multi-select' && (
        <FormControl sx={{ marginTop: 0 }}>
          {renderCheckboxList(privacyIssue.answerInputFields)}
        </FormControl>
      )) || privacyIssue?.answerInputFields.map((answerInputField: PrivacyIssueAnswerInputField) => (
        <FormControl key={`${answerInputField.id}-input-field`} sx={{ marginTop: 0 }}>
          {renderRadioGroup(answerInputField)}
        </FormControl>
      ))}

      {showDividerAboveButtons && <Divider />}

      <Stack direction='row' spacing={2}>
        <Button sx={{ width: 'auto' }} onClick={() => onSave(answers)}>{saveButtonText}</Button>
        <Button
          variant='outlined'
          sx={{ width: 'auto' }}
          disabled={!answers.length}
          onClick={onClearForm}
        >
          Clear Form
        </Button>
        {/* <Button variant='link' onClick={handleOnSaveForLater}>Save For Later</Button> */}
      </Stack>
    </Stack>
  )
}

export default React.memo(PrivacyIssueQuestion)
