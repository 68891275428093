import React from 'react'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material'

import {
  RecommendedActionAnswerInputFieldOption,
  Props,
} from './types'

function RecommendedActionQuestion({
  onAnswer,
  answerIds,
  inputId,
  answerInputFields,
} : Props): React.ReactElement {
  const handleOnChange = (answerId: string) => {
    onAnswer([ answerId ])
  }

  const renderRadioGroup = (recommendedActionAnswerInputFieldOptions: RecommendedActionAnswerInputFieldOption[]) => (
    <RadioGroup
      name={inputId}
    >
      {recommendedActionAnswerInputFieldOptions.map(option => (
        <FormControlLabel
          key={option.id}
          value={option.id}
          control={<Radio data-testid={`radio-${option.id}`} checked={answerIds.includes(option.id)} />}
          label={option.value}
          onChange={() => handleOnChange(option.id)}
        />
      ))}
    </RadioGroup>
  )

  return (
    <Stack>
      {answerInputFields.map(answerInputField => (
        <FormControl key={`${answerInputField.id}-input-field`} sx={{ marginTop: 0 }}>
          {renderRadioGroup(answerInputField.options)}
        </FormControl>
      ))}
    </Stack>
  )
}

export default React.memo(RecommendedActionQuestion)
