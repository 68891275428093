import commonSenseApi from '@/state/apis/commonSense'

import {
  PostGetCouponRequestResponseBody,
  GetPlansResponseBody,
  GetSubscriptionResponseBody,
  PostSubscriptionResponseBody,
  PostSubscriptionRequest,
  PostStripeSetupIntentResponse,
  PutStripePaymentMethodResponse,
  PutStripePaymentMethodRequest,
  PostStripeSetupIntentRequest,
  PostCreatePaymentPreviewResponseBody,
  PostCreatePaymentPreviewRequest,
  PostGetCouponRequest,
} from './types'

const path = 'v1/stripe'
const stripeService = commonSenseApi.injectEndpoints({
  endpoints: build => ({
    getPlans: build.query<GetPlansResponseBody, void>({
      query: () => ({
        url: 'v1/stripe/plans',
        method: 'GET',
      }),
    }),
    getSubscription: build.query<GetSubscriptionResponseBody, void>({
      query: () => ({
        url: `${path}/subscriptions`,
        method: 'GET',
      }),
    }),
    postCreateSubscription: build.mutation<PostSubscriptionResponseBody, PostSubscriptionRequest>({
      query: ({ body }) => ({
        url: `${path}/subscriptions`,
        method: 'POST',
        body,
      }),
    }),
    createSetupIntent: build.mutation<PostStripeSetupIntentResponse, PostStripeSetupIntentRequest>({
      query: ({ body }) => ({
        url: `${path}/setup-intents`,
        method: 'POST',
        body,
      }),
    }),
    updatePaymentMethod: build.mutation<PutStripePaymentMethodResponse, PutStripePaymentMethodRequest>({
      query: ({ body }) => ({
        url: `${path}/payment-methods`,
        method: 'PUT',
        body,
      }),
    }),
    postCreatePaymentPreview: build.mutation<PostCreatePaymentPreviewResponseBody, PostCreatePaymentPreviewRequest>({
      query: ({ body }) => ({
        url: `${path}/payment-preview`,
        method: 'POST',
        body,
      }),
    }),
    postGetCoupon: build.mutation<PostGetCouponRequestResponseBody, PostGetCouponRequest>({
      query: ({ body }) => ({
        url: `${path}/coupons`,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export default stripeService

export const {
  useGetPlansQuery,
  useGetSubscriptionQuery,
  usePostCreateSubscriptionMutation,
  usePostCreatePaymentPreviewMutation,
  usePostGetCouponMutation,
  useCreateSetupIntentMutation,
  useUpdatePaymentMethodMutation,
} = stripeService

export const {
  endpoints: {
    getPlans,
    getSubscription,
    postCreateSubscription,
  },
} = stripeService
