import {
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'

import CircularLoading from '@/components/CircularLoading'

import PrivacyIssueCard from '@/components/PrivacyIssueCard'

import { useGetProductPrivacyIssueListQuery } from '@/services/organization/product'
import { useGetProductMetricsQuery } from '@/services/organization/product/metric'
import {
  useCurrentOrganization,
  useCurrentProduct,
} from '@/hooks/useSession'
import { ProductPrivacyIssue } from '@/services/organization/product/types'

import { TopPrivacyIssueStat } from '@/components/Charts/Privacy/types'

import DocumentWrapper from '../../../components/DocumentWrapper'
import ScreenWrapper from '../../../components/ScreenWrapper'

function DashboardTopPrivacyIssues(): React.ReactElement {
  const { id: organizationId } = useCurrentOrganization()
  const product = useCurrentProduct()
  const { id: productId } = product || { id: '' }

  const { data: metrics } = useGetProductMetricsQuery({
    params: {
      id: organizationId,
      productId,
    },
    query: { metric: [ 'topPrivacyIssues' ] },
  }, { refetchOnMountOrArgChange: true })

  const {
    data: topProductPrivacyIssues,
    isLoading,
  } = useGetProductPrivacyIssueListQuery({
    params: {
      id: organizationId,
      productId,
    },
    query: { category: 'top-priority' },
  }, { refetchOnMountOrArgChange: true })

  const topPrivacyIssueStats:TopPrivacyIssueStat[] = metrics?.data?.topPrivacyIssues || []
  const stats: {
    good: number,
    bad: number,
    unclear: number,
  } = topPrivacyIssueStats.reduce((ac, { score }) => {
    if (score >= 1) {
      ac.good += 1
    }
    else if (score === 0) {
      ac.unclear += 1
    }
    else {
      ac.bad += 1
    }

    return ac
  }, {
    good: 0,
    bad: 0,
    unclear: 0,
  })

  const topPrivacyIssues: ProductPrivacyIssue[] = topProductPrivacyIssues?.data?.items || []

  if (isLoading || !organizationId || !productId) {
    return <CircularLoading my={6} />
  }

  return (
    <DocumentWrapper title='Common Sense Privacy | Dashboard Top Privacy Issues'>
      <ScreenWrapper bgcolor='grey.50'>
        <Container>
          <Stack direction='row' justifyContent='space-between' mb={4}>
            <Typography variant='h1'>Top Privacy Issues</Typography>
          </Stack>
        </Container>
        <Container>
          <Grid container={true} spacing={2} justifyContent='center' alignItems='stretch'>
            <Grid item={true} xs={12} sm={12} md={8}>
              <Stack spacing={2}>
                <Typography variant='h4'>What is a top privacy issue?</Typography>
                <Typography variant='intro'>Top privacy issues represent the things that people care about most when it comes to privacy. Please consider these issues carefully to ensure customers feel good about using your product. Review privacy issues below for details on how you rate and recommendations to improve your score.
                </Typography>
                <Stack direction='row' spacing={0.5}>
                  <Typography data-testid='good-stat'><strong>Clear & Good:</strong> {stats.good} |</Typography>
                  <Typography data-testid='bad-stat'><strong>Clear & Bad:</strong> {stats.bad} |</Typography>
                  <Typography data-testid='unclear-stat'><strong>Unclear:</strong> {stats.unclear}</Typography>
                </Stack>
              </Stack>
            </Grid>
            {topPrivacyIssues.map(issue => (
              <Grid key={issue.id} item={true} xs={12} sm={6} md={4}>
                <PrivacyIssueCard
                  data={issue}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(DashboardTopPrivacyIssues)
