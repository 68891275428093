import React from 'react'

import { transformProductRecommendedAction } from '@/utils/recommendedAction'

import ActionCard from '../ActionCard'

import { Props } from './types'

function RecommendedActionCard({
  productRecommendedAction,
  productPrivacyIssue,
  stats,
  onPracticesClick,
}: Props) {
  const {
    title,
    description,
    score,
    status,
    tags,
  } = transformProductRecommendedAction({
    productRecommendedAction,
    productPrivacyIssue,
    stats,
  })

  return (
    <ActionCard
      title={title}
      description={description}
      score={score}
      status={status}
      tags={tags}
      onPracticesClick={() => onPracticesClick && onPracticesClick(productRecommendedAction)}
    />
  )
}

export default React.memo(RecommendedActionCard)
