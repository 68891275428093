import React from 'react'
import {
  Box,
  Typography,
} from '@mui/material'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
    }}
    >
      <Box sx={{
        width: '100%',
        mr: 1,
      }}
      >
        { props.value === 0 ? (
          <LinearProgress
            variant='determinate'
            sx={{ backgroundColor: '#ccc' }}
            {...props}
          />
        ) : (
          <LinearProgress variant='determinate' {...props} />
        )}
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant='body2' color='text.secondary'>{`${Math.round(
          props.value,
        )}%`}
        </Typography>
      </Box>
    </Box>
  )
}

export default React.memo(LinearProgressWithLabel)
