import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'

import { RootState } from '../../types'

import {
  Organization,
  SessionState,
  UserOrganization,
  User,
  Product,
  SubscriptionWithExpansions,
} from './types'

export const initialState: SessionState = {
  accessToken: undefined,
  user: undefined,
}

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<SessionState | undefined>) => {
      if (!action.payload) {
        // Clear session (log out)
        return initialState
      }

      return {
        ...state,
        ...action.payload,
      }
    },
    setUser: (state, action: PayloadAction<User | undefined>) => {
      if (!action.payload) {
        // Clear session (log out)
        return state
      }

      state.user = action.payload

      return state
    },
    setUserEmailNotificationSetting: (state, action: PayloadAction<boolean>) => {
      if (!state.user) {
        return state
      }

      state.user.isEmailNotificationEnabled = action.payload

      return state
    },
    setUserOrganization: (state, action: PayloadAction<Organization>) => {
      if (!state.user) {
        return state
      }

      if (!state.user.organizations) {
        return state
      }

      state.user.organizations = state.user.organizations.map(
        (userOrganization: UserOrganization) => userOrganization.organization.id === action.payload.id ? {
          ...userOrganization,
          organization: action.payload,
        }
          : userOrganization,
      )

      return state
    },
    setProduct: (state, action: PayloadAction<{organizationId: string, product: Product}>) => {
      if (!state.user || !state.user.organizations) {
        return state
      }

      const {
        organizationId, product: newProduct,
      } = action.payload

      state.user.organizations = state.user.organizations.map((userOrganization: UserOrganization) => {
        if (userOrganization.organization.id === organizationId) {
          return {
            ...userOrganization,
            organization: {
              ...userOrganization.organization,
              products: userOrganization.organization.products.map(product => {
                if (product.id === newProduct.id) {
                  return newProduct
                }

                return product
              }),
            },
          }
        }

        return userOrganization
      })

      return state
    },
    setSubscription: (state, action: PayloadAction<SubscriptionWithExpansions>) => {
      state.subscription = action.payload

      return state
    },
  },
})

export const {
  setSession,
  setUserEmailNotificationSetting,
  setUserOrganization,
  setUser,
  setProduct,
  setSubscription,
} = sessionSlice.actions

export const sessionSelectors = { session: (state: RootState) => state.session }

export default sessionSlice.reducer

export * from './types'
