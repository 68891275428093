import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'

import {
  Role, useCurrentOrganization,
} from '@/hooks/useSession'

import useRouteParams from '@/hooks/useRouteParams'

import {
  useGetPrivacyTopicQuery,
  useGetProductTopicsQuery,
} from '@/services/organization/product/topics'

import TabPanel from '../../components/TabPanel'
import TabItem from '../../components/TabItem'
import LinearProgressWithLabel from '../../components/LinearProgress'

import { ProductPrivacyIssue } from './ProductPrivacyIssue/types'

function a11yProps(index: number) {
  return {
    id: `custom-tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  }
}

function PrivacyTopicCover(): React.ReactElement {
  const { id: organizationId } = useCurrentOrganization()
  const {
    productId,
    topicId,
  } = useRouteParams()
  const navigate = useNavigate()

  const {
    isJurisdictional,
    nextTopicId,
  } = useGetProductTopicsQuery(
    {
      params: {
        id: organizationId,
        productId: `${productId}`,
      },
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data }) => {
        const topics = data?.data?.topics
        if (!topics) {
          return {
            isJurisdictional: false,
            nextTopicId: '',
          }
        }

        const topicIndex = topics.findIndex(({ id }) => id === topicId)
        const topic = topics[topicIndex]

        return {
          isJurisdictional: topic.code === '0.0.0',
          nextTopicId: topics[topicIndex + 1]?.id || '',
        }
      },
    },
  )

  const {
    data: topic, isLoading,
  } = useGetPrivacyTopicQuery(
    {
      params: {
        id: organizationId,
        productId: `${productId}`,
        topicId: `${topicId}`,
      },
    },
    { refetchOnMountOrArgChange: true },
  )

  const {
    title,
    progressPercentage,
    description,
    lastAnsweredProductPrivacyIssueId,
    roles,
    code,
    skippedProductPrivacyIssues,
  } = topic?.data || {
    title: '',
    progressPercentage: 0,
    description: '',
    lastAnsweredProductPrivacyIssueId: '',
    roles: [],
  }

  const [
    pageForSkippedItems,
    setPageForSkippedItems,
  ] = useState(1)

  const skippedItems = skippedProductPrivacyIssues || []
  const totalPagesForSkippedItems = Math.ceil(skippedItems.length / 15)
  const paginatedSkippedItems = skippedItems.slice(0, pageForSkippedItems * 15)

  const canSkipSection = (isJurisdictional && progressPercentage === 100) || !isJurisdictional

  const [
    value,
    setValue,
  ] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Container>
      { isLoading
        ? (
          <>
            <Typography variant='h1' mb={4}><Skeleton animation='wave' /></Typography>
            <Typography mb={2}><Skeleton animation='wave' /></Typography>
            <Typography mb={4}><Skeleton animation='wave' /></Typography>
            <Typography mb={2}><Skeleton animation='wave' /></Typography>
            <Skeleton animation='wave' height={200} />
          </>
        )
        : (
          <>
            <Typography variant='h1' mb={4}>{title}</Typography>
            <Typography mb={2}>{description}
            </Typography>
            <Box>
              <Typography display='inline'> To complete this most people need knowledge in the following areas: </Typography>
              {roles?.map((role: Role) => (
                <Chip
                  key={role.id}
                  label={role.name}
                  variant='results'
                  sx={{
                    marginLeft: 1,
                    marginBottom: 0.5,
                    marginTop: 0.5,
                  }}
                />
              ))}
            </Box>
            <Stack direction='row' spacing={1} my={4}>
              <Button sx={{ width: 'auto' }} onClick={() => navigate(`/wizard/products/${productId}/topics/${topicId}/questions/${lastAnsweredProductPrivacyIssueId}`)}>
                {progressPercentage > 0 ? 'Resume' : 'Start'}
              </Button>
              {canSkipSection && (
                <Button
                  variant='outlined'
                  sx={{ width: 'auto' }}
                  onClick={() => navigate(nextTopicId ? `/wizard/products/${productId}/topics/${nextTopicId}` : `/wizard/products/${productId}/finalize`)}
                >Skip Section
                </Button>
              )}
            </Stack>
            <Box mb={4}>
              <Divider />
            </Box>
            { code !== '2.0.0' && (
              <>
                <Typography variant='h3' mb={4}>Progress</Typography>
                <Box sx={{ width: '100%' }}>
                  <LinearProgressWithLabel value={progressPercentage} />
                </Box>
                <Box my={4}>
                  <Divider />
                </Box>
              </>
            )}
            <Typography variant='h3' mb={4}>Items to Complete</Typography>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label='Saved or Skipped'
            >
              <Tab icon={<SwapHorizIcon />} iconPosition='start' label='Skipped' {...a11yProps(0)} disableRipple={true} />
              {/* <Tab icon={<StarIcon />} iconPosition='start' label='Saved' {...a11yProps(1)} disableRipple={true} /> */}
            </Tabs>
            <TabPanel value={value} index={0}>
              {paginatedSkippedItems.length > 0 ? (
                <>
                  {paginatedSkippedItems.map((productPrivacyIssue: ProductPrivacyIssue) => (
                    <TabItem
                      to={`/wizard/products/${productId}/topics/${topicId}/questions/${productPrivacyIssue.id}`}
                      key={productPrivacyIssue.id}
                      question={productPrivacyIssue.privacyIssue.code}
                      topic={title}
                      title={productPrivacyIssue.privacyIssue.title}
                    />

                  ))}

                  <Box p={4} textAlign='center'>
                    { pageForSkippedItems < totalPagesForSkippedItems && <Button variant='link' onClick={() => setPageForSkippedItems(pageForSkippedItems + 1)}>Show More <ExpandMoreIcon /></Button> }
                    { pageForSkippedItems > 1 && <Button variant='link' onClick={() => setPageForSkippedItems(pageForSkippedItems - 1)}>Show Less <ExpandLessIcon /></Button> }
                  </Box>
                </>
              ) : (
                <Typography>Your skipped questions will appear here.</Typography>
              )}
            </TabPanel>
          </>
        )}
      {/* <TabPanel value={value} index={1}>
        <TabItem
          question='10.4.3'
          topic='Advertising'
          title='Ad Profile'
        />
        <TabItem
          question='10.4.3'
          topic='Advertising'
          title='Ad Profile'
        />
      </TabPanel> */}
    </Container>
  )
}

export default React.memo(PrivacyTopicCover)
