import { api } from '@common-sense-privacy/common'

export type GetProductPrivacyIssuesRequest = api.endpoints.organization.product.applicableLaw.privacyIssue.types.GetListRequest

export type GetProductPrivacyIssuesResponseBody = api.endpoints.organization.product.applicableLaw.privacyIssue.types.GetListResponseBody

export const {
  NO_ACTION,
  IMPROVE_LEGAL_PRACTICE,
  MINIMUM_LEGAL_REQUIREMENTS,
} = api.endpoints.organization.product.applicableLaw.types.ApplicableLawState
