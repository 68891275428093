import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'

import history from '@/App/Router/history'

import config from '@/config'

import { PostResponseBody } from '@/services/auth/types'

import {
  EASY_FIX, HIGH_PRIORITY_FIX, TOP_PRIORITY,
} from '@/services/organization/product/types'

import { IMPROVE_LEGAL_PRACTICE } from '@/services/organization/product/applicableLaw/privacyIssue/types'

import log from '@/utils/log'

import { RootState } from '../../types'
import { setSession } from '../../slices/session'

const mutex = new Mutex()

export const baseQuery = fetchBaseQuery({
  baseUrl: config.apiBaseUrl || 'http://localhost',
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const { accessToken } = (getState() as RootState).session ?? {}

    if (accessToken) {
      headers.set('Access-Control-Allow-Credentials', 'true')
      headers.set('Authorization', `Bearer ${accessToken}`)
    }

    return headers
  },
})

const baseQueryWithRefresh: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock()

  let result = await baseQuery(args, api, extraOptions)

  if (api.endpoint === 'postSession') {
    return result
  }

  if (result.error?.status === 401 && (
    ((result.error?.data as { isSessionExpired?: boolean })?.isSessionExpired)
    || ((result.error?.data as { isSubscriptionStale?: boolean })?.isSubscriptionStale)
  )) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()

      try {
        const refreshResult = (await baseQuery(
          {
            credentials: 'include',
            method: 'post',
            url: 'v1/auth/refresh',
          },
          api,
          extraOptions,
        )) || undefined
        if (refreshResult?.data) {
          const userSession = refreshResult.data as PostResponseBody
          log('info', '✅ Successfully refreshed accessToken after expiration.')
          api.dispatch(setSession(userSession?.data))

          // Retry the initial query
          result = await baseQuery(args, api, extraOptions)
        }
        else {
          // Log out
          api.dispatch(setSession(undefined))

          history.push('/signin')

          /**
           * @todo fix when toast is implemented
           */
          // Toast.show({
          //   title: 'Session Expired',
          //   description: lang().messages.sessionExpired(),
          //   type: 'info',
          // })
        }
      }
      finally {
        // release must be called once the mutex should be released again.
        release()
      }
    }
    else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }

  return result
}

const api = createApi({
  baseQuery: baseQueryWithRefresh,
  endpoints: () => ({}),
  reducerPath: 'common-sense-privacyApi',
  tagTypes: [
    'Users',
    'PrivacyTopics',
    'ProductScore',
    'PrivacyIssueScore',
    'ProductPrivacyIssue',
    'PrivacyIssues',
    'ProductApplicableLaw',
    'PrivacyIssuesImprovePractice',
    'PrivacyIssuesNoAction',
    'PrivacyIssuesMeetingRequirements',
    `PrivacyIssues::${HIGH_PRIORITY_FIX}`,
    `PrivacyIssues::${IMPROVE_LEGAL_PRACTICE}`,
    `PrivacyIssues::${EASY_FIX}`,
    `PrivacyIssues::${TOP_PRIORITY}`,
    'RecommendedAction',
  ],
})

export default api
