import {
  Box,
  Container,
  List,
  ListItem,
  Typography,
} from '@mui/material'
import React from 'react'

import DocumentWrapper from '../../../components/DocumentWrapper'
import ScreenWrapper from '../../../components/ScreenWrapper'

function Terms(): React.ReactElement {
  return (
    <DocumentWrapper title='Common Sense Privacy | Terms and Conditions'>
      <ScreenWrapper>
        <Container>
          <Typography variant='h1'>Terms and Conditions</Typography>
        </Container>
        <Container>
          <Box mb='6'>
            <List sx={{
              listStyleType: 'disc',
              paddingLeft: 4,
            }}
            >
              <ListItem sx={{ display: 'list-item' }}>Test List Item</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Test List Item</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Test List Item</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Test List Item</ListItem>
              <List sx={{
                listStyleType: 'disc',
                paddingLeft: 4,
              }}
              >
                <ListItem sx={{ display: 'list-item' }}>Test Nested List Item</ListItem>
                <ListItem sx={{ display: 'list-item' }}>Test Nested List Item</ListItem>
                <ListItem sx={{ display: 'list-item' }}>Test Nested List Item</ListItem>
              </List>
              <ListItem sx={{ display: 'list-item' }}>Test List Item</ListItem>
            </List>
          </Box>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(Terms)
