import usePrevious from '@/hooks/usePrevious'

import {
  Props,
  SubscriptionWithExpansions,
} from './types'

const usePreviousSubscription = ({ subscription }: Props) => {
  const testFunction = (newSub: SubscriptionWithExpansions | undefined, prevSub: SubscriptionWithExpansions | undefined) => {
    const newPlan = newSub?.plan?.product?.metadata?.type
    const oldPlan = prevSub?.plan?.product?.metadata?.type

    if (!oldPlan || newPlan === oldPlan) return true

    return false
  }
  const previousSubscription = usePrevious<SubscriptionWithExpansions | undefined>(subscription, testFunction)

  return previousSubscription
}

export default usePreviousSubscription
