import commonSenseApi from '../../state/apis/commonSense'

import {
  PostRequest, PostResponseBody,
} from './types'

const accountService = commonSenseApi.injectEndpoints({
  endpoints: build => ({
    postSignUp: build.mutation<PostResponseBody, PostRequest>({
      query: request => ({
        url: 'v1/signup',
        method: 'POST',
        body: request,
      }),
    }),
  }),
})

export default accountService

export const { usePostSignUpMutation } = accountService

export const { endpoints: { postSignUp } } = accountService
