import {
  TransformProps,
  TransformData,
  Status,
} from './types'

// eslint-disable-next-line import/prefer-default-export
export const transformProductRecommendedAction = ({
  productRecommendedAction,
  productPrivacyIssue,
  stats,
}: TransformProps): TransformData => {
  const emptyData = {
    title: '',
    description: '',
    score: '',
    status: Status.UNACTIONABLE,
    tags: [],
  }

  if (!productRecommendedAction || !productPrivacyIssue) return emptyData

  const score = stats?.score ? `${Math.round(stats.score * 100) / 100}` : '0'
  const {
    recommendedAction: { title },
    answers,
  } = productRecommendedAction

  const tags = stats?.codes.slice().sort((a, b) => {
    const code1 = a.split(':')[0].split('.').map(charDigit => Number(charDigit))
    const code2 = b.split(':')[0].split('.').map(charDigit => Number(charDigit))

    return code1[0] - code2[0] || code1[1] - code2[1] || code1[2] - code2[2]
  }) || []

  const [ answer ] = answers || []
  let description = ''
  let status: Status
  if (!answer) {
    description = productRecommendedAction.recommendedAction.callToActionStatement
    status = Status.UNACTIONABLE
  }
  else {
    description = answer.answer.statement
    if (answer.answer.isBetterPractice) {
      status = Status.COMPLETED
    }
    else {
      status = Status.ACTIONABLE
    }
  }

  return {
    title,
    description,
    score,
    status,
    tags,
  }
}
