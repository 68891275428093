import {
  Breadcrumbs,
  Button,
  Container,
  Divider,
  FormControlLabel,
  Link,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'

import useSession, { setUserEmailNotificationSetting } from '@/hooks/useSession'
import useNavigate from '@/hooks/useNavigate'
import useHandleFormApiErrors from '@/hooks/useHandleFormApiErrors'
import { usePatchUserNotificationMutation } from '@/state/services/user/notification'
import { useAlert } from '@/context/AlertContext'

import DocumentWrapper from '../../../components/DocumentWrapper'
import ScreenWrapper from '../../../components/ScreenWrapper'

function EditNotifications(): React.ReactElement {
  const dispatch = useDispatch()
  const session = useSession()
  const navigate = useNavigate()
  const handleFormApiErrors = useHandleFormApiErrors()
  const { setAlert } = useAlert()

  const {
    id: userId,
    isEmailNotificationEnabled,
  } = session.user || {
    id: '',
    isEmailNotificationEnabled: false,
  }

  const [ patchUserNotification ] = usePatchUserNotificationMutation()
  const [
    checked,
    setChecked,
  ] = React.useState<boolean>(isEmailNotificationEnabled)

  const toggleChecked = () => {
    setChecked(prev => !prev)
  }

  const handleSaveNotification = () => {
    patchUserNotification({
      body: { isEmailNotificationEnabled: checked },
      params: { id: userId },
    })
      .unwrap()
      .then(() => {
        dispatch(setUserEmailNotificationSetting(checked))
        navigate('/account')
        setAlert({
          description: 'Your notification setting has been updated!',
          type: 'success',
        })
      }).catch(error => {
        handleFormApiErrors({
          error,
          setErrors: false,
          showFieldErrorsAsToast: true,
        })
      })
  }

  return (
    <DocumentWrapper title='Common Sense Privacy | My Account Notifications'>
      <ScreenWrapper>
        <Container>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/account' variant='body2'>
              Account
            </Link>
            <Typography color='text.primary' variant='body2'>Edit Notifications</Typography>
          </Breadcrumbs>
          <Typography variant='h1'>Notifications</Typography>
          <Typography variant='intro' mb={4}>Edit your notification settings</Typography>
          <Divider />
        </Container>
        <Container>
          <Stack maxWidth='sm' mt={6}>
            <Typography variant='body2' fontWeight='bold'>Email:</Typography>
            <FormControlLabel control={<Switch checked={checked} />} onChange={toggleChecked} label={`${checked ? 'On' : 'Off'}`} />
            <Stack direction='row' spacing={2} mt={4}>
              <Button variant='contained' onClick={handleSaveNotification}>Save</Button>
              <Button variant='outlined' onClick={() => navigate(-1)}>Cancel</Button>
            </Stack>
          </Stack>
          <Stack mt={8}>
            <Divider />
          </Stack>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(EditNotifications)
