import { api } from '@common-sense-privacy/common'

import commonSenseApi from '../../state/apis/commonSense'

const organizationService = commonSenseApi.injectEndpoints({
  endpoints: build => ({
    patchOrganization: build.mutation<
      api.endpoints.organization.types.PatchResponseBody,
      api.endpoints.organization.types.PatchRequest
    >({
      query: ({
        body, params: { id },
      }) => ({
        url: `v1/organizations/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
  }),
})

export default organizationService

export const { usePatchOrganizationMutation } = organizationService

export const { endpoints: { patchOrganization } } = organizationService
