// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const printEnumKeys = (enumType: any) => Object
  .keys(enumType)
  .filter(key => Number.isNaN(Number(key)))

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const printEnumValues = (enumType: any) => Object
  .keys(enumType)
  .filter(key => Number.isNaN(Number(key)))
  .map(key => enumType[key])
