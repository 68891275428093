import React, {
  useEffect,
  useMemo,
} from 'react'

import { Outlet } from 'react-router-dom'

import { isProduction } from '@common-sense-privacy/common'

import useSession, { SessionState } from '@/hooks/useSession'

import Error403 from '@/screens/Error403'

import log from '@/utils/log'
import { printEnumValues } from '@/utils/enum'
import { getPlanType } from '@/utils/stripe'

import {
  Props,
  PlanType,
} from './types'

function ProtectedRoute({
  children,
  otherwise,
  test,
  toast,
}: Props): React.ReactElement {
  const session = useSession()
  /**
   * @todo implement toast in the ui package
   */
  const otherwises = useMemo(() => Array.isArray(otherwise) ? otherwise : [ otherwise ], [ otherwise ])
  const tests = useMemo(() => Array.isArray(test) ? test : [ test ], [ test ])
  const toasts = useMemo(() => Array.isArray(toast) ? toast : [ toast ], [ toast ])

  const failedTestIndex = tests.findIndex(test => test && !test(session))

  useEffect(() => {
    if (failedTestIndex >= 0) {
      if (toasts.length === 1) {
        return
      }

      const theToast = toasts[failedTestIndex]
      if (theToast) {
        // showToast(theToast)
      }
    }
  }, [
    failedTestIndex,
    toasts,
  ])

  if (failedTestIndex >= 0) {
    if (otherwises.length === 1) {
      return otherwises[0] || <Error403 />
    }

    if (otherwises[failedTestIndex]) {
      return otherwises[failedTestIndex] || <Error403 />
    }

    return <Error403 />
  }

  return children || <Outlet />
}

export default React.memo(ProtectedRoute)

export const isLoggedIn = (session: SessionState) => !!session?.user

export const isNotLoggedIn = (session: SessionState) => !session?.user

/**
 * @todo add emailVerifiedAt propert later on
 */
export const isUserEmailUnverified = (session: SessionState) => !session?.user

export const isUserEmailVerified = (session: SessionState) => !!session?.user

export const isUserOrganizationOwner = (session: SessionState) => {
  const userOrganization = session?.user?.organizations[0]

  return !!(userOrganization && userOrganization?.type === 'owner')
}

export const hasProduct = (session: SessionState) => {
  const userOrganization = session?.user?.organizations[0].organization

  return !!(userOrganization && userOrganization?.products && userOrganization?.products.length > 0)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const onlyWizardAccess = (session: SessionState) => !isProduction

export const requiresStarterPlan = (session: SessionState) => {
  const subscription = session?.subscription
  if (subscription) {
    const planType = getPlanType(subscription)
    switch (planType) {
      case PlanType.Starter:
      case PlanType.Wizard:
      case PlanType.Dashboard:
        return true
      default:
        log('error', `Invalid plan type: ${planType}. Expected one of ${printEnumValues(PlanType)}`)

        return false
    }
  }

  return false
}

export const requiresWizardPlan = (session: SessionState) => {
  const subscription = session?.subscription
  if (subscription) {
    const planType = getPlanType(subscription)
    switch (planType) {
      case PlanType.Starter:
        return false
      case PlanType.Wizard:
      case PlanType.Dashboard:
        return true
      default:
        log('error', `Invalid plan type: ${planType}. Expected one of ${printEnumValues(PlanType)}`)

        return false
    }
  }

  return false
}

export const requiresDashboardPlan = (session: SessionState) => {
  const subscription = session?.subscription
  if (subscription) {
    const planType = getPlanType(subscription)
    switch (planType) {
      case PlanType.Starter:
      case PlanType.Wizard:
        return false
      case PlanType.Dashboard:
        return true
      default:
        log('error', `Invalid plan type: ${planType}. Expected one of ${printEnumValues(PlanType)}.`)

        return false
    }
  }

  return false
}
