import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
} from '@mui/material'
import React, {
  useCallback,
  useRef,
} from 'react'

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { api } from '@common-sense-privacy/common'

import Form from '@/components/Form'
import FormField from '@/components/FormField'

import { usePostResetPasswordMutation } from '@/services/auth'

import useHandleFormApiErrors from '@/hooks/useHandleFormApiErrors'

import { useAlert } from '@/context/AlertContext'

import ScreenWrapper from '../../../components/ScreenWrapper'
import DocumentWrapper from '../../../components/DocumentWrapper'

import type {
  FormValues,
  OnSubmit,
} from './types'

function ForgotPassword(): React.ReactElement {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const handleFormApiErrors = useHandleFormApiErrors()

  const { setAlert } = useAlert()
  const [
    postResetPassword,
    { isLoading },
  ] = usePostResetPasswordMutation()

  // eslint-disable-next-line
  const recaptchaRef = useRef<string | null>(null)

  const initialValues = {
    email: '',
    'g-recaptcha-response': '',
  }

  const verifyRecaptcha = useCallback(async () => {
    // Essentially checks if recaptcha is fully loaded
    if (!executeRecaptcha) {
      setAlert({
        description: 'Captcha is not fully loaded. Try again.',
        type: 'error',
      })

      return
    }
    const token = await executeRecaptcha('resetPassword')
    recaptchaRef.current = token
  }, [
    executeRecaptcha,
    setAlert,
  ])

  const handleSubmit = useCallback<OnSubmit>(async (values: FormValues, { setErrors }) => {
    await verifyRecaptcha()

    const recaptchaValue = recaptchaRef.current
    if (!recaptchaValue) {
      setAlert({
        description: 'Failed captcha.',
        type: 'error',
      })

      return
    }
    values['g-recaptcha-response'] = recaptchaValue

    postResetPassword({ body: values })
      .unwrap().then(() => {
        setAlert({
          description: 'Success! Check your email for a reset password email.',
          type: 'success',
        })
      }).catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
          showFieldErrorsAsToast: true,
        })
      })
  }, [
    setAlert,
    handleFormApiErrors,
    postResetPassword,
    recaptchaRef,
    verifyRecaptcha,
  ])
  const { rules } = api.endpoints.resetPassword.validation.post.body

  return (
    <DocumentWrapper title='Common Sense Privacy | Forgot Password'>
      <ScreenWrapper>
        <Container maxWidth='xs'>
          <Box textAlign='center'>
            <Typography variant='h5'>Forgot Password</Typography>
            <Typography mt={2} mb={4} variant='intro'>Please enter your email address below
              and we’ll send you a email to reset your password.
            </Typography>
            <Typography variant='h6'>Password Reset Email</Typography>
          </Box>
        </Container>
        <Container maxWidth='sm'>
          <Form<FormValues> initialValues={initialValues} onSubmit={handleSubmit} rules={rules}>
            <Stack marginTop={4} spacing={4}>
              <FormField
                name='email'
                label='Email'
                variant='outlined'
                inputProps={{ 'data-testid': 'forgot-password-email' }}
              />
              <Box textAlign='center'>
                <Button
                  name='sendPasswordReset'
                  variant='contained'
                  disabled={isLoading}
                  type='submit'
                  role='button'
                >
                  Send Password Reset
                </Button>
              </Box>
            </Stack>
          </Form>
        </Container>
        <Container>
          <Box mt={8} textAlign='center'>
            <Divider />
          </Box>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(ForgotPassword)
