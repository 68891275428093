import {
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'

import useRouteParams from '@/hooks/useRouteParams'
import useLocation from '@/hooks/useLocation'
import { useCurrentOrganization } from '@/hooks/useSession'

import { useGetProductTopicsQuery } from '@/services/organization/product/topics'

import TimelineItem from './Item'
import { TopicDetail } from './types'

function Timeline(): React.ReactElement {
  const { id: organizationId } = useCurrentOrganization()
  const {
    productId,
    topicId,
  } = useRouteParams()
  const location = useLocation()

  const topicUrl = (itemTopicId: string) => `/wizard/products/${productId}/topics/${itemTopicId}`
  const finalizeUrl = `/wizard/products/${productId}/finalize`

  const status = (itemTopicId: string, progressPercentage: number, inActive = false) => {
    if (inActive) {
      return 'inactive'
    }
    if (itemTopicId === topicId) {
      return 'active'
    }

    if (progressPercentage === 100) {
      return 'complete'
    }

    if (progressPercentage > 0) {
      return 'incomplete'
    }

    return 'untouched'
  }

  const { data: topics } = useGetProductTopicsQuery(
    {
      params: {
        id: organizationId,
        productId: `${productId}`,
      },
    },
    { refetchOnMountOrArgChange: true },
  )

  const topicItems = topics?.data?.topics || []
  const isFinalizeActive = topicItems.length > 0 && !topicItems.find(topic => topic.inActive)
  const finalizeStatus = isFinalizeActive ? 'untouched' : 'inactive'
  const isFinalize = finalizeUrl === location.pathname

  return (
    <Stack>
      <Typography fontSize='20px' variant='secondary' mb={3}>Privacy Wizard Progress</Typography>
      {topicItems.map((topic: TopicDetail, index: number) => (
        <TimelineItem
          key={topic.id}
          title={topic.title}
          to={topicUrl(topic.id)}
          status={status(topic.id, topic.progressPercentage, topic.inActive)}
          hasDivider={topics?.data && index !== 0}
        />
      ))}
      <TimelineItem
        hasDivider={true}
        title='Finalize & Submit'
        status={isFinalize ? 'active' : finalizeStatus}
        to={finalizeUrl}
      />
    </Stack>
  )
}

export default React.memo(Timeline)
