import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
} from '@mui/material'
import React, { useCallback } from 'react'

import { api } from '@common-sense-privacy/common'

import useRouteQuery from '@/hooks/useRouteQuery'

import Form from '@/components/Form'

import FormField from '@/components/FormField'

import { usePatchResetPasswordMutation } from '@/services/auth'

import useHandleFormApiErrors from '@/hooks/useHandleFormApiErrors'
import useNavigate from '@/hooks/useNavigate'

import { useAlert } from '@/context/AlertContext'

import DocumentWrapper from '../../../../components/DocumentWrapper'
import ScreenWrapper from '../../../../components/ScreenWrapper'

import type {
  FormValues,
  OnSubmit,
} from './types'

function ResetPassword(): React.ReactElement {
  const navigate = useNavigate()
  const handleFormApiErrors = useHandleFormApiErrors()
  const { setAlert } = useAlert()
  const [
    patchResetPassword,
    { isLoading },
  ] = usePatchResetPasswordMutation()

  const queryParams = useRouteQuery()
  const token = queryParams.get('token') || ''
  const email = queryParams.get('email') || ''

  const initialValues = {
    email,
    password: '',
    confirmPassword: '',
  }
  const handleSubmit = useCallback<OnSubmit>(async (values: FormValues, { setErrors }) => {
    patchResetPassword({
      body: values,
      query: { token },
    })
      .unwrap().then(() => {
        setAlert({
          description: 'Success! Password is updated.',
          type: 'success',
        })
        navigate('/signin')
      }).catch(error => {
        handleFormApiErrors({
          error,
          setErrors,
          showFieldErrorsAsToast: true,
        })
      })
  }, [
    setAlert,
    token,
    navigate,
    handleFormApiErrors,
    patchResetPassword,
  ])
  const { rules } = api.endpoints.resetPassword.validation.patch.body

  return (
    <DocumentWrapper title='Common Sense Privacy | Reset Password'>
      <ScreenWrapper>
        <Container maxWidth='xs'>
          <Box textAlign='center'>
            <Typography variant='h5'>New Password</Typography>
            <Typography mt={2} mb={4} variant='intro'>Please choose a new password for your Common Sense Account.
            </Typography>
            <Typography variant='h6'>Reset Password</Typography>
          </Box>
        </Container>
        <Container maxWidth='sm'>
          <Form<FormValues> initialValues={initialValues} onSubmit={handleSubmit} rules={rules}>
            <Stack maxWidth='sm' marginTop={4} spacing={4}>
              <FormField
                name='email'
                label='Email'
                variant='outlined'
                readOnly={true}
                disabled={true}
              />
              <FormField
                name='password'
                type='password'
                label='Password'
                variant='outlined'
                inputProps={{ 'data-testid': 'reset-password' }}
                fieldHelperText='Choose a password with at least 8 characters and include numbers, letters, and a special character.'
              />
              <FormField
                name='confirmPassword'
                type='password'
                label='Confirm Password'
                variant='outlined'
                inputProps={{ 'data-testid': 'reset-confirm-password' }}
              />
              <Box textAlign='center'>
                <Button
                  variant='contained'
                  disabled={isLoading}
                  type='submit'
                  role='button'
                >
                  Reset Password
                </Button>
              </Box>
            </Stack>
          </Form>
        </Container>
        <Container>
          <Box mt={8} textAlign='center'>
            <Divider />
          </Box>
        </Container>
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(ResetPassword)
