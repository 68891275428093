import React, { useState } from 'react'

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material'

import PrivacyIssueCard from '@/components/PrivacyIssueCard'

import { ProductPrivacyIssue } from '@/services/organization/product/types'

import {
  useCurrentOrganization,
  useCurrentProduct,
} from '@/hooks/useSession'

import { useGetPrivacyIssuesByStateQuery } from '@/services/organization/product/applicableLaw/privacyIssue'

import useRouteParams from '@/hooks/useRouteParams'

import { Props } from './types'

export default function PrivacyIssuesPreview({
  title,
  description,
  loadMoreText,
  itemsPerPage,
  state,
}: Props): React.ReactElement {
  const { id: organizationId } = useCurrentOrganization()
  const product = useCurrentProduct()
  const { lawId } = useRouteParams()
  const { id: productId } = product || { id: '' }

  const [
    offset,
    setOffset,
  ] = useState(0)

  const handleShowMore = () => {
    setOffset(offset + itemsPerPage)
  }

  const {
    data: paginatedResponse,
    isLoading,
  } = useGetPrivacyIssuesByStateQuery({
    params: {
      id: organizationId,
      productId,
      lawId: lawId || '',
      state,
    },
    query: {
      offset: `${offset || 0}`,
      limit: `${itemsPerPage || 20}`,
    },
  }, { refetchOnMountOrArgChange: true })

  const privacyIssues: ProductPrivacyIssue[] = paginatedResponse?.data?.items || []
  const total = paginatedResponse?.data?.total || 0

  return (
    <Container>
      <Stack spacing={2} mb={3} maxWidth='md'>
        <Typography variant='h4'>{title}</Typography>
        <Divider />
      </Stack>
      <Typography variant='intro' mb={2}>{description}</Typography>
      <Grid container={true} item={true} spacing={2} mb={6}>
        {/* CARDS */}
        {privacyIssues.length > 0 ? privacyIssues.map((issue: ProductPrivacyIssue) => (
          <Grid key={issue.id} item={true} xs={12} sm={6} md={4}>
            <PrivacyIssueCard data={issue} />
          </Grid>
        )) : (
          <Grid item={true} xs={12} sm={6} md={4}>
            <Typography>There are no privacy issues to show.</Typography>
          </Grid>
        )}
        {/* END CARDS */}
      </Grid>
      {privacyIssues.length < total && (
        <Box mb={6} textAlign='center' position='relative'>
          <Box position='absolute' width='100%' top='50%'>
            <Divider />
          </Box>
          <Button
            disabled={isLoading}
            variant='outlined'
            onClick={handleShowMore}
          >{loadMoreText}
          </Button>
        </Box>
      )}
    </Container>

  )
}
