import commonSenseApi from '../../state/apis/commonSense'

import { GetResponseBody } from './types'

const articleService = commonSenseApi.injectEndpoints({
  endpoints: build => ({
    getArticles: build.query<GetResponseBody, void>({
      query: () => ({
        url: 'v1/articles',
        method: 'GET',
      }),
    }),
  }),
})

export default articleService

export const { useGetArticlesQuery } = articleService

export const { endpoints: { getArticles } } = articleService
