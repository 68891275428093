import React from 'react'

import { Stack } from '@mui/material'

import { transformPlans } from '@/utils/stripe'

import PlanCard from './Card'

import { Props } from './types'

function PlanCards({
  plans,
  subscription,
  requiresPaidPlan,
  onContinue,
  onSelect,
}: Props): React.ReactElement {
  const transformedPlans = transformPlans({
    plans,
    subscription,
  })

  return (
    <Stack
      direction={{
        xs: 'column',
        md: 'row',
      }}
      spacing={2}
    >
      {transformedPlans.map(plan => (
        <PlanCard
          key={plan.id}
          tagLine={plan.tagline}
          planTypeTitle={plan.name}
          price={plan.price}
          interval={plan.billingInterval}
          listItems={plan.features}
          isActive={plan.isActive}
          showContinueButton={plan.isActive && (plan.isFree ? !requiresPaidPlan : false) && !!onContinue}
          showSelectButton={!plan.isActive && (plan.isFree ? !requiresPaidPlan : true) && !!onSelect && !plan.scheduledForDowngrade}
          onContinue={onContinue}
          onSelect={() => onSelect && onSelect(plan.id, plan.priceId)}
          scheduledForDowngrade={plan.scheduledForDowngrade}
          downgradeHelperText={plan.downgradeHelperText}
        />
      ))}
    </Stack>
  )
}

export default React.memo(PlanCards)
