/* eslint-disable react/no-array-index-key */
import {
  Typography, Stack, Box,
} from '@mui/material'
import React, { useState } from 'react'
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from 'recharts'

import ChartLabel from '../components/Label'

import { ApplicableLawStat } from './types'

// interface LabelProps {
//   x?: number,
//   y?: number,
//   name?: string,
// }

// function renderCustomizedLabel({
//   x = 50,
//   y = 100,
//   name,
// }: LabelProps): React.ReactElement {
//   return (
//     <text x={x} y={y} fill='black' textAnchor='start' dominantBaseline='central'>
//       {name}
//     </text>
//   )
// }

function ChartsLegal({ stats }: { stats: ApplicableLawStat }) {
  const [ COLORS ] = useState([
    '#FAA41A', // Color For Risky
    '#23B5E8', // Color For Meeting requirements
    '#33A544', // Color For Excellent
  ])

  const data = [
    {
      name: 'Risky',
      value: stats.risky,
    },
    {
      name: 'Meeting Requirements',
      value: stats.meetingRequirements,
    },
    {
      name: 'Excellent',
      value: stats.excellent,
    },
  ]

  const total = stats.risky + stats.meetingRequirements + stats.excellent

  return (
    <Box width='100%' position='relative'>
      <ResponsiveContainer height={300} width='100%'>
        <PieChart>
          <Pie
            data={data}
            innerRadius={76}
            outerRadius={100}
            paddingAngle={5}
            dataKey='value'
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <Stack position='absolute' top='120px' bottom='0' left='0' right='0' alignItems='center'>
        <Typography fontSize={34} lineHeight={1}>{total}</Typography>
        <Typography variant='subtitle2'>Laws Apply</Typography>
      </Stack>
      <Stack direction='row' justifyContent='center' spacing={1} minHeight={20}>
        <ChartLabel labelText='Excellent' bgcolor={COLORS[2]} />
        <ChartLabel labelText='Meeting Requirements' bgcolor={COLORS[1]} />
        <ChartLabel labelText='Risky' bgcolor={COLORS[0]} />
      </Stack>
    </Box>
  )
}

export default React.memo(ChartsLegal)
