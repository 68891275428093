import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'

import useRouteParams from '@/hooks/useRouteParams'
import {
  useCurrentOrganization,
  useCurrentProduct,
} from '@/hooks/useSession'

import { useGetProductPrivacyIssueQuery } from '@/services/organization/product'
import { useGetPrivacyIssueMetricsQuery } from '@/services/organization/product/metric'
import { useGetProductRecommendedActionListQuery } from '@/services/organization/product/recommendedAction'

import ResourceCard from '@/components/ResourceCard'
import ChartsBenchMark from '@/components/Charts/BenchMark'
import DocumentWrapper from '@/components/DocumentWrapper'
import ScreenWrapper from '@/components/ScreenWrapper'
import PrivacyIssueCard from '@/components/PrivacyIssueCard'
import RecommendedActionCard from '@/components/RecommendedActionCard'

import { getProductPrivacyIssueStatus } from '@/utils/productPrivacyIssue'
import {
  LevelOfImportance,
  ScoreStatus,
} from '@/utils/productPrivacyIssue/types'

import ScoreIndicator from '../RaiseYourScore/components/ScoreIndicator'
import LevelBar from '../RaiseYourScore/components/LevelBar'

import PrivacyIssuePracticesDrawer from './PrivacyIssuePracticesDrawer'
import RecommendedActionDrawer from './RecommendedActionDrawer'
import {
  ScoreDependentData,
  ProductRecommendedAction,
} from './types'

const getStatusDependentData = (status: ScoreStatus | undefined): ScoreDependentData => {
  if (status === 'bad') {
    return {
      skipFetchingRecommendedActions: true,
      question: "What's wrong?",
      privacyIssueMessage: 'We need to know the following:',
      scoreLevel: 'unclear',
      recommendedActionsMessage: 'No recommendations are offered until the primary question is answered.',
    }
  }
  if (status === 'normal') {
    return {
      skipFetchingRecommendedActions: false,
      question: "What's wrong?",
      privacyIssueMessage: 'The following practice is not ideal...',
      scoreLevel: 'bad',
      recommendedActionsMessage: 'Take the following actions to improve your privacy score and customer experience.',
    }
  }

  return {
    skipFetchingRecommendedActions: true,
    question: "What's working?",
    privacyIssueMessage: 'You have a great practice...',
    scoreLevel: 'good',
    recommendedActionsMessage: 'No additional actions are required at this time.',
  }
}

function DashboardPrivacyIssueDetail(): React.ReactElement {
  const { privacyIssueId } = useRouteParams()
  const { id: organizationId } = useCurrentOrganization()
  const { id: productId } = useCurrentProduct()

  const [
    isPrivacyIssuePracticesDrawerOpen,
    setIsPrivacyIssuePracticesDrawerOpen,
  ] = useState(false)

  const [
    isRecommendedActionDrawerOpen,
    setIsRecommendedActionDrawerOpen,
  ] = useState(false)

  const [
    recommendedActionClicked,
    setRecommendedActionClicked,
  ] = useState<ProductRecommendedAction>()

  const { data: productPrivacyIssueResponse } = useGetProductPrivacyIssueQuery({
    params: {
      id: organizationId,
      productId,
      privacyIssueId: privacyIssueId || '',
    },
  }, { refetchOnMountOrArgChange: true })

  const { data: metrics } = useGetPrivacyIssueMetricsQuery({
    params: {
      id: organizationId,
      productId,
      privacyIssueId: privacyIssueId || '',
    },
    query: { metric: [ 'topicScore' ] },
  }, { refetchOnMountOrArgChange: true })

  const productPrivacyIssue = productPrivacyIssueResponse?.data
  const privacyIssue = productPrivacyIssue?.privacyIssue
  const hasRecommendedActions = !!privacyIssue?.privacyIssueRecommendedActions?.length

  const status = productPrivacyIssue && getProductPrivacyIssueStatus(productPrivacyIssue)
  const importanceLevel = productPrivacyIssue?.privacyIssue.importanceLevel as LevelOfImportance
  const {
    skipFetchingRecommendedActions,
    question,
    privacyIssueMessage,
    scoreLevel,
    recommendedActionsMessage,
  } = getStatusDependentData(status)

  const { data: productRecommendedActionsResponse } = useGetProductRecommendedActionListQuery({
    params: {
      id: organizationId,
      productId,
    },
    query: { privacyIssueId: privacyIssueId || '' },
  }, {
    skip: skipFetchingRecommendedActions,
    refetchOnMountOrArgChange: true,
  })

  const productRecommendedActions = productRecommendedActionsResponse?.data?.[0]
  const productRecommendedStatsMap = productRecommendedActionsResponse?.data?.[1]

  const topicScore = metrics?.data?.topicScore

  const handleOnPrivacyIssuePracticesClick = () => {
    setIsPrivacyIssuePracticesDrawerOpen(true)
  }

  return (
    <DocumentWrapper title='Common Sense Privacy | Privacy Issue Detail'>
      <ScreenWrapper bgcolor='grey.50'>
        <Container>
          <Typography variant='h1' mb={6}>{`${privacyIssue?.code}: ${privacyIssue?.title}`}</Typography>
        </Container>
        <Container>
          <Stack spacing={2} mb={3}>
            <Typography variant='h4'>{question}</Typography>
            <Divider />
          </Stack>
          <Grid container={true} spacing={4} mb={4}>
            <Grid
              item={true}
              xs={12}
              sm={6}
              md={8}
              order={{
                xs: 2,
                sm: 1,
              }}
            >
              <Stack spacing={2} data-testid='PrivacyIssueDetail-CardContainer'>
                <Typography variant='intro'>{privacyIssueMessage}</Typography>

                {productPrivacyIssue
                && (
                  <PrivacyIssueCard
                    data={productPrivacyIssue}
                    isList={false}
                    hasUrl={false}
                    hasPracticesButton={true}
                    onPracticesClick={handleOnPrivacyIssuePracticesClick}
                  />
                )}
              </Stack>
            </Grid>
            <Grid
              item={true}
              xs={12}
              sm={6}
              md={4}
              order={{
                xs: 1,
                sm: 2,
              }}
            >
              <Typography variant='intro' mb={2} ml={2}>Your practices are...</Typography>
              <ScoreIndicator scoreLevel={scoreLevel} />
            </Grid>
          </Grid>
        </Container>
        <Container>
          <Stack spacing={2} mb={3}>
            <Typography variant='h4'>Recommended Actions</Typography>
            <Divider />
          </Stack>
          <Grid container={true} spacing={4} mb={6}>
            <Grid item={true} xs={12} sm={12} md={8}>
              <Stack spacing={2}>
                <Typography variant='intro'>
                  {hasRecommendedActions ? recommendedActionsMessage : 'There are no recommended actions available at this time.'}
                </Typography>
                {status === 'normal' && (
                  productRecommendedActions?.map(action => (
                    <RecommendedActionCard
                      key={action.id}
                      productRecommendedAction={action}
                      productPrivacyIssue={productPrivacyIssue}
                      stats={productRecommendedStatsMap?.[action.recommendedAction.id]}
                      onPracticesClick={productRecommendedAction => {
                        setIsRecommendedActionDrawerOpen(true)
                        setRecommendedActionClicked(productRecommendedAction)
                      }}
                    />
                  ))
                )}
              </Stack>
            </Grid>
          </Grid>
        </Container>
        {/* Resources section with linked articles is hidden for the first version of the tool */}
        <Container sx={{ display: 'none' }}>
          <Stack spacing={2} mb={3}>
            <Typography variant='h4'>Resources</Typography>
            <Divider />
          </Stack>
          <Grid container={true} spacing={8} mb={4}>
            <Grid item={true} xs={12} sm={6} md={4}>
              <ResourceCard
                date='09.07.2022 - Common Sense Media'
                title='Update Notices For Changes to a Privacy Policy'
                description='When sending an update notice it is good practice to include a short summary of the changes you have made and advise users of the date the new...'
                link='/'
                categories={[
                  'CalOPPA',
                  '1.1.2',
                  'Data Sharing',
                ]}
              />
            </Grid>
            <Grid item={true} xs={12} sm={6} md={4}>
              <ResourceCard
                date='09.07.2022 - Common Sense Media'
                title='Update Notices For Changes to a Privacy Policy'
                description='When sending an update notice it is good practice to include a short summary of the changes you have made and advise users of the date the new...'
                link='/'
                categories={[
                  'CalOPPA',
                  '1.1.2',
                  'Data Sharing',
                ]}
              />
            </Grid>
            <Grid item={true} xs={12} sm={6} md={4}>
              <ResourceCard
                date='09.07.2022 - Common Sense Media'
                title='Update Notices For Changes to a Privacy Policy'
                description='When sending an update notice it is good practice to include a short summary of the changes you have made and advise users of the date the new...'
                link='/'
                categories={[
                  'CalOPPA',
                  '1.1.2',
                  'Data Sharing',
                ]}
              />
            </Grid>
          </Grid>
          <Box mb={6} textAlign='center' position='relative'>
            <Box position='absolute' width='100%' top='50%'>
              <Divider />
            </Box>
            <Button
              variant='outlined'
            >More Resources
            </Button>
          </Box>
        </Container>
        <Container>
          <Stack spacing={2} mb={3}>
            <Typography variant='h4'>How much do people care about this?</Typography>
            <Divider />
          </Stack>
          <Grid container={true} spacing={6} mb={4}>
            <Grid item={true} xs={12} md={8}>
              <Typography>Our research shows this topic of high importance in public opinion surveys.</Typography>
            </Grid>
            <Grid item={true} xs={12} md={4}>
              <LevelBar level={importanceLevel} title='How much do people care?' />
            </Grid>
          </Grid>
        </Container>
        <Container>
          <Stack spacing={2} mb={3}>
            <Typography variant='h4'>How do companies in your product category compare?</Typography>
            <Divider />
          </Stack>
          <Grid container={true} spacing={6} mb={4}>
            <Grid item={true} xs={12} md={8}>
              <Typography>Here is how products in your category compare on this topic.</Typography>
            </Grid>
            <Grid item={true} xs={12} md={4}>
              <ChartsBenchMark score={topicScore || {
                myScore: 0,
                othersScore: 0,
              }}
              />
            </Grid>
          </Grid>
        </Container>
        {productPrivacyIssue && (
          <PrivacyIssuePracticesDrawer
            maxWidth={736}
            productPrivacyIssue={productPrivacyIssue}
            open={isPrivacyIssuePracticesDrawerOpen}
            onClose={() => setIsPrivacyIssuePracticesDrawerOpen(false)}
            onSubmit={() => setIsPrivacyIssuePracticesDrawerOpen(false)}
          />
        )}
        {recommendedActionClicked && (
          <RecommendedActionDrawer
            maxWidth={736}
            productRecommendedAction={recommendedActionClicked}
            open={isRecommendedActionDrawerOpen}
            onClose={() => setIsRecommendedActionDrawerOpen(false)}
            onSubmit={() => setIsRecommendedActionDrawerOpen(false)}
          />
        )}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(DashboardPrivacyIssueDetail)
