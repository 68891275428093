import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
} from '@mui/material'
import React, { useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { useGetMeQuery } from '@/services/auth'

import {
  useCurrentProduct,
  setUser,
} from '@/hooks/useSession'

import CircularLoading from '@/components/CircularLoading'

import DocumentWrapper from '../../components/DocumentWrapper'
import ScreenWrapper from '../../components/ScreenWrapper'

import CarefullyRead from './assets/images/carefullyRead.svg'
import Save from './assets/images/save.svg'
import Skipped from './assets/images/skipped.svg'
import Topics from './assets/images/topics.svg'
import AnswerAll from './assets/images/answerAll.svg'

function WizardStart(): React.ReactElement {
  const dispatch = useDispatch()
  const product = useCurrentProduct()
  const { id: productId } = product || { id: '' }

  const {
    data: user,
    isLoading,
  } = useGetMeQuery(undefined, { refetchOnMountOrArgChange: true })

  useEffect(() => {
    if (isLoading || !user) {
      return
    }

    const { data } = user

    if (!data) {
      return
    }

    dispatch(setUser(data))
  }, [
    dispatch,
    isLoading,
    user,
  ])

  return (
    <DocumentWrapper title='Common Sense Privacy | Privacy Wizard'>
      <ScreenWrapper>
        { (isLoading || !productId) ? <CircularLoading /> : (
          <>
            <Container maxWidth='md'>
              <Stack textAlign='center' spacing={4}>
                <Typography variant='h5'>Welcome!</Typography>
                <Typography variant='intro'>We will walk you through a series of questions to generate, or improve  upon, your privacy policy.
                  Please read each question carefully. If you are unsure about the answer, you can save it for later and come back. Don’t worry about leaving, we’ll save your place.
                </Typography>
                {/* Commented out for v1.0 */}
                {/* <Typography fontWeight='bold'>Complete the wizard for free! Pay to download your policy at the end.</Typography> */}
              </Stack>
            </Container>
            <Container>
              <Stack alignItems='center'>
                <Stack my={4} spacing={2}>
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <Box
                      component='img'
                      sx={{
                        height: 35,
                        width: 35,
                      }}
                      alt='Answer All'
                      src={Topics}
                    /><Typography fontSize={17}>Questions are organized by topic.</Typography>
                  </Stack>
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <Box
                      component='img'
                      sx={{
                        height: 35,
                        width: 35,
                      }}
                      alt='Answer All'
                      src={CarefullyRead}
                    /><Typography fontSize={17}>Carefully read and answer each question.</Typography>
                  </Stack>
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <Box
                      component='img'
                      sx={{
                        height: 35,
                        width: 35,
                      }}
                      alt='Answer All'
                      src={Save}
                    /><Typography fontSize={17}>Save questions for follow up if you are unsure.</Typography>
                  </Stack>
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <Box
                      component='img'
                      sx={{
                        height: 35,
                        width: 35,
                      }}
                      alt='Answer All'
                      src={Skipped}
                    /><Typography fontSize={17}>Skipped questions can be returned to later.</Typography>
                  </Stack>
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <Box
                      component='img'
                      sx={{
                        height: 35,
                        width: 35,
                      }}
                      alt='Answer All'
                      src={AnswerAll}
                    /><Typography fontSize={17}>Answer all questions to maximize your score.</Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Box textAlign='center'>
                <Button component={RouterLink} to={`/wizard/products/${productId}`} variant='contained'>Get Started</Button>
              </Box>
            </Container>
          </>
        )}
      </ScreenWrapper>
    </DocumentWrapper>
  )
}

export default React.memo(WizardStart)
