import commonSenseApi from '@/state/apis/commonSense'

import { GetResponseBody } from './types'

const distributionMethodService = commonSenseApi.injectEndpoints({
  endpoints: build => ({
    getDistributionMethods: build.query<GetResponseBody, void>({
      query: () => ({
        url: 'v1/distributionMethods',
        method: 'GET',
      }),
    }),
  }),
})

export default distributionMethodService

export const { useGetDistributionMethodsQuery } = distributionMethodService

export const { endpoints: { getDistributionMethods } } = distributionMethodService
