"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ifEmptyThenUndefined = exports.toListString = void 0;
const toListString = (input, { conjunction = 'and', oxfordComma = true, } = {}) => input.length < 3 ? input.join(` ${conjunction} `) : [
    input.slice(0, -1).join(', '),
    input.slice(-1),
].join(`${oxfordComma ? ',' : ''} ${conjunction} `);
exports.toListString = toListString;
const ifEmptyThenUndefined = (...arrays) => {
    const totalLength = arrays.reduce((acc, array) => acc + (array || []).length, 0);
    if (!totalLength) {
        return undefined;
    }
    return Array.prototype.concat(...arrays.map(array => array || []));
};
exports.ifEmptyThenUndefined = ifEmptyThenUndefined;
