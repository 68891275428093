import { api } from '@common-sense-privacy/common'

import commonSenseApi from '../../state/apis/commonSense'

const privacyPolicyService = commonSenseApi.injectEndpoints({
  endpoints: build => ({
    getPrivacyPolicyNames: build.query<
      api.endpoints.privacyPolicy.types.GetResponseBody,
      api.endpoints.privacyPolicy.types.GetRequestQuery
    >({
      query: ({ search }) => ({
        url: `v1/privacy-policy?search=${search}`,
        method: 'GET',
      }),
    }),
  }),
})

export default privacyPolicyService

export const { useGetPrivacyPolicyNamesQuery } = privacyPolicyService

export const { endpoints: { getPrivacyPolicyNames } } = privacyPolicyService
