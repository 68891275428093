import {
  Container,
  Divider,
  Typography,
  Stack,
  Button,
  Box,
  Breadcrumbs,
  Link,
  Dialog,
  DialogContent,
} from '@mui/material'
import React, {
  useState,
  useEffect,
} from 'react'

import { lang } from '@common-sense-privacy/common'

import {
  useGetPlansQuery,
  useGetSubscriptionQuery,
  usePostCreateSubscriptionMutation,
  useCreateSetupIntentMutation,
} from '@/services/stripe'

import { useAlert } from '@/context/AlertContext'
import useNavigate from '@/hooks/useNavigate'
import useWaitForSubscriptionChange from '@/hooks/useWaitForSubscriptionChange'
import useRefreshAccessToken from '@/hooks/useRefreshAccessToken'

import BackLink from '@/components/BackLink'
import PlanCards from '@/components/PlanCards'
import Loading from '@/screens/Wizard/components/Loading'

import { isHigherTierPlan } from '@/utils/stripe'

import dateTime from '@/utils/dateTime'

import {
  Product,
  Props,
} from './types'

function Plans({
  subtitle,
  requiresPaidPlan,
  onSelectFreePlan,
  backLink,
  editMode,
  onSuccessLink,
}: Props): React.ReactElement {
  const [
    waitForSubscription,
    setWaitForSubscription,
  ] = useState(false)

  const [
    downgradePlan,
    setDowngradePlan,
  ] = useState<{
    currentProduct: Product | undefined,
    currentPlanExpiresIn: string | undefined,
    nextProduct: Product | undefined,
    nextPlanId: string,
    nextPlanPriceId: string,
  } | undefined>()

  const {
    data: plansResponse,
    isLoading: isLoadingPlans,
  } = useGetPlansQuery(undefined, { refetchOnMountOrArgChange: true })
  const {
    data: subscriptionResponse,
    isFetching: isFetchingSubscription,
  } = useGetSubscriptionQuery(undefined, { refetchOnMountOrArgChange: true })
  const [ postCreateSubscription ] = usePostCreateSubscriptionMutation()
  const [ createSetupIntent ] = useCreateSetupIntentMutation()

  const navigate = useNavigate()
  const { setAlert } = useAlert()

  const plans = plansResponse?.data || []
  const subscription = subscriptionResponse?.data

  const subscriptionChanged = useWaitForSubscriptionChange({
    canWait: waitForSubscription,
    subscription,
  })

  const accessTokenRefreshed = useRefreshAccessToken(subscriptionChanged)

  const submitPlan = (priceId: string, isSelectedPlanHigherTier: boolean) => {
    if (!subscription) return

    postCreateSubscription({ body: { priceId } }).unwrap().then(() => {
      if (!isSelectedPlanHigherTier) {
        setAlert({
          type: 'success',
          description: `Success! Your plan has been changed. You will maintain access to paid features until ${downgradePlan?.currentPlanExpiresIn}`,
        })
      }
      setWaitForSubscription(true)
    }).catch(() => {
      setAlert({
        description: lang().messages.somethingWentWrong(),
        type: 'error',
      })
    })
  }

  const handlePlanSelected = (planId: string, priceId: string) => {
    if (!subscription) return

    const selectedPlan = plans.find(plan => plan.id === planId)
    const currentPlan = plans.find(plan => plan.id === subscription?.plan?.product?.id)

    if (!selectedPlan || !currentPlan) return

    const selectedPlanType = selectedPlan.metadata.type
    const currentPlanType = currentPlan.metadata.type
    const isSelectedPlanHigherTier = isHigherTierPlan(selectedPlanType, currentPlanType)

    if (isSelectedPlanHigherTier) {
      createSetupIntent({ body: { stripeCustomerId: subscription?.customer.id as string } })
        .unwrap()
        .then(response => {
          const paymentLink = editMode ? '/account/edit-plan/payment' : '/subscriptions/payment'

          navigate(paymentLink, {
            state: {
              planId,
              priceId,
              clientSecret: response?.data?.clientSecret,
              editMode,
              nextPage: onSuccessLink,
            },
          })
        })
        .catch(() => {
          setAlert({
            description: lang().messages.unknownError(),
            type: 'error',
          })
        })
    }
    else if (!isSelectedPlanHigherTier) {
      setDowngradePlan({
        currentProduct: currentPlan,
        nextProduct: selectedPlan,
        nextPlanId: planId,
        nextPlanPriceId: priceId,
        currentPlanExpiresIn: dateTime.format(new Date(subscription.current_period_end * 1000), 'MM.dd.yyyy'),
      })
    }
    else {
      submitPlan(priceId, isSelectedPlanHigherTier)
    }
  }

  useEffect(() => {
    if (!accessTokenRefreshed) return

    if (onSelectFreePlan) {
      onSelectFreePlan()
    }
    else {
      navigate('/wizard')
    }
  }, [
    accessTokenRefreshed,
    onSelectFreePlan,
    navigate,
  ])

  return (
    <>
      <Container>
        {backLink && <Box mb={4}><BackLink to={backLink} /></Box>}
        {editMode && (
          <Box mb={4}>
            <Breadcrumbs>
              <Link
                href='/account'
                variant='body2'
                sx={{ color: 'text.primary' }}
              >My Account
              </Link>
              <Typography variant='body2'>Edit Plan</Typography>
            </Breadcrumbs>
          </Box>
        )}
        <Stack mb={4}>
          <Typography variant='h1'>Compare Plans</Typography>
          <Typography variant='intro' mb={4}>{subtitle}</Typography>
          <Divider />
        </Stack>
      </Container>
      <Container>
        {isLoadingPlans || isFetchingSubscription ? <Loading /> : (
          <PlanCards
            plans={plans}
            subscription={subscription}
            onContinue={!requiresPaidPlan ? onSelectFreePlan : undefined}
            onSelect={handlePlanSelected}
            requiresPaidPlan={requiresPaidPlan}
          />
        )}
        <Box my={4}>
          <Divider />
        </Box>
        <Stack spacing={3} mt={4} alignItems='flex-start'>
          <Typography variant='h6'>Need an Enterprise plan?</Typography>
          <Button variant='outlined' onClick={() => navigate('/contact-support')}>Contact Support</Button>
        </Stack>
        <Dialog open={!!downgradePlan} onClose={() => setDowngradePlan(undefined)}>
          <DialogContent dividers={true}>
            {downgradePlan && (
              <Box textAlign='center'>
                <Typography variant='h5'>Are you sure you want to downgrade to the {downgradePlan?.nextProduct?.name} plan?</Typography>
                <Typography mt={2} mb={4}>You will maintain access to paid features until your subscription expires on {downgradePlan.currentPlanExpiresIn}.
                  After that, you will lose access. Your data will be saved.
                </Typography>
                <Stack spacing={2} direction='row' justifyContent='center'>
                  <Button variant='cancel' onClick={() => setDowngradePlan(undefined)} sx={{ width: 'auto' }}>Nevermind.</Button>
                  <Button variant='contained' onClick={() => submitPlan(downgradePlan.nextPlanPriceId, false)}>Yes, downgrade my plan.</Button>
                </Stack>
              </Box>
            )}
          </DialogContent>
        </Dialog>

      </Container>
    </>
  )
}

export default React.memo(Plans)
