import React from 'react'

import { SvgIcon } from '@mui/material'

import { ReactComponent as DefaultLogo } from '../assets/CSP_Wizard_Logo.svg'

function LogoWizard() {
  return (
    <SvgIcon
      viewBox='0 0 238 70'
      sx={{
        height: '70px',
        width: '238px',
      }}
      component={DefaultLogo}
    />
  )
}

export default React.memo(LogoWizard)
