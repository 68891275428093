import React, {
  createContext,
  useContext,
  useState,
} from 'react'

import { SignupValues } from './types'

interface SignupContextType {
  signupValues: SignupValues,
  setSignupValues: React.Dispatch<React.SetStateAction<SignupValues>>,
}

const DEFAULT_INITIAL_VALUES = {
  firstName: '',
  lastName: '',
  jobTitle: '',
  roles: [],
  organizationName: '',
  organizationSize: '',
  email: '',
  password: '',
  confirmPassword: '',
  organizationEmail: '',
  city: '',
  state: '',
  streetAddress: '',
  zipCode: '',
  poBox: '',
  organizationCountry: '',
  agreeToTerms: false,
}

const SignupContext = createContext<SignupContextType | undefined>(undefined)

export const useSignup = () => {
  const context = useContext(SignupContext)
  if (!context) {
    throw new Error('useSignup must be used within a SignupContextProvider')
  }

  return context
}

function SignupContextProvider({ children }: { children: React.ReactNode }): React.ReactElement {
  const [
    signupValues,
    setSignupValues,
  ] = useState<SignupValues>(DEFAULT_INITIAL_VALUES)

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <SignupContext.Provider value={{
      signupValues,
      setSignupValues,
    }}
    >
      {children}
    </SignupContext.Provider>
  )
}

export default SignupContextProvider
